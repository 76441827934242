import React, { useState } from "react";

//Material-UI Imports
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import LogoWhite from "../../images/BnR_Logo_White.png";

//Import Images
import youTubeIcon from "../../images/yt-icon-white.png";
import instagramIcon from "../../images/ig-icon-white.png";
import facebookIcon from "../../images/fb-icon-white.png";

import BrButton from "../elements/BrButton";
import BrIframeModal from "../elements/BrIframeModal";
import MarketoForm from "../../utilities/MarketoForms/MarketoForm";

//Import CSS Styles
import "../../theme/css/footer.css";

//React Router Imports
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: 0,
        marginTop: 0,
    },
    siteFooter: {
        width: "100%",
        backgroundColor: "#194666",
        "& MuiAppBar-colorPrimary": {
            display: "none",
        },
        padding: "50px 0 80px 0",
    },
    text: {
        fontFamily: theme.typography.body1.fontFamily,
    },
    emailCta: {
        "&:hover": {
            background: "#DBAA79",
            boxShadow: "1px 4px 5px #212121 !important",
        },
    },
}));

export default function Footer(props) {
    const classes = useStyles();
    const mobileWidth = useMediaQuery("(max-width:959px)");
    let searchParams = new URLSearchParams(useLocation().search);
    let isPrint = searchParams.get("isPrint") === "true";
    const [isNewsletterSignUpModalOpen, setIsNewsletterSignUpModalOpen] =
        useState(false);

    return (
        <>
            {isPrint ? (
                <></>
            ) : (
                <footer id="colophon" className={classes.siteFooter}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <div>
                                <img
                                    style={{ width: "185px" }}
                                    src={LogoWhite}
                                />
                            </div>
                            <div class="social-media">
                                <img
                                    src={youTubeIcon}
                                    style={{
                                        maxWidth: "100px",
                                        margin: "0 10px",
                                    }}
                                />
                                <img
                                    src={facebookIcon}
                                    style={{
                                        maxWidth: "100px",
                                        margin: "0 10px",
                                    }}
                                />
                                <img
                                    src={instagramIcon}
                                    style={{
                                        maxWidth: "100px",
                                        margin: "0 10px",
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={9}
                            style={{ display: mobileWidth ? "block" : "flex" }}
                        >
                            <div
                                style={{
                                    textAlign: mobileWidth ? "center" : "left",
                                    alignSelf: "flex-end",
                                }}
                            >
                                <BrButton
                                    buttonText="Subscribe to our emails"
                                    color="bespokeOrange"
                                    onClick={() =>
                                        setIsNewsletterSignUpModalOpen(
                                            !isNewsletterSignUpModalOpen
                                        )
                                    }
                                />
                                <div className="terms-privacy">
                                    <p
                                        style={{
                                            color: "rgb(255, 255, 255)",
                                            fontSize: "13px",
                                            margin: "20px 0 0 0",
                                        }}
                                    >
                                        <a href="#">Terms &amp; Conditions</a> |{" "}
                                        <a href="#">Privacy Policy</a> | TICO
                                        Registered | Wholesale: 1322989 &amp;
                                        Retail: 1321983
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <BrIframeModal
                        fullScreen={false}
                        isOpen={isNewsletterSignUpModalOpen}
                        addClassName={"wider"}
                        handleClick={() =>
                            setIsNewsletterSignUpModalOpen(
                                !isNewsletterSignUpModalOpen
                            )
                        }
                    >
                        <div
                            className={"newsletter-sign-up"}
                            style={{ position: "relative" }}
                        >
                            <div
                                onClick={() =>
                                    setIsNewsletterSignUpModalOpen(
                                        !isNewsletterSignUpModalOpen
                                    )
                                }
                            >
                                <CloseIcon
                                    style={{
                                        color: "#194666",
                                        position: "absolute",
                                        right: "25px",
                                        top: "0px",
                                        cursor: "pointer",
                                    }}
                                />
                            </div>
                            <MarketoForm
                                form="newsletterSignUp"
                                additionalData={{
                                    closeForm: () =>
                                        setIsNewsletterSignUpModalOpen(
                                            !isNewsletterSignUpModalOpen
                                        ),
                                }}
                            />
                        </div>
                    </BrIframeModal>
                </footer>
            )}
        </>
    );
}

//React Imports
import React, { useState } from "react";

//Import API
import { api } from "../../api/api";

//Import Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

//Import SHA512
import SHA512 from "crypto-js/sha512";

//Set Form Styles
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "8rem 0 4rem 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {
    //Manage State
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState(localStorage.getItem("token"));

    //Pull In Styles
    const classes = useStyles();

    //Define Login Function
    const handleLogin = (event, email, password) => {
        event.preventDefault();
        api("https://api.butterfield.com/v1/my/login", {
            method: "POST",
            body: {
                userEmail: email,
                userPassword: ("" + SHA512(password)).toUpperCase(),
            },
        })
            .then((loginData) => {
                if (loginData.token) {
                    localStorage.setItem("token", loginData.token);
                    setToken(loginData.token);
                    localStorage.setItem("loggedIn", "true");
                    window.location = "/";
                } else {
                    localStorage.setItem("token", "failed");
                    setToken("failed");
                    localStorage.setItem("loggedIn", "false");
                }
            })
            .catch((err) => {
                //Do Something
                console.log(err.Error);
            });
    };

    //Render
    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                {token == "expired" ? (
                    <Alert severity="warning">
                        Login has expired, please login again.
                    </Alert>
                ) : (
                    ""
                )}
                {token == "failed" ? (
                    <Alert severity="warning">
                        Incorrect Login Credentials
                    </Alert>
                ) : (
                    ""
                )}
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Typography component="h1" variant="h5">
                    {email}
                </Typography>
                <Typography component="h1" variant="h5">
                    {password.replace(/[a-zA-Z0-9_!@#$%^&*()-=+]/g, "*")}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        style={{ padding: 0 }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        autoFocus
                    />
                    <TextField
                        style={{ padding: 0 }}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(event) => handleLogin(event, email, password)}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href="https://my.butterfield.com/#/forgotPassword"
                                variant="body2"
                            >
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link
                                href="https://my.butterfield.com/#/signup"
                                variant="body2"
                            >
                                Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}

const userLookup = [
    {
        id: 9804,
        username: "brad",
        name: "brad crockett",
        email: "brad@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 21410,
        username: "butter02",
        name: "steve wilson",
        email: "steve.wilson@butterfield.com",
        "last login": "2021-05-06",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 21411,
        username: "butter03",
        name: "christine tucker",
        email: "christine.tucker@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 21412,
        username: "butter04",
        name: "michele harvey",
        email: "michele.harvey@butterfield.com",
        "last login": "2021-12-13",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 25581,
        username: "butter05",
        name: "stephanie gulledge",
        email: "stephanie.gulledge@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 25582,
        username: "butter06",
        name: "marilisa sgambato",
        email: "marilisa.sgambato@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 29244,
        username: "butter07",
        name: "rob grieve",
        email: "rob.grieve@butterfield.com",
        "last login": "2021-12-13",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 29277,
        username: "butter08",
        name: "annalise andrews",
        email: "annalise.andrews@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 29698,
        username: "butter09",
        name: "karen macrae",
        email: "karen.macrae@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30252,
        username: "butter10",
        name: "jerome bore",
        email: "jerome.bore@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30253,
        username: "butter11",
        name: "oscar biedma",
        email: "oscar.biedma@butterfield.com",
        "last login": "2021-12-13",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30254,
        username: "butter12",
        name: "georgia yuill",
        email: "georgia.yuill@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30255,
        username: "butter13",
        name: "christina cain",
        email: "christina.cain@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30256,
        username: "butter14",
        name: "tom abraham",
        email: "tom.abraham@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30257,
        username: "butter15",
        name: "marya valli",
        email: "marya.valli@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30258,
        username: "butter16",
        name: "mario koch",
        email: "mario.koch@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30259,
        username: "butter17",
        name: "dana quinn",
        email: "dana.quinn@butterfield.com",
        "last login": "2021-12-08",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30260,
        username: "butter18",
        name: "nathan lane",
        email: "nathan.lane@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30261,
        username: "butter19",
        name: "courtney mundy",
        email: "courtney.mundy@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30262,
        username: "butter20",
        name: "veronika macas",
        email: "veronika.macas@butterfield.com",
        "last login": "2021-11-23",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30263,
        username: "butter21",
        name: "orsolya kako",
        email: "orsolya.kako@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30264,
        username: "butter22",
        name: "dane tredway",
        email: "dane.tredway@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30265,
        username: "butter23",
        name: "eugenie weiss",
        email: "eugenie.weiss@butterfield.com",
        "last login": "2021-12-10",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30266,
        username: "butter24",
        name: "butterfield marketing login",
        email: "wetualerts@butterfield.com",
        "last login": "2021-12-13",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30267,
        username: "butter25",
        name: "annik boulva",
        email: "annik.boulva@butterfield.com",
        "last login": "2021-10-28",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30269,
        username: "butter27",
        name: "dale sherrow",
        email: "dale.sherrow@butterfield.com",
        "last login": "2021-12-09",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30271,
        username: "butter29",
        name: "mark harris",
        email: "mark.harris@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 30675,
        username: "butter31",
        name: "marianne abraham (reassigned to jeff)",
        email: "jeff.barfield@butterfield.com",
        "last login": "2021-09-17",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 33132,
        username: "butter32",
        name: "laura benn",
        email: "laura.benn@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 33133,
        username: "butter33",
        name: "liana mallia",
        email: "liana.mallia@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 33215,
        username: "butter34",
        name: "general user",
        email: "info@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 33628,
        username: "butter35",
        name: "isabel lavandeira",
        email: "isabel.lavandeira@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 33629,
        username: "butter36",
        name: "pj muirhead",
        email: "pj.muirhead@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 34116,
        username: "butter38",
        name: "yvonne power",
        email: "yvonne.power@butterfield.com",
        "last login": "2021-12-14",
        admin: "admin",
        field7: "edit",
    },
    {
        id: 34207,
        username: "butter39",
        name: "alvaro diez diaz",
        email: "alvaro.diez.diaz@butterfield.com",
        "last login": "2021-12-15",
        admin: "admin",
        field7: "edit",
    },
];

export default userLookup;

const activeForms = {
    contactUs: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1380,
        callback: () => {
            console.log("Contact Us Form Loaded Successfully");
        },
    },
    smallGroupBookNow: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1384,
        callback: () => {
            console.log("Small Group Book Now Form Loaded Successfully");
        },
    },
    startPlanning: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1386,
        callback: () => {
            console.log("Start Planning Form Loaded Successfully");
        },
    },
    requestItinerary: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1389,
        callback: () => {
            console.log("Request An Itinerary Form Loaded Successfully");
        },
    },
    newsletterSignUp: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1390,
        callback: () => {
            console.log("Newsletter Sign Up Form Loaded Successfully");
        },
    },
    shareTrip: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1391,
        callback: () => {
            console.log("Share Trip Form Loaded Successfully");
        },
    },
    privatizeDeparture: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1394,
        callback: () => {
            console.log("Privatize Departure Form Loaded Successfully");
        },
    },
    startCustomizing: {
        baseUrl: "//app-sjh.marketo.com",
        munchkinId: "896-KLO-962",
        formId: 1396,
        callback: () => {
            console.log("Start Customizing Form Loaded Successfully");
        },
    },
};

export default activeForms;

import React, { useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

const googleCaptchaSection = () => (
    <div
        style={{
            margin: "30px 0px",
            color: "#fff",
            border: "1px solid #fff",
            textAlign: "center",
        }}
    >
        GOOGLE CAPTCHA
    </div>
);

const emailOptInSection = () => (
    <div className={"newsletter-subscription"}>
        <FormControlLabel
            control={
                <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    name="newsletter-subscription"
                    checked={false}
                    onChange={() => {
                        console.log("Checkbox Click");
                    }}
                />
            }
            label="I would like to receive regular email updates
                    from Butterfield & Robinson."
            style={{ marginRight: 0, color: "#fff" }}
        />
    </div>
);

const privacyPolicy = () => (
    <p style={{ textAlign: "center", color: "#fff" }}>
        By submitting this form, you agree to the Butterfield & Robinson{" "}
        <a href="/terms-and-conditions" style={{ color: "#70B2C4" }}>
            Terms
        </a>{" "}
        and{" "}
        <a href="/privacy-policy" style={{ color: "#70B2C4" }}>
            Privacy Policy
        </a>
        .
    </p>
);

const ContactUsForm = (props) => {
    const {
        formSubmissionObject,
        implicitUpdateFormSubmissionObject,
        explicitUpdateFormSubmissionObject,
        handleSubmit,
        submitted,
    } = props;

    //Set hidden form fields
    useEffect(() => {
        explicitUpdateFormSubmissionObject({
            explictField1: "Hello",
            explictField2: "World",
        });
    }, []);

    return (
        <div style={{ backgroundColor: "#494949", padding: "40px 20px" }}>
            <div
                style={{
                    maxWidth: "600px",
                    margin: "0 auto",
                    borderBottom: "1px solid #fff",
                    paddingBottom: "60px",
                }}
            >
                {submitted ? (
                    <div>
                        <h3
                            style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 300,
                                fontSize: "22px",
                                color: "#fff",
                                textTransform: "uppercase",
                            }}
                        >
                            Thank you for your message!
                        </h3>
                        <p style={{ color: "#fff", textAlign: "center" }}>
                            One of our team members will get back to you
                            shortly.
                        </p>
                    </div>
                ) : (
                    <>
                        <div>
                            <h3
                                style={{
                                    fontFamily: "Lora, serif",
                                    fontWeight: 300,
                                    fontSize: "22px",
                                    color: "#fff",
                                    textTransform: "uppercase",
                                    textAlign: "center",
                                }}
                            >
                                Get in touch
                            </h3>
                            <p style={{ color: "#fff", textAlign: "center" }}>
                                Send us a note using the form below.
                                <br />
                                We look forward to getting back to you.
                            </p>
                        </div>
                        <div
                            className={"contact-fields"}
                            style={{
                                maxWidth: "400px",
                                margin: "0 auto",
                            }}
                        >
                            <TextField
                                id={"FirstName"}
                                name={"FirstName"}
                                label={"First Name"}
                                placeholder={"Please enter your first name."}
                                style={{
                                    width: "100%",
                                }}
                                value={formSubmissionObject["FirstName"]}
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                            <TextField
                                id={"LastName"}
                                name={"LastName"}
                                label={"Last Name"}
                                placeholder={"Please enter your last name."}
                                style={{
                                    width: "100%",
                                }}
                                value={formSubmissionObject["LastName"]}
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                            <TextField
                                id={"Email"}
                                name={"Email"}
                                label={"Email"}
                                placeholder={
                                    "Please enter the email you'd like to be contact at."
                                }
                                style={{ width: "100%" }}
                                value={formSubmissionObject["Email"]}
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                            <TextField
                                id={"Message"}
                                name={"Message"}
                                label={"Message"}
                                placeholder={
                                    "What would you like to talk with us about?"
                                }
                                style={{ width: "100%" }}
                                value={formSubmissionObject["Message"]}
                                onChange={implicitUpdateFormSubmissionObject}
                                multiline
                                rows={4}
                            />
                            {googleCaptchaSection()}
                            {emailOptInSection()}
                            {privacyPolicy()}
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginTop: "30px",
                                    marginBottom: "-20px",
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disableElevation
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ContactUsForm;

//React and Material UI Imports
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

//React Slick Carousel Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Build Component Styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    text: {
        textAlign: "left",
    },
    testimonialsContainer: {
        backgroundColor: "#eadfb0",
        padding: "20px 20px 60px 20px",
    },
    testimonialTitle: {
        fontFamily: theme.typography.h4.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        fontSize: theme.typography.h4.fontSize,
        color: "#494949",
        textTransform: "uppercase",
    },
    testimonialText: {
        fontFamily: theme.typography.h4.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        fontSize: "16px",
        color: "#494949",
        fontStyle: "italic",
    },
}));

//Run Component
export default function Testimonials({ tripContent }) {
    //const { testimonials, destinationName } = props;
    let testimonials = [
        {
            traveller_name: "",
            trip_name: "",
            testimonial: tripContent.trip?.cms_quote_1,
        },
        {
            traveller_name: "",
            trip_name: "",
            testimonial: tripContent.trip?.cms_quote_2,
        },
        {
            traveller_name: "",
            trip_name: "",
            testimonial: tripContent.trip?.cms_quote_3,
        },
    ];

    //Pull in styles
    const classes = useStyles();

    const renderTestimonials = () =>
        testimonials.map((testimonial) => {
            if (testimonial.testimonial) {
                return (
                    <div className="testimonial">
                        <p className={classes.testimonialText}>
                            {testimonial.testimonial}
                        </p>
                    </div>
                );
            }
        });

    return (
        <div className={classes.testimonialsContainer}>
            <div className={classes.root}>
                <Container maxWidth="md">
                    <h1 className={classes.testimonialTitle}>
                        What Our Travellers Say
                    </h1>
                    <Slider
                        dots={true}
                        autoplay={true}
                        autoplaySpeed={5000}
                        arrows={false}
                    >
                        {renderTestimonials()}
                    </Slider>
                </Container>
            </div>
        </div>
    );
}

Testimonials.propTypes = {
    testimonials: PropTypes.array.isRequired,
    destinationName: PropTypes.string.isRequired,
};

//React & Material UI Imports
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

//Import Global Components
import DayCard from "../elements/DayCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "800px",
        margin: "auto",
        textAlign: "left",
        padding: "60px 20px 20px 20px",
    },
    hero: {
        position: "relative",
        textAlign: "center",
        maxHeight: "50vh",
        overflow: "hidden",
        width: "100%",
    },
    title: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        marginTop: 0,
        fontSize: "2rem",
    },
    text: {
        fontFamily: theme.typography.body1.fontFamily,
        "& p": {
            textAlign: "justify",
        },
    },
    introduction: {
        maxWidth: "980px",
        margin: "0 auto",
        overflow: "hidden",
    },
    rendezvousDropOffContainer: {
        maxWidth: "1000px",
        margin: "0 auto",
    },
    rendezvousDropOff: {
        display: "flex",
        textTransform: "uppercase",
        fontSize: "12px",
        position: "relative",
    },
    hotel1: {
        backgroundColor: "#194666",
        padding: "35px",
        height: "460px",
    },
    hotel2: {
        backgroundColor: "#70B2C4",
        padding: "35px",
        textTransform: "uppercase",
    },
    hotelImageContainer: {
        width: "100%",
        height: "100%",
    },
    hotelImage: {
        objectFit: "cover",
        height: "100%",
        width: "100%",
    },
    text4: {
        fontFamily: theme.typography.body1.fontFamily,
        textAlign: "left",
        fontSize: "14px",
        color: "#fff",
        margin: 0,
        padding: 0,
        listStyle: "none",
    },
    title2: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "20px",
        color: "#fff",
        marginTop: 0,
        fontWeight: 300,

        paddingBottom: "10px",
    },
    dailyBreakdownGallery: {
        height: "500px",
    },
    dailyBreakdownGalleryMobile: {
        height: "35vh",
    },
    readMore: {
        "& p:nth-child(2)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "24px" /* fallback */,
            maxHeight: "72px" /* fallback */,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
        },
        "& p:nth-child(n+3)": {
            display: "none",
        },
    },
}));

//Run Component
export default function ItineraryContent(props) {
    const {
        urlToHyperlink,
        tripType,
        isBooked,
        isValidDate,
        itinerary,
        itineraryRelatedContent,
        tripStart,
        dateDisplayOptions,
        finalLeg,
        itineraryHotelContent,
        itineraryAltHotelContent,
        itineraryDestinationContent,
        search,
        addDays,
        mobileWidth,
        isPrint,
        selfGuided,
    } = props;

    //Pull In Styles
    const classes = useStyles();

    const generateAvailableDailyImagesArray = (leg, period) => {
        //Initialize imageSourcesArray to hold current day's available image sources
        let imageSourceArray = [];

        //Add all non-information and non-destination activities to imageSourceArray
        if (period.activities) {
            period.activities
                .sort((a, b) => a.period_sequence - b.period_sequence)
                .filter(
                    (activity) =>
                        (activity.type != "Information") &
                        (activity.type != "Destination")
                )
                .map((activity) =>
                    imageSourceArray.push(activity.content_entity_id)
                );
        }

        //Add all destination activities to imageSourceArray
        if (period.activities) {
            period.activities
                .filter((activity) => activity.type == "Destination")
                .map((activity) =>
                    imageSourceArray.push(activity.content_entity_id)
                );
        }

        //Add leg destination to imageSourceArray
        if (leg.destination_content_entity_id) {
            imageSourceArray.push(leg.destination_content_entity_id);
        }

        //Add leg accommadation to imageSourceArray
        if (leg.content_entity_id) {
            imageSourceArray.push(leg.content_entity_id);
        }

        //Initialize availableImages to hold current day's available images
        let availableImages = [];

        //Iterate over each image source and check if source has images, if they do push into availableImages
        imageSourceArray.map((imageSource) => {
            if (imageSource !== 25862) {
                let images = search(
                    imageSource,
                    "map_object_id",
                    itineraryRelatedContent
                )?.content?.images;
                if (images) {
                    images.map((image) =>
                        availableImages.push(
                            `https://wetu.com/ImageHandler/c833x500/${image.url_fragment}`
                        )
                    );
                }
            }
        });

        return availableImages;
    };

    //External array to store images that have been previously used
    let usedImages = [];

    const renderDailyImageCarousel = (imageArray) => {
        let daysFinalImageArray = [];

        imageArray.map((image) => {
            if (
                usedImages.indexOf(image) === -1 &&
                daysFinalImageArray.length < 3
            ) {
                daysFinalImageArray.push(image);
                usedImages.push(image);
            }
        });

        return daysFinalImageArray.map((image) => (
            <div className={classes.dailyBreakdownGalleryContainer}>
                <div
                    className={
                        mobileWidth
                            ? classes.dailyBreakdownGalleryMobile
                            : classes.dailyBreakdownGallery
                    }
                    style={
                        isPrint ? { height: "175px", padding: "0.5rem" } : {}
                    }
                >
                    <img
                        src={image}
                        style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                </div>
            </div>
        ));
    };

    //Render
    return (
        <div
            style={{
                maxWidth: "980px",
                margin: "auto",
                textAlign: "justify",
                paddingTop: isPrint ? 0 : "50px",
            }}
        >
            {isPrint ? (
                <></>
            ) : (
                <div style={{ paddingBottom: "2rem" }}>
                    <h3
                        className={classes.title}
                        style={{
                            color: "#194666",
                            borderBottom: "1px solid #494949",
                            maxWidth: "85%",
                            margin: "0 auto",
                            paddingBottom: "2rem",
                            textAlign: "center",
                        }}
                        id="itinerary"
                    >
                        Itinerary
                    </h3>
                </div>
            )}
            <>
                {itinerary.legs.map((leg, i) =>
                    leg.periods.map((period, j) => (
                        <>
                            <DayCard
                                urlToHyperlink={urlToHyperlink}
                                itineraryRelatedContent={
                                    itineraryRelatedContent
                                }
                                leg={leg}
                                period={period}
                                finalLeg={finalLeg}
                                carouselImages={renderDailyImageCarousel(
                                    generateAvailableDailyImagesArray(
                                        leg,
                                        period
                                    )
                                )}
                                tripType={tripType}
                                isBooked={isBooked}
                                isValidDate={isValidDate}
                                dateDisplayOptions={dateDisplayOptions}
                                addDays={addDays}
                                search={search}
                                tripStart={tripStart}
                                itineraryHotelContent={itineraryHotelContent}
                                itineraryAltHotelContent={
                                    itineraryAltHotelContent
                                }
                                routes={itinerary.routes}
                                isPrint={isPrint}
                                selfGuided={selfGuided}
                            />
                            <div style={{ pageBreakAfter: "always" }}></div>
                        </>
                    ))
                )}
            </>
        </div>
    );
}

ItineraryContent.propTypes = {
    tripType: PropTypes.string.isRequired,
    isBooked: PropTypes.bool.isRequired,
    itinerary: PropTypes.object.isRequired,
    itineraryRelatedContent: PropTypes.any.isRequired,
    tripStart: PropTypes.any.isRequired,
    dateDisplayOptions: PropTypes.object.isRequired,
    itineraryHotelContent: PropTypes.object.isRequired,
    itineraryAltHotelContent: PropTypes.object.isRequired,
    itineraryDestinationContent: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    addDays: PropTypes.func.isRequired,
};

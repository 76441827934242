import React from "react";

import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

//Build Component Styles
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    backToTop: {
        color: "#fff",
        backgroundColor: "#194666",
        "&:hover": {
            backgroundColor: "#194666",
        },
    },
}));

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}

const ScrollToTopButton = ({ mobileWidth }) => {
    const classes = useStyles();
    const toTopScrollTrigger = useScrollTrigger({
        threshold: 500,
    });

    return (
        <Zoom in={toTopScrollTrigger}>
            <div
                style={{
                    position: "fixed",
                    bottom: mobileWidth ? "100px" : "75px",
                    right: mobileWidth ? "50px" : "120px",
                }}
            >
                <Fab
                    size="small"
                    className={classes.backToTop}
                    aria-label="add"
                    onClick={() => {
                        scrollToTop();
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    );
};

export default ScrollToTopButton;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import CancelIcon from "@material-ui/icons/Cancel";
import { Container } from "@material-ui/core";

import EmblaImageCarousel from "./EmblaImageCarousel";
import { isValidDate } from "../../utilities/HelperFunctions/helperFunctions";

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content",
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    hotelImage: {
        objectFit: "cover",
        [theme.breakpoints.down("md")]: {
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            height: "100%",
        },
    },
    hotelTitle: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "24px",
        color: "#fff",
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 300,
    },
    hotelSuperTitle: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "16px",
        color: "#fff",
        fontStyle: "italic",
        marginBottom: 0,
        fontWeight: 300,
    },
    hotelSubtitle: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "18px",
        color: "#6dafc1",
        fontStyle: "italic",
        marginTop: 0,
        fontWeight: 300,
    },
    hotelText: {
        textAlign: "justify",
        color: "#fafafa",
        "& p": {
            textAlign: "justify",
            color: "#fafafa",
        },
    },
    hotelNextPrevButton: {
        fontStyle: "italic",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "55%",
        },
        [theme.breakpoints.up("md")]: {
            width: "30%",
        },
    },
}));

export default function AccommadationDialog({
    accommadation,
    activeDialog,
    setActiveDialog,
    index,
    length,
    checkInDay,
    checkOutDay,
    isBooked,
}) {
    const classes = useStyles();

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState("md");

    const handleClose = () => {
        setActiveDialog(null);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };
    console.log(accommadation);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={activeDialog === index}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <CancelIcon
                    onClick={() => handleClose()}
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "15px",
                        zIndex: 999,
                        color: "#fafafa",
                        cursor: "pointer",
                    }}
                />
                <Grid container>
                    <Grid item xs={12} md={6} style={{ position: "relative" }}>
                        <EmblaImageCarousel
                            slides={accommadation.hotel.content.images
                                .slice(0, 3)
                                .map((image) => {
                                    return (
                                        <img
                                            src={`https://wetu.com/ImageHandler/c500x550/${image.url_fragment}`}
                                            className={classes.hotelImage}
                                        />
                                    );
                                })}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            backgroundColor: "#194666",
                            padding: "0 1rem 1.5rem",
                        }}
                    >
                        <Container>
                            <div className={classes.hotel1}>
                                <h4 className={classes.hotelSuperTitle}>
                                    {accommadation.type === "OwnArrangement" ? (
                                        <>Own Arrangements</>
                                    ) : (
                                        <></>
                                    )}
                                </h4>
                                <h3 className={classes.hotelTitle}>
                                    {accommadation.hotel.name}
                                </h3>
                                <h4 className={classes.hotelSubtitle}>
                                    {accommadation.hotel.position ? (
                                        <>
                                            {
                                                accommadation.hotel.position
                                                    .destination
                                            }
                                            ,{" "}
                                            {
                                                accommadation.hotel.position
                                                    .country
                                            }
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </h4>
                                <div
                                    className={classes.hotelText}
                                    dangerouslySetInnerHTML={{
                                        __html: accommadation.hotel.content
                                            .general_description,
                                    }}
                                ></div>
                                {accommadation.rooms ? (
                                    <>
                                        {accommadation.rooms.map((room) => (
                                            <>
                                                {room.rooms > 1 ? (
                                                    <h3
                                                        className={
                                                            classes.hotelTitle
                                                        }
                                                        style={{
                                                            fontSize: "18px",
                                                            fontStyle: "italic",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        {room.rooms}x{" "}
                                                        {room.name}
                                                    </h3>
                                                ) : (
                                                    <h3
                                                        className={
                                                            classes.hotelTitle
                                                        }
                                                        style={{
                                                            fontSize: "18px",
                                                            fontStyle: "italic",
                                                            margin: 0,
                                                        }}
                                                    >
                                                        {room.name}
                                                    </h3>
                                                )}
                                            </>
                                        ))}
                                    </>
                                ) : (
                                    <></>
                                )}
                                <p
                                    className={classes.hotelText}
                                    style={{
                                        margin: "1rem 0 0 0",
                                        fontStyle: "italic",
                                    }}
                                >
                                    {accommadation.nights > 1
                                        ? `${accommadation.nights} Nights`
                                        : `${accommadation.nights} Night`}
                                </p>
                                {isBooked &&
                                isValidDate(checkInDay) &&
                                isValidDate(checkOutDay) ? (
                                    <p
                                        className={classes.hotelText}
                                        style={{ marginTop: 0 }}
                                    >
                                        Check in:{" "}
                                        {new Intl.DateTimeFormat("en-US", {
                                            // weekday: "long",
                                            // year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            timeZone: "UTC",
                                        }).format(checkInDay)}{" "}
                                        | Check out:{" "}
                                        {new Intl.DateTimeFormat("en-US", {
                                            // weekday: "long",
                                            // year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            timeZone: "UTC",
                                        }).format(checkOutDay)}
                                    </p>
                                ) : (
                                    <p
                                        className={classes.hotelText}
                                        style={{ marginTop: 0 }}
                                    >
                                        {accommadation.checkOutDay >
                                        accommadation.checkInDay
                                            ? "Nights " +
                                              (accommadation.checkInDay + 1) +
                                              " - " +
                                              (accommadation.checkInDay +
                                                  accommadation.nights)
                                            : "Night " +
                                              (accommadation.checkInDay + 1)}
                                    </p>
                                )}

                                {/* <p className={classes.hotelText}>
                                    Check Out:{" "}
                                    {new Intl.DateTimeFormat("en-US", {
                                        // weekday: "long",
                                        // year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        timeZone: "UTC",
                                    }).format(checkOutDay)}
                                </p> */}
                                {accommadation.bookingReference ? (
                                    <p className={classes.hotelText}>
                                        Reference:{" "}
                                        {accommadation.bookingReference}
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Container>
                    </Grid>
                    <Container>
                        <Grid container>
                            <Grid item xs={6}>
                                {index != 0 ? (
                                    <Button
                                        className={classes.hotelNextPrevButton}
                                        onClick={() => {
                                            setActiveDialog(index - 1);
                                        }}
                                    >
                                        {"< Prev Hotel"}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {index != length - 1 ? (
                                    <Button
                                        className={classes.hotelNextPrevButton}
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            setActiveDialog(index + 1);
                                        }}
                                    >
                                        {"Next Hotel >"}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Dialog>
        </React.Fragment>
    );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import AccommadationDialog from "./AccommadationDialog";

import { isValidDate } from "../../utilities/HelperFunctions/helperFunctions";

const useStyles = makeStyles((theme) => ({
    accommadationCard: {
        position: "relative",
    },
    image: {
        listStyle: "none",
        textAlign: "left",
        height: "420px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding: "45px 35px",
        color: "#fff",
        position: "relative",
        overflow: "hidden",
    },
    colorOverlay: {
        background: `#194666`,
        listStyle: "none",
        textAlign: "left",
        height: "420px",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        padding: "45px 35px",
        color: "#fff",
        position: "absolute",
        overflow: "hidden",
        zIndex: 2,
        top: 0,
        opacity: 0.65,
    },
    textOverlay: {
        height: "420px",
        width: "100%",
        textAlign: "center",
        color: "#fff",
        position: "absolute",
        overflow: "hidden",
        zIndex: 3,
        top: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    textTitle: {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "20px",
        fontWeight: 300,
    },
    textSubtitle: {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "16px",
        fontWeight: 300,
    },
}));

const AccommadationCard = ({
    accommadation,
    activeDialog,
    setActiveDialog,
    index,
    length,
    addDays,
    isBooked,
}) => {
    const classes = useStyles();

    // const [open, setOpen] = React.useState(false);

    let checkInCheckOut = (accommadation) => {
        if (!accommadation) {
            return `No accommadation provided...`;
        }

        if (!(accommadation.checkOutDay > accommadation.checkInDay)) {
            return `Night ${accommadation.checkInDay + 1}`;
        }

        return `Nights ${accommadation.checkInDay + 1} - ${
            accommadation.checkInDay + accommadation.nights
        }`;
    };

    let checkInDay;
    let checkOutDay;

    if (accommadation.tripStart && isBooked) {
        checkInDay = addDays(accommadation.tripStart, accommadation.checkInDay);
        checkOutDay = addDays(checkInDay, accommadation.nights);
    }

    return (
        <>
            <Card className={classes.accommadationCard} raised={true}>
                <CardActionArea onClick={() => setActiveDialog(index)}>
                    <div
                        className={classes.image}
                        style={{
                            backgroundImage: `url(https://wetu.com/ImageHandler/c500x550/${accommadation.hotel.content?.images[0]?.url_fragment})`,
                        }}
                    ></div>
                    <div className={classes.colorOverlay}></div>
                    <div className={classes.textOverlay}>
                        <>
                            <div
                                style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <h3 className={classes.textTitle}>
                                    {accommadation.hotel.name}
                                </h3>
                            </div>
                            {/* <h3 className={classes.textSubtitle}>
                                {accommadation.nights > 1
                                    ? `${accommadation.nights} Nights`
                                    : `${accommadation.nights} Night`}
                            </h3> */}
                            <div
                                style={{ position: "absolute", bottom: "1rem" }}
                            >
                                {isBooked ? (
                                    isValidDate(accommadation.tripStart) ? (
                                        <>
                                            <h3
                                                className={classes.textSubtitle}
                                                style={{ marginBottom: 0 }}
                                            >
                                                Check in:{" "}
                                                {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                        // weekday: "long",
                                                        // year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        timeZone: "UTC",
                                                    }
                                                ).format(checkInDay)}
                                            </h3>
                                            <h3
                                                className={classes.textSubtitle}
                                                style={{ marginTop: 0 }}
                                            >
                                                Check out:{" "}
                                                {new Intl.DateTimeFormat(
                                                    "en-US",
                                                    {
                                                        // weekday: "long",
                                                        // year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        timeZone: "UTC",
                                                    }
                                                ).format(checkOutDay)}
                                                {/* {accommadation.tripStart} */}
                                            </h3>
                                        </>
                                    ) : (
                                        <h3 className={classes.textSubtitle}>
                                            {checkInCheckOut(accommadation)}
                                        </h3>
                                    )
                                ) : (
                                    <h3 className={classes.textSubtitle}>
                                        {checkInCheckOut(accommadation)}
                                    </h3>
                                    // <></>
                                )}
                            </div>
                        </>
                    </div>
                </CardActionArea>
            </Card>
            <AccommadationDialog
                accommadation={accommadation}
                activeDialog={activeDialog}
                setActiveDialog={setActiveDialog}
                index={index}
                length={length}
                checkInDay={checkInDay}
                checkOutDay={checkOutDay}
                isBooked={isBooked}
            />
        </>
    );
};

export default AccommadationCard;

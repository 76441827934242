import React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

//React Router Imports
import { useLocation } from "react-router-dom";

//Import Page Renderer
import Itinerary from "./Itinerary";
import Login from "../elements/Login";
import FourZeroFour from "../elements/FourZeroFour";

//Import Switch and Route
import { Switch, Route } from "react-router-dom";

export default function PageSwitch(props) {
    const { loginState } = props;
    const mobileWidth = useMediaQuery("(max-width:959px)");

    let searchParams = new URLSearchParams(useLocation().search);
    let isPrint = searchParams.get("isPrint") === "true";

    return (
        <main
            // style={{ paddingTop: isPrint ? 0 : mobileWidth ? "60px" : "95px" }}
            style={{ paddingTop: isPrint ? 0 : "60px" }}
        >
            <Switch>
                <Route
                    exact
                    path="/itinerary/:wetuid"
                    children={
                        <Itinerary isPrint={isPrint} loginState={loginState} />
                    }
                ></Route>
                <Route
                    exact
                    path="/itinerary/:section/:wetuid"
                    children={
                        <Itinerary isPrint={isPrint} loginState={loginState} />
                    }
                ></Route>
                <Route exact path="/login" children={<Login />}></Route>
                <Route component={FourZeroFour} />
            </Switch>
        </main>
    );
}

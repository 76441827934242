import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BrButton from "./BrButton";

//React Helmet
import { Helmet } from "react-helmet";

//Router
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        maxWidth: "650px",
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        margin: "3rem auto 0",
        fontSize: "2.5rem",
        color: "rgb(73, 73, 73)",
    },
    text: {
        maxWidth: "1060px",
        fontWeight: 500,
        margin: "2rem auto 0",
        fontSize: "1.5rem",
        color: "rgb(73, 73, 73)",
        textAlign: "center",
    },
}));

const FourZeroFour = () => {
    //Pull In Styles
    const classes = useStyles();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>We're lost... | Butterfield & Robinson</title>
            </Helmet>
            <div
                style={{
                    marginBottom: "10rem",
                    textDecoration: "none",
                }}
            >
                <div style={{ height: "40vh" }}>
                    <img
                        style={{
                            width: "100%",
                            height: "40vh",
                            objectFit: "cover",
                        }}
                        src="https://blog.butterfield.com/wp-content/uploads/2021/03/MicrosoftTeams-image-2-scaled.jpg"
                        alt="road"
                    />
                </div>
                <div style={{ padding: "0 5% 5rem" }}>
                    <h1 className={classes.title}>
                        OOPS! Looks like we took a wrong turn.
                    </h1>
                    <p className={classes.text}>
                        Get back on the right path by calling us at
                        1-866-551-9090 to speak with a Travel Advisor.
                    </p>
                </div>
                {/* <Link
                    to={"/itinerary/6ECB70BB-175B-41FC-9477-5BA5F699F70B"}
                    style={{ marginRight: "20px", textDecoration: "none" }}
                >
                    <BrButton color="smallGroupGreen" buttonText="Sample" />
                </Link>
                <Link
                    to={"/itinerary/411B80D0-CEBF-4D5A-8F1F-8C4404C9CB66"}
                    style={{ marginRight: "20px", textDecoration: "none" }}
                >
                    <BrButton color="smallGroupGreen" buttonText="Personal" />
                </Link>
                <Link
                    to={"/itinerary/280C34AB-554C-46CD-BB9D-94B29BD0767B"}
                    style={{ marginRight: "20px", textDecoration: "none" }}
                >
                    <BrButton color="bespokeOrange" buttonText="Sample" />
                </Link>
                <Link
                    to={"/itinerary/6518b8da-05ae-4c41-9f08-000ed092020c"}
                    style={{ marginRight: "20px", textDecoration: "none" }}
                >
                    <BrButton color="bespokeOrange" buttonText="Personal" />
                </Link> */}
            </div>
        </>
    );
};

export default FourZeroFour;

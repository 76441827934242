import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    iFrame: {
        border: 0,
        height: "100%",
    },
    previousPageButton: {
        color: "#fff",
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "14px",
        cursor: "pointer",
        textTransform: "uppercase",
    },
    fullScreenBackground: {
        "& div": {
            "& div.MuiDialog-paperFullScreen": {
                background: "#494949!important",
            },
        },
    },
}));

/*
WARNING:
    The function below must exists outside the IframeModal function otherwise
    once the user closes the modal the layer of the transition will still be sitting
    on top of the parent node and therefore not allow you to click on anything.
*/
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IframeModal(props) {
    const classes = useStyles();
    const {
        source,
        handleClick,
        isOpen,
        fullScreen,
        previousPageHeading,
        background,
        padding,
        addClassName,
        closeIcon,
    } = props;

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                onClose={() => handleClick(false)}
                TransitionComponent={Transition}
                className={
                    background
                        ? classes.fullScreenBackground
                        : addClassName
                        ? addClassName
                        : ""
                }
            >
                {closeIcon ? (
                    <CloseIcon
                        onClick={() => handleClick(false)}
                        style={{
                            height: "25px",
                            width: "25px",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            zIndex: 999,
                        }}
                    />
                ) : (
                    ""
                )}
                {previousPageHeading ? (
                    <div
                        onClick={() => handleClick(false)}
                        style={{
                            width: "85%",
                            margin: "auto",
                            paddingTop: "20px",
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <ArrowRightAltIcon
                                style={{
                                    color: "#fff",
                                    position: "relative",
                                    top: "11px",
                                    marginRight: "5px",
                                    transform: "scaleX(-1)",
                                    cursor: "pointer",
                                }}
                            />
                            <p className={classes.previousPageButton}>
                                {previousPageHeading}
                            </p>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                {source ? (
                    <iframe src={source} className={classes.iFrame}></iframe>
                ) : (
                    <div
                        style={
                            padding
                                ? {
                                      padding: "20px 20px 40px 20px",
                                      maxWidth: 850,
                                      margin: "auto",
                                      borderRadius: "10px",
                                  }
                                : {
                                      maxWidth: 850,
                                      margin: "auto",
                                      borderRadius: "10px",
                                  }
                        }
                    >
                        {props.children}
                    </div>
                )}
            </Dialog>
        </div>
    );
}

IframeModal.propTypes = {
    fullScreen: PropTypes.any.isRequired,
    handleClick: PropTypes.any.isRequired,
    isOpen: PropTypes.any.isRequired,
};

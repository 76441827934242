// React & Material UI Imports
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AddIcon from "@material-ui/icons/Add";

import GoogleMaps from "../elements/GoogleMaps";
import ItineraryMap from "../elements/ItineraryMap";
import AccommadationCard from "../elements/AccommadationCard";
import AccommadationDialog from "../elements/AccommadationDialog";

import { addDays } from "../__layout/Itinerary";

import {
    where,
    buildHotelArray,
} from "../../utilities/HelperFunctions/helperFunctions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "1000px",
        margin: "auto",
        textAlign: "left",
    },
    title: {
        textAlign: "left",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "18px",
        fontWeight: 300,
        margin: 0,
        textTransform: "uppercase",
    },
    sectionTitle: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        marginTop: 0,
        fontSize: "2rem",
    },
    text: {
        textAlign: "left",
        fontFamily: theme.typography.body1.fontFamily,
        marginBottom: 0,
        fontSize: "14px",
    },
    destinationDescription: {
        "& p": {
            textAlign: "justify",
        },
    },
    details: {
        //  border: "1px solid #494949",
        // padding: "30px",
        // margin: "30px 20px",
    },
    localInformationPanel: {
        boxShadow: "none",
    },
}));

//Helper Functions
// This will parse a delimited string into an array of
// arrays. The default delimiter is the comma, but this
// can be overriden in the second argument.
function CSVToArray(strData, strDelimiter) {
    // Check to see if the delimiter is defined. If not,
    // then default to comma.
    strDelimiter = strDelimiter || ",";

    // Create a regular expression to parse the CSV values.
    var objPattern = new RegExp(
        // Delimiters.
        "(\\" +
            strDelimiter +
            "|\\r?\\n|\\r|^)" +
            // Quoted fields.
            '(?:"([^"]*(?:""[^"]*)*)"|' +
            // Standard fields.
            '([^"\\' +
            strDelimiter +
            "\\r\\n]*))",
        "gi"
    );

    // Create an array to hold our data. Give the array
    // a default empty first row.
    var arrData = [[]];

    // Create an array to hold our individual pattern
    // matching groups.
    var arrMatches = null;

    // Keep looping over the regular expression matches
    // until we can no longer find a match.
    while ((arrMatches = objPattern.exec(strData))) {
        // Get the delimiter that was found.
        var strMatchedDelimiter = arrMatches[1];

        // Check to see if the given delimiter has a length
        // (is not the start of string) and if it matches
        // field delimiter. If id does not, then we know
        // that this delimiter is a row delimiter.
        if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
            // Since we have reached a new row of data,
            // add an empty row to our data array.
            arrData.push([]);
        }

        // Now that we have our delimiter out of the way,
        // let's check to see which kind of value we
        // captured (quoted or unquoted).
        if (arrMatches[2]) {
            // We found a quoted value. When we capture
            // this value, unescape any double quotes.
            var strMatchedValue = arrMatches[2].replace(
                new RegExp('""', "g"),
                '"'
            );
        } else {
            // We found a non-quoted value.
            var strMatchedValue = arrMatches[3];
        }

        // Now that we have our value string, let's add
        // it to the data array.
        arrData[arrData.length - 1].push(strMatchedValue);
    }

    // Return the parsed data.
    return arrData;
}
const buildRoute = (latLngArray) => {
    let route = [];
    let index = 0;
    latLngArray.map((coordinate, i) => {
        if (i % 2 == 0) {
            route.push({ lat: parseFloat(coordinate) });
        } else {
            route[index].lng = parseFloat(coordinate);
            index++;
        }
    });
    return route;
};

//Run Component
export default function LocalInformationContent(props) {
    const {
        bySea,
        mobileWidth,
        itineraryDestinationContent,
        localInformationData,
        itineraryCountryContent,
        itinerary,
        itineraryRelatedContent,
        itineraryHotelContent,
        isPrint,
        isBooked,
    } = props;

    //Pull In Styles
    const classes = useStyles();

    const [activeDialog, setActiveDialog] = useState(null);

    let accommadationArray = buildHotelArray(itinerary, itineraryHotelContent);

    // let checkInDay;
    // let checkOutDay;

    // if (accommadation.tripStart && isBooked) {
    //     checkInDay = addDays(accommadation.tripStart, accommadation.checkInDay);
    //     checkOutDay = addDays(checkInDay, accommadation.nights);
    // }

    const calcCheckInDay = (accommadation) => {
        let checkInDay;

        if (accommadation.tripStart && isBooked) {
            checkInDay = addDays(
                accommadation.tripStart,
                accommadation.checkInDay
            );
        }

        return checkInDay;
    };
    const calcCheckOutDay = (accommadation) => {
        let checkOutDay;

        if (accommadation.tripStart && isBooked) {
            checkOutDay = addDays(
                calcCheckInDay(accommadation),
                accommadation.nights
            );
        }

        return checkOutDay;
    };

    const routes = [];

    if (itinerary.routes) {
        for (let i = 0; i < itinerary.routes.length; i++) {
            if (itinerary.routes[i].points) {
                if (itinerary.routes[i].points.length > 0) {
                    routes.push({
                        start_content_entity_id:
                            itinerary.routes[i].start_content_entity_id,
                        end_content_entity_id:
                            itinerary.routes[i].end_content_entity_id,
                        start_day: itinerary.routes[i].start_day,
                        end_day: itinerary.routes[i].end_day,
                        mode: itinerary.routes[i].mode,
                        variant: itinerary.routes[i].variant,
                        distance: itinerary.routes[i].distance,
                        duration: itinerary.routes[i].duration,
                        start_time: itinerary.routes[i].start_time,
                        end_time: itinerary.routes[i].end_time,
                        route: buildRoute(
                            CSVToArray(itinerary.routes[i].points, ";")[0]
                        ),
                    });
                }
            } else if (itinerary.routes[i].mode != "NoTransit") {
                routes.push({
                    start_content_entity_id:
                        itinerary.routes[i].start_content_entity_id,
                    end_content_entity_id:
                        itinerary.routes[i].end_content_entity_id,
                    start_day: itinerary.routes[i].start_day,
                    end_day: itinerary.routes[i].end_day,
                    mode: itinerary.routes[i].mode,
                    variant: itinerary.routes[i].variant,
                    distance: itinerary.routes[i].distance,
                    duration: itinerary.routes[i].duration,
                    start_time: itinerary.routes[i].start_time,
                    end_time: itinerary.routes[i].end_time,
                    route: [
                        {
                            lat: where(itineraryRelatedContent, {
                                map_object_id:
                                    itinerary.routes[i].start_content_entity_id,
                            })[0].position.latitude,
                            lng: where(itineraryRelatedContent, {
                                map_object_id:
                                    itinerary.routes[i].start_content_entity_id,
                            })[0].position.longitude,
                        },
                        {
                            lat: where(itineraryRelatedContent, {
                                map_object_id:
                                    itinerary.routes[i].end_content_entity_id,
                            })[0].position.latitude,
                            lng: where(itineraryRelatedContent, {
                                map_object_id:
                                    itinerary.routes[i].end_content_entity_id,
                            })[0].position.longitude,
                        },
                    ],
                });
            }
        }
    }

    const hotels = [];

    // console.log(itineraryHotelContent);

    // for (let i = 0; i < itineraryHotelContent.length; i++) {
    //     if (itineraryHotelContent[i].position?.latitude) {
    //         hotels.push({
    //             name: itineraryHotelContent[i].name,
    //             lat: itineraryHotelContent[i].position.latitude,
    //             lng: itineraryHotelContent[i].position.longitude,
    //         });
    //     }
    // }

    for (let i = 0; i < itinerary.legs.length; i++) {
        let hotel = where(itineraryHotelContent, {
            map_object_id: itinerary.legs[i].content_entity_id,
        })[0];

        if (hotel.position?.latitude) {
            hotels.push({
                name: hotel.name,
                lat: hotel.position.latitude,
                lng: hotel.position.longitude,
                content_entity_id: hotel.map_object_id,
            });
        }
        // console.log("Hotel", hotel);
    }

    let stops = [];

    if (bySea) {
        for (let i = 0; i < itinerary.legs.length; i++) {
            if (itinerary.legs[i].stops) {
                for (let j = 0; j < itinerary.legs[i].stops.length; j++) {
                    let stop = where(itineraryDestinationContent, {
                        map_object_id:
                            itinerary.legs[i].stops[j].content_entity_id,
                    })[0];

                    if (stop.position?.latitude) {
                        stops.push({
                            name: stop.name,
                            lat: stop.position.latitude,
                            lng: stop.position.longitude,
                            content_entity_id: stop.map_object_id,
                        });
                    }
                }
            }
        }
        // for (let i = 0; i < itineraryDestinationContent.length; i++) {
        //     if (itineraryDestinationContent[i].position?.latitude) {
        //         stops.push({
        //             name: itineraryDestinationContent[i].name,
        //             lat: itineraryDestinationContent[i].position.latitude,
        //             lng: itineraryDestinationContent[i].position.longitude,
        //         });
        //     }
        // }
    }

    const buildDestinationDetails = () => {
        return (
            <>
                <div style={{ padding: "2rem 0" }}>
                    <h3
                        className={classes.sectionTitle}
                        style={{
                            color: "#194666",
                            borderBottom: "1px solid #494949",
                            maxWidth: "85%",
                            margin: "0 auto",
                            paddingBottom: "2rem",
                            textAlign: "center",
                        }}
                        id="country_information"
                    >
                        Destinations
                    </h3>
                </div>
                <Paper
                    style={{
                        margin: "1rem 0 50px 0",
                        padding: "30px",
                    }}
                    elevation={3}
                >
                    <div className={classes.details}>
                        {/* <GoogleMaps routes={routes} pins={bySea ? stops : hotels} /> */}
                        <ItineraryMap
                            itinerary={itinerary}
                            itineraryRelatedContent={itineraryRelatedContent}
                            routes={routes}
                            pins={bySea ? stops : hotels}
                            setActiveDialog={
                                bySea ? (i) => console.log(i) : setActiveDialog
                            }
                            buildRoute={buildRoute}
                            CSVToArray={CSVToArray}
                            mobileWidth={mobileWidth}
                            isBooked={isBooked}
                            bySea={bySea}
                        />
                        {accommadationArray.map((accommadation, index, arr) => {
                            return (
                                // <AccommadationCard
                                //     accommadation={accommadation}
                                //     activeDialog={activeDialog}
                                //     setActiveDialog={setActiveDialog}
                                //     index={index}
                                //     length={arr.length}
                                //     addDays={addDays}
                                //     isBooked={true}
                                // />
                                <AccommadationDialog
                                    accommadation={accommadation}
                                    activeDialog={activeDialog}
                                    setActiveDialog={setActiveDialog}
                                    index={index}
                                    length={arr.length}
                                    checkInDay={calcCheckInDay(accommadation)}
                                    checkOutDay={calcCheckOutDay(accommadation)}
                                    isBooked={isBooked}
                                />
                            );
                        })}
                    </div>
                </Paper>
                {isPrint ? (
                    <></>
                ) : (
                    <>
                        {itineraryCountryContent.map((country) => {
                            return (
                                <Paper
                                    style={{
                                        margin: "20px 0 50px 0",
                                        padding: "75px 7.5%",
                                        textAlign: "left",
                                    }}
                                    elevation={3}
                                >
                                    <Typography
                                        className={classes.title}
                                        style={{
                                            color: "#194666",
                                            // borderBottom: "1px solid #494949",
                                            maxWidth: "400px",
                                            margin: "auto",
                                            paddingBottom: "10px",
                                            textAlign: "center",
                                            fontSize: "24px",
                                        }}
                                        id="country_information"
                                    >
                                        {country.name}
                                    </Typography>
                                    <Typography>
                                        <div
                                            className={
                                                classes.destinationDescription
                                            }
                                            style={{ padding: "0 7.5% 2rem" }}
                                            dangerouslySetInnerHTML={{
                                                __html: country.content
                                                    .general_description,
                                            }}
                                        ></div>
                                    </Typography>
                                    <>
                                        {itineraryDestinationContent
                                            .filter(
                                                (destination) =>
                                                    destination.position
                                                        .country_content_entity_id ==
                                                    country.map_object_id
                                            )
                                            .map((destination) => (
                                                <div
                                                    style={{
                                                        //maxWidth: "800px",
                                                        margin: "0 auto",
                                                        padding: "0 7.5% 2rem",
                                                    }}
                                                >
                                                    {" "}
                                                    <p
                                                        className={
                                                            classes.title
                                                        }
                                                        style={{
                                                            textAlign: "center",
                                                            margin: "20px 0px 10px 0px",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: destination.name,
                                                        }}
                                                    ></p>
                                                    <div
                                                        className={
                                                            classes.destinationImages
                                                        }
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            // overflowX: "hidden",
                                                        }}
                                                    >
                                                        {destination.content
                                                            .images.length >
                                                        2 ? (
                                                            mobileWidth ? (
                                                                destination.content.images
                                                                    .slice(0, 1)
                                                                    .map(
                                                                        (
                                                                            image
                                                                        ) => (
                                                                            <img
                                                                                // src={destination.images}
                                                                                src={`https://wetu.com/ImageHandler/c675x425/${image.url_fragment}`}
                                                                                style={{
                                                                                    float: "left",
                                                                                    width: "100%",
                                                                                    height: "33vh",
                                                                                    objectFit:
                                                                                        "cover",
                                                                                }}
                                                                            />
                                                                        )
                                                                    )
                                                            ) : (
                                                                destination.content.images
                                                                    .slice(0, 3)
                                                                    .map(
                                                                        (
                                                                            image
                                                                        ) => (
                                                                            <img
                                                                                // src={destination.images}
                                                                                src={`https://wetu.com/ImageHandler/c250x250/${image.url_fragment}`}
                                                                                style={{
                                                                                    float: "left",
                                                                                    width: "32%",
                                                                                    height: "20vh",
                                                                                    objectFit:
                                                                                        "cover",
                                                                                }}
                                                                            />
                                                                        )
                                                                    )
                                                            )
                                                        ) : (
                                                            <>
                                                                {/* <img
                                            // src={destination.images}
                                            src="https://dummyimage.com/1200x800/000/fff.png&text=Check"
                                            style={{ maxWidth: "260px" }}
                                            />
                                            <img
                                            // src={destination.images}
                                            src="https://dummyimage.com/1200x800/000/fff.png&text=Destination"
                                            style={{ maxWidth: "260px" }}
                                            />
                                            <img
                                            // src={destination.images}
                                            src="https://dummyimage.com/1200x800/000/fff.png&text=Images"
                                            style={{ maxWidth: "260px" }}
                                        /> */}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.destinationDescription
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                            __html: destination
                                                                .content
                                                                .general_description,
                                                        }}
                                                    ></div>
                                                </div>
                                            ))}
                                    </>
                                </Paper>
                            );
                        })}
                    </>
                )}
            </>
        );
    };

    //Render
    return (
        <div className={classes.container + " local-information"}>
            {buildDestinationDetails()}
        </div>
    );
}

LocalInformationContent.propTypes = {
    itineraryDestinationContent: PropTypes.object.isRequired,
    itineraryCountryContent: PropTypes.object.isRequired,
};

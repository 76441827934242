import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.body1.fontFamily,
    },
    title: {
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: 300,
        textTransform: "uppercase",
        marginTop: 0,
    },
    privatizeCta: {
        background: "#194666",
        color: "#fff",
        maxWidth: "190px",
        width: "100%",
        lineHeight: 1.2,
        padding: "10px",
        "&:hover": {
            background: "#2b5d80",
        },
    },
}));

const ContactPersonalTravelAdvisor = () => {
    //Pull In Styles
    const classes = useStyles();
    return (
        <Container
            maxWidth={false}
            style={{
                background: "rgb(112, 177, 195)",
                padding: "50px 0 75px 0",
            }}
        >
            <Container maxWidth="md">
                <h2 className={classes.title}>
                    QUESTIONS? GET IN TOUCH WITH YOUR PERSONAL TRAVEL ADVISOR.
                </h2>
                <p
                    className={classes.text}
                    style={{
                        maxWidth: "600px",
                        margin: "auto",
                        textAlign: "center",
                        marginBottom: "25px",
                    }}
                >
                    We're here to chat with you about any pre-and-post travel
                    bookings, insurance questions or anything else you might
                    need related to your travels.
                </p>
                <Button
                    href={`${process.env.REACT_APP_APP_ENDPOINT}/contact-us`}
                    className={classes.privatizeCta}
                >
                    Contact Us
                </Button>
            </Container>
        </Container>
    );
};

export default ContactPersonalTravelAdvisor;

// React & Material UI Imports
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

//React Slick Carousel Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
        padding: 0,
    },
    container: {
        maxWidth: "1000px",
        margin: "auto",
        textAlign: "left",
        padding: "30px 20px 20px 20px",
    },
    title: {
        textAlign: "left",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "18px",
        fontWeight: 300,
        margin: 0,
        textTransform: "uppercase",
    },
    title2: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 300,
    },
    text: {
        textAlign: "left",
        fontFamily: theme.typography.body1.fontFamily,
        marginBottom: 0,
        fontSize: "14px",
    },
    extendYourTripSlides: {
        margin: "25px 0",
    },
}));

//Run Component
export default function MoreFromBAndRContent(props) {
    const { itinerary, extendYourTripData, tripContent } = props;

    let tripDesigner = null;

    if (tripContent.trip) {
        tripDesigner = tripContent.trip[0].trip_designer[0];
    }

    //Pull In Styles
    const classes = useStyles();

    const renderExtendYourTripSlides = () =>
        extendYourTripData.map((data) => (
            <div>
                <div className={classes.extendYourTrip}>
                    <img
                        src={data.image}
                        style={{ maxWidth: "500px", margin: "0 auto" }}
                    />
                </div>
                <div
                    className={classes.extendYourTripImage}
                    style={{
                        backgroundColor: "#194666",
                        maxWidth: "500px",
                        margin: "0 auto",
                    }}
                >
                    <h3
                        className={classes.title2}
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            margin: 0,
                            padding: "15px 0",
                        }}
                    >
                        {data.title}
                    </h3>
                </div>
            </div>
        ));

    const buildMeetYourExperienceDesigner = () => {
        return (
            <div style={{ backgroundColor: "#70B2C4", padding: "40px 0" }}>
                <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
                    <h3
                        className={classes.title}
                        style={{
                            color: "#fff",
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        Meet Your {itinerary.name} Experience Designer
                    </h3>
                    {tripDesigner ? (
                        <Grid
                            container
                            spacing={0}
                            style={{
                                maxWidth: "595px",
                                margin: "0 auto",
                                alignItems: "center",
                            }}
                        >
                            <Grid item md={6}>
                                <img
                                    src={tripDesigner.photourl}
                                    style={{ maxWidth: "200px" }}
                                />
                            </Grid>
                            <Grid item md={6} className={classes.text}>
                                <p
                                    className={classes.title}
                                    style={{ color: "#494949" }}
                                >
                                    {tripDesigner.fullname}
                                </p>
                                <p>{tripDesigner.br_description}</p>
                                <Link
                                    to="/"
                                    style={{
                                        display: "inline-block",
                                        padding: "0px",
                                        color: "#494949",
                                        borderBottom: "1px solid #494949",
                                    }}
                                >
                                    LEARN MORE ABOUT{" "}
                                    {tripDesigner.firstname.toUpperCase()}
                                </Link>
                            </Grid>
                        </Grid>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    };

    const buildExtendYourTripWithBAndR = () => {
        return (
            <div
                style={{
                    padding: "40px 0",
                    maxWidth: "600px",
                    margin: "0 auto",
                }}
            >
                <p className={classes.title} style={{ textAlign: "center" }}>
                    EXTEND YOUR TRIP BEFORE OR AFTER YOUR TIME WITH B&R
                </p>
                <p className={classes.text} style={{ textAlign: "center" }}>
                    We have endless ideas on how to bookend your B&R experience.
                    Inquire with your Travel Advisor, and we can make the
                    arrangements
                </p>

                <div className={classes.extendYourTripSlides}>
                    <Slider
                        dots={true}
                        autoplay={true}
                        autoplaySpeed={5000}
                        arrows={false}
                    >
                        {renderExtendYourTripSlides()}
                    </Slider>
                </div>
            </div>
        );
    };

    const buildQuestionsCta = () => {
        return (
            <div style={{ padding: "40px 0" }}>
                <h3 className={classes.title} style={{ textAlign: "center" }}>
                    QUESTIONS? GET IN TOUCH WITH YOUR PERSONAL TRAVEL ADVISOR
                </h3>
                <Button
                    variant="contained"
                    disableElevation
                    className={classes.button}
                    href={"/"}
                    style={{
                        backgroundColor: "#194666",
                        color: "#fff",
                        margin: "15px 0",
                    }}
                >
                    Contact Us
                </Button>
            </div>
        );
    };

    const buildBAndRBrand = () => {
        return (
            <div style={{ backgroundColor: "#3E739B" }}>
                <div style={{ padding: "30px" }}>
                    <h3
                        className={classes.title2}
                        style={{
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "15px",
                            maxWidth: "535px",
                            margin: "0 auto",
                            lineHeight: 2,
                        }}
                    >
                        Butterfield &amp; Robinson is a community of travel
                        experts who design and execute global experiences for
                        discerning travellers.
                    </h3>
                </div>
            </div>
        );
    };

    //Render
    return (
        <div className={classes.root + " more-from-bandr"}>
            {buildMeetYourExperienceDesigner()}
            {buildExtendYourTripWithBAndR()}
            {buildQuestionsCta()}
            {buildBAndRBrand()}
        </div>
    );
}

MoreFromBAndRContent.propTypes = {
    itinerary: PropTypes.object.isRequired, 
    extendYourTripData: PropTypes.array.isRequired, 
    tripContent: PropTypes.object.isRequired
}

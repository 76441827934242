//React and Material UI Imports
import React, { useReducer, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

//Import Reducers
import { privatizeDepartureFormReducer } from "../formReducers/PrivatizeDepartureFormReducer";

//Import CSS Styles
import "../../../theme/css/form.css";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		background: "#fff",
	},
	container: {
		maxWidth: "960px",
		margin: "auto",
		textAlign: "left",
		padding: "40px",
	},
	title: {
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		textAlign: "center",
		textTransform: "uppercase",
	},
	travellerInformation: {
		display: "flex",
		fontSize: "16px",
		alignItems: "flex-end",
		fontFamily: theme.typography.body1.fontFamily,
	},
	requestItineraryFields: {
		"& input": {
			border: 0,
		},
	},
}));

export default function RequestItineraryForm(props) {
	const {
		formHeading,
		formSubmissionObject,
		implicitUpdateFormSubmissionObject,
		explicitUpdateFormSubmissionObject,
		handleSubmit,
		submitted,
		tripType,
		tripName,
		departureDate,
		selectedDepartureId,
		itineraryId,
		finalDepartureList,
	} = props;

	const buildDepartureDatePickList = (departureList) => {
		let departureDatePickList = [];

		departureList.map((departureYear) => {
			departureYear.dates.map((departureDate) => {
				departureDatePickList.push({
					departure: `${departureDate.startDate} - ${departureDate.endDate}, ${departureYear.year}`,
					departureId: departureDate.departureId,
					departureItineraryId: departureDate.departureItineraryId,
				});
			});
		});

		departureDatePickList.push({
			departure: `Just Browsing`,
			departureId: null,
			departureItineraryId:
				departureList[0].dates[0].departureItineraryId,
		});

		return departureDatePickList;
	};

	let departurePickLIst = buildDepartureDatePickList(finalDepartureList);

	const initialState = {
		dailyBudget: 0,
		travelDate: "",
		travelHistory: "false",
		newsletterSubscription: "true",
		travelAgent: "false",
		contactMethod: "",
		activeItinerary: null,
	};

	const [privatizeDepartureFormInfo, dispatchPrivatizeDepartureFormInfo] =
		useReducer(privatizeDepartureFormReducer, initialState);

	//Pull in styles
	const classes = useStyles();

	//Fetch Page Data
	useEffect(() => {
		explicitUpdateFormSubmissionObject({
			formEnvironment: 20376,
			inquirySubject: `Itinerary Privatize Departure Request - ${tripName}`,
			selectedDepartureId: selectedDepartureId,
			Trip_of_Interest: tripName,
			flexFieldDepartureDate: departureDate,
		});
	}, []);

	const setActiveItinerary = (departureDateString) => {
		console.log(departureDateString);
		let activeDeparture = departurePickLIst.filter(
			(departure) => departure.departure == departureDateString
		)[0];

		dispatchPrivatizeDepartureFormInfo({
			type: "SET_ACTIVE_ITINERARY",
			payload: activeDeparture.departureItineraryId,
		});

		explicitUpdateFormSubmissionObject({
			flexFieldDepartureDate: activeDeparture.departure,
			requestedItineraryId: activeDeparture.departureItineraryId,
		});
	};
	//Render
	return submitted ? (
		//Render form confirmation message
		<div className={"request-itinerary-form"}>
			<div
				style={{
					backgroundColor: "#fff",
					borderRadius: "10px",
					padding: "30px 30px 50px 30px",
				}}
			>
				<div
					style={{
						maxWidth: "735px",
						margin: "0 auto",
						textAlign: "center",
					}}
				>
					<h3
						style={{
							fontFamily: "Lora, serif",
							fontWeight: 300,
							fontSize: "22px",
							color: "#494949",
							textTransform: "uppercase",
							textAlign: "center",
						}}
					>
						Thank you for your request!
					</h3>
					<p style={{ color: "#494949", textAlign: "center" }}>
						One of our team members will get back to you shortly.
					</p>
					{itineraryId && tripType == "Small Group" ? (
						<Button
							variant="outlined"
							href={
								process.env.REACT_APP_ITINERARIES_ENDPOINT +
								"/itinerary/" +
								itineraryId
							}
						>
							View Itinerary
						</Button>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	) : (
		//Render form
		<div
			className={
				classes.requestItineraryFields + " request-itinerary-form"
			}
		>
			<div
				style={{
					backgroundColor: "#fff",
					borderRadius: "10px",
					padding: "30px 30px 50px 30px",
				}}
			>
				<div style={{ maxWidth: "735px", margin: "0 auto" }}>
					{/* <img src="https://images.unsplash.com/photo-1523482580672-f109ba8cb9be?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1961&q=80" /> */}

					<h3 className={classes.title}>Privatize a Departure</h3>
					<h1
						className={classes.title}
						style={{ marginBottom: "0.5rem" }}
					>
						{tripName}
					</h1>
					<h4 className={classes.title} style={{ margin: "0px" }}>
						{formSubmissionObject["flexFieldDepartureDate"]}
					</h4>
					<p style={{ textAlign: "center", marginBottom: "40px" }}>
						Fill out the form below to receive a detailed itinerary
						for this trip. Please note: While we try to share the
						most accurate itinerary with you, these are sometimes
						subject to change and should not be treated as final.
					</p>
					<div
						style={{
							textAlign: "center",
						}}
					>
						<div
							className={classes.travellerInformation}
							style={{
								maxWidth: "350px",
								margin: "auto",
								padding: "5px 0px",
							}}
						>
							<div style={{ width: "40%" }}>
								<p style={{ width: "100%", margin: 0 }}>
									First Name
								</p>
							</div>
							<div style={{ width: "60%" }}>
								<TextField
									id="first-name"
									name="FirstName"
									style={{ width: "100%" }}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</div>
						<div
							className={classes.travellerInformation}
							style={{
								maxWidth: "350px",
								margin: "auto",
								padding: "5px 0px",
							}}
						>
							<div style={{ width: "40%" }}>
								<p style={{ width: "100%", margin: 0 }}>
									Last Name
								</p>
							</div>
							<div style={{ width: "60%" }}>
								<TextField
									id="last-name"
									name="LastName"
									style={{ width: "100%" }}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</div>
						<div
							className={classes.travellerInformation}
							style={{
								maxWidth: "350px",
								margin: "auto",
								padding: "5px 0px",
							}}
						>
							<div style={{ width: "40%" }}>
								<p style={{ width: "100%", margin: 0 }}>
									Email
								</p>
							</div>
							<div style={{ width: "60%" }}>
								<TextField
									style={{ width: "100%" }}
									id="Email"
									name="Email"
									value={formSubmissionObject["Email"]}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</div>
						<div
							className={classes.travellerInformation}
							style={{
								maxWidth: "350px",
								margin: "auto",
								padding: "5px 0px",
							}}
						>
							<div style={{ width: "40%" }}>
								<p style={{ width: "100%", margin: 0 }}>
									Phone number
								</p>
							</div>
							<div style={{ width: "60%" }}>
								<TextField
									id="phone-number"
									name="Phone"
									style={{ width: "100%" }}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</div>
						<div
							className={classes.travellerInformation}
							style={{
								maxWidth: "350px",
								margin: "auto",
								padding: "5px 0px",
							}}
						>
							<div style={{ width: "40%" }}>
								<p style={{ width: "100%", margin: 0 }}>
									Zip/Postal Code
								</p>
							</div>
							<div style={{ width: "60%" }}>
								<TextField
									id="postal-code"
									name="PostalCode"
									style={{ width: "100%" }}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</div>

						{/* <div className={"additional-traveller-info"}>
                            <FormLabel
                                component="legend"
                                style={{
                                    color: "#494949",
                                    padding: "45px 0px 10px 0px",
                                    margin: "auto",
                                }}
                            >
                                When do you plan to travel?
                            </FormLabel>
                            <FormControl className={classes.formControl}>
                                <Select
                                    labelId="request-itinerary-travel-date"
                                    id="request-itinerary-travel-date"
                                    name="flexFieldDepartureDate"
                                    value={
                                        formSubmissionObject[
                                            "flexFieldDepartureDate"
                                        ]
                                    }
                                    onChange={(e) => {
                                        setActiveItinerary(e.target.value);
                                    }}
                                    label="Travel Date"
                                    style={{
                                        color: "#494949",
                                        width: "250px",
                                        background: "#fff",
                                    }}
                                    className={classes.travellerInformation}
                                >
                                    {departurePickLIst.map((menuItem) => (
                                        <MenuItem value={menuItem.departure}>
                                            {menuItem.departure}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> */}

						<div
							className={"additional-traveller-info"}
							style={{ padding: "45px 0px" }}
						>
							<FormControl component="fieldset">
								<FormLabel
									component="legend"
									style={{ color: "#494949" }}
								>
									Have you travelled with us before?
								</FormLabel>
								<RadioGroup
									row
									aria-label="travel-history"
									name="previousTraveler"
									value={
										privatizeDepartureFormInfo.travelHistory
									}
									onChange={(event) => {
										dispatchPrivatizeDepartureFormInfo({
											type: "SET_TRAVEL_HISTORY_INFO",
											payload: event.target.value,
										});
										implicitUpdateFormSubmissionObject(
											event
										);
									}}
									style={{
										color: "#494949",
										justifyContent: "center",
									}}
								>
									<FormControlLabel
										value="true"
										control={<Radio />}
										label="Yes"
										style={{ color: "#494949" }}
									/>
									<FormControlLabel
										value="false"
										control={<Radio />}
										label="No"
										style={{ color: "#494949" }}
									/>
								</RadioGroup>

								<FormLabel
									component="legend"
									style={{
										color: "#494949",
										marginTop: "15px",
									}}
								>
									Are you a travel agent?
								</FormLabel>
								<RadioGroup
									row
									aria-label="travel-agent"
									name="travelAgent"
									value={
										privatizeDepartureFormInfo.travelAgent
									}
									onChange={(event) => {
										dispatchPrivatizeDepartureFormInfo({
											type: "SET_TRAVEL_AGENT_INFO",
											payload: event.target.value,
										});
										implicitUpdateFormSubmissionObject(
											event
										);
									}}
									style={{
										color: "#494949",
										justifyContent: "center",
									}}
								>
									<FormControlLabel
										value="true"
										control={<Radio />}
										label="Yes"
										style={{ color: "#494949" }}
									/>
									<FormControlLabel
										value="false"
										control={<Radio />}
										label="No"
										style={{ color: "#494949" }}
									/>
								</RadioGroup>
							</FormControl>
							<FormLabel
								component="legend"
								style={{
									color: "#494949",
									marginTop: "15px",
									lineHeight: "1.6",
									maxWidth: "425px",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								Sign up for our emails to receive B&R news,
								brand new trip launches, and content from our
								award-winning blog.
							</FormLabel>
							<RadioGroup
								row
								aria-label="newsletter-subscription"
								name="emailOptIn"
								value={
									privatizeDepartureFormInfo.newsletterSubscription
								}
								onChange={(event) => {
									dispatchPrivatizeDepartureFormInfo({
										type: "SET_NEWSLETTER_SUBSCRIPTION_INFO",
										payload: event.target.value,
									});
									implicitUpdateFormSubmissionObject(event);
								}}
								style={{
									color: "#494949",
									justifyContent: "center",
								}}
							>
								<FormControlLabel
									value="true"
									control={<Radio />}
									label="Yes"
									style={{ color: "#494949" }}
								/>
								<FormControlLabel
									value="false"
									control={<Radio />}
									label="No"
									style={{ color: "#494949" }}
								/>
							</RadioGroup>
						</div>

						<Button
							style={{
								backgroundColor: "#494949",
								color: "#fff",
								padding: "6px 20px",
							}}
							onClick={handleSubmit}
						>
							Submit
						</Button>
						<div>
							<p
								style={{
									textAlign: "center",
									fontSize: "11.5px",
									marginBottom: "20px",
								}}
							>
								By submitting this form, you agree to the
								Butterfield & Robinson{" "}
								<Link
									href="/terms-and-conditions"
									style={{ color: "#70B2C4" }}
								>
									Terms
								</Link>{" "}
								and{" "}
								<Link
									href="/privacy-policy"
									style={{ color: "#70B2C4" }}
								>
									Privacy Policy
								</Link>
								.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

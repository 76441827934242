import React from "react";
import PropTypes from "prop-types";

//Import Material UI Components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { where } from "../../utilities/HelperFunctions/helperFunctions";

//Import Images
import Globe from "../../images/globe.png";

import Carousel from "react-material-ui-carousel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "800px",
        margin: "auto",
        textAlign: "left",
        padding: "60px 20px 20px 20px",
    },
    hero: {
        position: "relative",
        textAlign: "center",
        maxHeight: "50vh",
        overflow: "hidden",
        width: "100%",
    },
    title: {
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: 300,
        textTransform: "uppercase",
        marginTop: 0,
    },
    dayTitle2: {
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        fontSize: "2.5rem",
        textTransform: "uppercase",
        margin: 0,
    },
    dayTitle3: {
        marginBottom: 0,
        color: "#3E739B",
        textTransform: "uppercase",
        fontSize: "16px",
        fontFamily: "Muli, sans-serif",
        fontWeight: "800",
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
    },
    dayDescriptionText: {
        fontSize: "16px",
        fontFamily: "Muli, sans-serif",
        "& p": {
            textAlign: "justify",
        },
        "& p:first-child": {
            marginTop: 0,
        },
    },
    dayInclusionExclusionText: {
        maxWidth: "90%",
        "& p": {
            marginTop: "0px",
        },
        "& ul": {
            marginTop: "0px",
            fontSize: "16px",
            fontFamily: "Muli, sans-serif",
        },
    },
    dayBodyText: {
        color: "rgba(0, 0, 0, 0.87)",
        textTransform: "none",
        fontSize: "16px",
        fontFamily: "Muli, sans-serif",
        fontWeight: "400",
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        marginTop: 0,
        textAlign: "justify",
    },
    text: {
        fontFamily: theme.typography.body1.fontFamily,
        "& p": {
            textAlign: "justify",
        },
    },
    introduction: {
        maxWidth: "980px",
        margin: "0 auto",
        overflow: "hidden",
    },
    itineraryList: {
        // padding: 0,
        margin: 0,
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: "16px",
        maxWidth: "95%",
        "& li": {
            marginBottom: "1rem",
        },
    },
    rendezvousDropOffContainer: {
        maxWidth: "1000px",
        margin: "0 auto",
    },
    rendezvousDropOff: {
        display: "flex",
        textTransform: "uppercase",
        fontSize: "12px",
        position: "relative",
    },
    hotel1: {
        backgroundColor: "#194666",
        padding: "35px",
        height: "460px",
    },
    hotel2: {
        backgroundColor: "#70B2C4",
        padding: "35px",
        textTransform: "uppercase",
    },
    hotelImageContainer: {
        width: "100%",
        height: "100%",
    },
    hotelImage: {
        objectFit: "cover",
        height: "100%",
        width: "100%",
    },
    text4: {
        fontFamily: theme.typography.body1.fontFamily,
        textAlign: "left",
        fontSize: "14px",
        color: "#fff",
        margin: 0,
        padding: 0,
        listStyle: "none",
    },
    title2: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "20px",
        color: "#fff",
        marginTop: 0,
        fontWeight: 300,

        paddingBottom: "10px",
    },
    dailyBreakdownGallery: {
        height: "500px",
    },
    itineraryPeriodCard: {
        padding: "0 5%",
    },
    readMore: {
        "& p:nth-child(2)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "24px" /* fallback */,
            maxHeight: "72px" /* fallback */,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
        },
        "& p:nth-child(n+3)": {
            display: "none",
        },
    },
    contactExpansion: {
        margin: "0 !important",
        "&::before": {
            height: "1px",
            opacity: "0 !important",
        },
    },
    accordianSummary: {
        padding: "0 0 0 0",
        minHeight: "48px !important",
        margin: 0,
        justifyContent: "left",
        "& div": {
            margin: "0 !important",
        },
        "& div.MuiAccordionSummary-content": {
            flexGrow: 0,
        },
    },
}));

const DayCard = (props) => {
    //Access Props
    const {
        urlToHyperlink,
        leg,
        period,
        finalLeg,
        carouselImages,
        tripType,
        isBooked,
        isValidDate,
        dateDisplayOptions,
        addDays,
        search,
        tripStart,
        itineraryRelatedContent,
        itineraryHotelContent,
        itineraryAltHotelContent,
        routes,
        isPrint,
        selfGuided,
    } = props;

    //Pull In Styles
    const classes = useStyles();

    let contactAccordianPrintProp = isPrint
        ? { expanded: true }
        : { expanded: true };

    const prettyRenderMode = (transportMode) => {
        switch (transportMode) {
            case "ScheduledFlight":
                return "Fly";
            case "CharteredFlight":
                return "Fly";
            case "Selfdrive":
                return "Drive";
            case "Transfer":
                return "Drive";
            case "ScheduledFlightNoRoute":
                return "Fly";
            case "CharteredFlightNoRoute":
                return "Fly";
            case "SelfdriveNoRoute":
                return "Drive";
            case "TransferNoRoute":
                return "Drive";
            case "TrainNoRoute":
                return "Train";
            case "BoatNoRoute":
                return "Boat";
            default:
                return transportMode;
        }
    };

    const buildDayRoutes = (period, routes) => {
        if (!routes) return [];
        const filterDayRoutes = (dayRoutes) => {
            return dayRoutes
                .filter((route) => route.mode !== "NoTransit")
                .filter((route) => route.mode !== "Hike")
                .filter((route) => route.mode !== "HikeNoRoute");
        };

        let dayRoutes = where(routes, {
            start_day: period.itinerary_start_day,
        });

        if (!period.routes) return filterDayRoutes(dayRoutes);

        period.routes.forEach((route) => {
            dayRoutes.push(route);
        });

        return filterDayRoutes(dayRoutes);
    };

    let dayRoutes = buildDayRoutes(period, routes);

    const finalDayCard = () => (
        <>
            <Paper
                style={{
                    margin: isPrint ? 0 : "20px 0 50px 0",
                    padding: isPrint ? 0 : "30px 7.5%",
                    textAlign: "left",
                }}
                elevation={isPrint ? 0 : 3}
            >
                <>
                    <div style={{ paddingBottom: "20px" }}>
                        <h2
                            className={classes.dayTitle2}
                            style={{
                                color:
                                    tripType === "Small Group"
                                        ? "#96AF8E"
                                        : selfGuided
                                        ? "#8eafaf"
                                        : "#DBAA79",
                            }}
                        >
                            Final Day
                        </h2>

                        {isBooked && isValidDate(tripStart) ? (
                            <h3
                                style={{
                                    fontWeight: 300,
                                    marginTop: 0,
                                    textAlign: "center",
                                }}
                            >
                                {new Intl.DateTimeFormat("en-US", {
                                    weekday: "long",
                                    // year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    timeZone: "UTC",
                                }).format(
                                    addDays(
                                        tripStart,
                                        period.itinerary_start_day
                                    )
                                )}
                                {period.itinerary_end_day -
                                    period.itinerary_start_day >
                                0 ? (
                                    <>
                                        {" "}
                                        -{" "}
                                        {new Intl.DateTimeFormat(
                                            "en-US",
                                            dateDisplayOptions
                                        ).format(
                                            addDays(
                                                tripStart,
                                                period.itinerary_end_day
                                            )
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </h3>
                        ) : (
                            ""
                        )}
                    </div>
                </>
                {carouselImages.length > 0 ? (
                    <>
                        {isPrint ? (
                            <div style={{ display: "flex" }}>
                                {carouselImages}
                            </div>
                        ) : (
                            <div>
                                <Carousel
                                    autoPlay={false}
                                    navButtonsAlwaysVisible={true}
                                >
                                    {carouselImages}
                                </Carousel>
                            </div>
                        )}
                    </>
                ) : (
                    <></>
                )}
                <div
                    className={classes.dayDescriptionText}
                    dangerouslySetInnerHTML={{
                        __html: urlToHyperlink(period.notes),
                    }}
                ></div>
                <Grid container>
                    <Grid item xs={isPrint ? 6 : 12} sm={6}>
                        {search("Included", "type", period.activities) ? (
                            <>
                                <h3 className={classes.dayTitle3}>
                                    Planned Activities
                                </h3>
                                <ul className={classes.itineraryList}>
                                    {period.activities ? (
                                        <>
                                            {period.activities.map((activity) =>
                                                activity.type == "Included" ? (
                                                    <li>{activity.name}</li>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item xs={isPrint ? 6 : 12} sm={6}>
                        {search("Optional", "type", period.activities) ? (
                            <>
                                <h3 className={classes.dayTitle3}>Optional</h3>
                                <ul className={classes.itineraryList}>
                                    {period.activities ? (
                                        <>
                                            {period.activities.map((activity) =>
                                                activity.type == "Optional" ? (
                                                    <li>{activity.name}</li>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {period.consultant_notes.length > 0 ? (
                            <>
                                <h3
                                    style={{
                                        color: "#3E739B",
                                        textTransform: "uppercase",
                                        fontSize: "16px",
                                        fontFamily: "Muli, sans-serif",
                                        fontWeight: "800",
                                        lineHeight: 1.5,
                                        letterSpacing: "0.00938em",
                                    }}
                                >
                                    Expert Tips{" "}
                                </h3>
                                <div
                                    className={classes.dayDescriptionText}
                                    dangerouslySetInnerHTML={{
                                        __html: urlToHyperlink(
                                            period.consultant_notes
                                        ),
                                    }}
                                ></div>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {dayRoutes.length > 0 ? (
                            <>
                                <h3 className={classes.dayTitle3}>Transport</h3>
                                <ul className={classes.itineraryList}>
                                    {dayRoutes.map((route) => {
                                        return (
                                            <li>
                                                {`${prettyRenderMode(
                                                    route.mode
                                                )} from ${
                                                    where(
                                                        itineraryRelatedContent,
                                                        {
                                                            map_object_id:
                                                                route.start_content_entity_id,
                                                        }
                                                    )[0]?.name
                                                } to ${
                                                    where(
                                                        itineraryRelatedContent,
                                                        {
                                                            map_object_id:
                                                                route.end_content_entity_id,
                                                        }
                                                    )[0]?.name
                                                }`}
                                                {route.reference_codes ? (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <span
                                                            style={{
                                                                fontStyle:
                                                                    "italic",
                                                            }}
                                                        >
                                                            {
                                                                route.reference_codes
                                                            }
                                                        </span>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={isPrint ? 6 : 12} sm={6}>
                        {period.included.length > 0 ? (
                            <>
                                <h3 className={classes.dayTitle3}>
                                    Inclusions
                                </h3>
                                <div
                                    className={
                                        classes.dayInclusionExclusionText
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: urlToHyperlink(period.included),
                                    }}
                                ></div>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item xs={isPrint ? 6 : 12} sm={6}>
                        {period.excluded.length > 0 ? (
                            <>
                                <h3 className={classes.dayTitle3}>
                                    Exclusions
                                </h3>
                                <div
                                    className={
                                        classes.dayInclusionExclusionText
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: urlToHyperlink(period.excluded),
                                    }}
                                ></div>
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
                {period.greetings ? (
                    <Accordion
                        {...contactAccordianPrintProp}
                        elevation={0}
                        className={classes.contactExpansion}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={classes.accordianSummary}
                        >
                            <Typography className={classes.dayTitle3}>
                                Contacts
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <ul style={{ margin: 0 }}>
                                {period.greetings.map((greeting) => (
                                    <li>
                                        <p
                                            style={{
                                                margin: 0,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {greeting.start_time
                                                ? greeting.start_time + " | "
                                                : ""}
                                            {greeting.name ? greeting.name : ""}
                                            {greeting.contact_name
                                                ? greeting.name
                                                    ? " | "
                                                    : ""
                                                : ""}
                                            {greeting.contact_name
                                                ? greeting.contact_name
                                                : ""}
                                            {greeting.company
                                                ? ", " + greeting.company
                                                : ""}
                                            {greeting.contact_number != "" ? (
                                                <>
                                                    {" "}
                                                    |{" "}
                                                    <a
                                                        href={`tel:${greeting.contact_number}`}
                                                    >
                                                        {
                                                            greeting.contact_number
                                                        }
                                                    </a>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {greeting.contact_alternative_number !=
                                            "" ? (
                                                <>
                                                    {" "}
                                                    |{" "}
                                                    <a
                                                        href={`tel:${greeting.contact_alternative_number}`}
                                                    >
                                                        {
                                                            greeting.contact_alternative_number
                                                        }
                                                    </a>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {greeting.contact_email != "" ? (
                                                <>
                                                    {" "}
                                                    |{" "}
                                                    <a
                                                        href={`mailto:${greeting.contact_email}`}
                                                    >
                                                        {greeting.contact_email}
                                                    </a>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                        {greeting.description ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: urlToHyperlink(
                                                        greeting.description
                                                    ),
                                                }}
                                            ></div>
                                        ) : (
                                            <></>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <></>
                )}
                <div
                    style={{
                        width: "100%",
                        textAlign: "center",
                        paddingTop: "2rem",
                    }}
                >
                    <img style={{ width: "90px" }} src={Globe} />
                </div>
            </Paper>
        </>
    );

    //New Layout Mockup
    return (
        <div className={"itineraryPeriodCard"}>
            {leg.sequence == finalLeg ? (
                finalDayCard()
            ) : (
                <Paper
                    style={{
                        margin: isPrint ? 0 : "20px 0 50px 0",
                        padding: isPrint ? 0 : "30px 7.5%",
                        textAlign: "left",
                    }}
                    elevation={isPrint ? 0 : 3}
                >
                    <div style={{ paddingBottom: "20px" }}>
                        <h2
                            className={classes.dayTitle2}
                            style={{
                                color:
                                    tripType === "Small Group"
                                        ? "#96AF8E"
                                        : selfGuided
                                        ? "#8eafaf"
                                        : "#DBAA79",
                            }}
                        >
                            DAY {period.itinerary_start_day + 1}
                            {period.itinerary_end_day -
                                period.itinerary_start_day >
                            0 ? (
                                <> - {period.itinerary_end_day + 1}</>
                            ) : (
                                ""
                            )}
                        </h2>

                        <>
                            {isBooked && isValidDate(tripStart) ? (
                                <h3
                                    style={{
                                        fontWeight: 300,
                                        marginTop: 0,
                                        textAlign: "center",
                                    }}
                                >
                                    {new Intl.DateTimeFormat(
                                        "en-US",
                                        period.itinerary_start_day == 0
                                            ? {
                                                  weekday: "long",
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                  timeZone: "UTC",
                                              }
                                            : {
                                                  weekday: "long",
                                                  // year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                  timeZone: "UTC",
                                              }
                                    ).format(
                                        addDays(
                                            tripStart,
                                            period.itinerary_start_day
                                        )
                                    )}
                                    {period.itinerary_end_day -
                                        period.itinerary_start_day >
                                    0 ? (
                                        <>
                                            {" "}
                                            -{" "}
                                            {new Intl.DateTimeFormat("en-US", {
                                                weekday: "long",
                                                // year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                timeZone: "UTC",
                                            }).format(
                                                addDays(
                                                    tripStart,
                                                    period.itinerary_end_day
                                                )
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </h3>
                            ) : (
                                ""
                            )}
                        </>
                    </div>
                    {isPrint ? (
                        <div style={{ display: "flex" }}>{carouselImages}</div>
                    ) : (
                        <div>
                            <Carousel
                                autoPlay={false}
                                navButtonsAlwaysVisible={true}
                            >
                                {carouselImages}
                            </Carousel>
                        </div>
                    )}
                    {/* <ReadMore html={period.notes} /> */}
                    <div
                        className={classes.dayDescriptionText}
                        dangerouslySetInnerHTML={{
                            __html: urlToHyperlink(period.notes),
                        }}
                    ></div>
                    {/* <div style={{ pageBreakAfter: "always" }}></div> */}
                    <Grid container>
                        <Grid item xs={12}>
                            {period.consultant_notes.length > 0 ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Expert Tips{" "}
                                    </h3>
                                    <div
                                        className={classes.dayDescriptionText}
                                        dangerouslySetInnerHTML={{
                                            __html: urlToHyperlink(
                                                period.consultant_notes
                                            ),
                                        }}
                                    ></div>
                                </>
                            ) : isPrint ? (
                                <div style={{ height: "15px" }}></div>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {dayRoutes.length > 0 ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Transport
                                    </h3>
                                    <ul className={classes.itineraryList}>
                                        {dayRoutes.map((route) => {
                                            return (
                                                <li>
                                                    {`${prettyRenderMode(
                                                        route.mode
                                                    )} from ${
                                                        where(
                                                            itineraryRelatedContent,
                                                            {
                                                                map_object_id:
                                                                    route.start_content_entity_id,
                                                            }
                                                        )[0]?.name
                                                    } to ${
                                                        where(
                                                            itineraryRelatedContent,
                                                            {
                                                                map_object_id:
                                                                    route.end_content_entity_id,
                                                            }
                                                        )[0]?.name
                                                    }`}
                                                    {route.reference_codes ? (
                                                        <>
                                                            {" "}
                                                            |{" "}
                                                            <span
                                                                style={{
                                                                    fontStyle:
                                                                        "italic",
                                                                }}
                                                            >
                                                                {
                                                                    route.reference_codes
                                                                }
                                                            </span>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {search(
                                leg.content_entity_id,
                                "map_object_id",
                                itineraryHotelContent
                            ).name == "Missing Point" ? (
                                leg.type == "OwnArrangement" ? (
                                    <>
                                        <h3 className={classes.dayTitle3}>
                                            Accommodation
                                        </h3>
                                        <p className={classes.dayBodyText}>
                                            Own Arrangements
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )
                            ) : (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Accommodation
                                    </h3>
                                    <p className={classes.dayBodyText}>
                                        {
                                            search(
                                                leg.content_entity_id,
                                                "map_object_id",
                                                itineraryHotelContent
                                            ).name
                                        }
                                        {leg.type == "OwnArrangement"
                                            ? " - Own Arrangements"
                                            : ""}
                                    </p>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {leg.alternate_accommodations ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Alternative Accommodation
                                    </h3>
                                    <p
                                        className={classes.dayBodyText}
                                        style={{ margin: 0 }}
                                    >
                                        {
                                            search(
                                                leg.alternate_accommodations[0]
                                                    .content_entity_id,
                                                "map_object_id",
                                                itineraryAltHotelContent
                                            ).name
                                        }
                                    </p>
                                    <p
                                        className={classes.dayBodyText}
                                        style={{ margin: 0 }}
                                    >
                                        {leg.alternate_accommodations[1]
                                            ? search(
                                                  leg
                                                      .alternate_accommodations[1]
                                                      .content_entity_id,
                                                  "map_object_id",
                                                  itineraryAltHotelContent
                                              ).name
                                            : null}
                                    </p>
                                </>
                            ) : null}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {search("Included", "type", period.activities) ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Planned Activities
                                    </h3>
                                    <ul className={classes.itineraryList}>
                                        {period.activities ? (
                                            <>
                                                {period.activities.map(
                                                    (activity) =>
                                                        activity.type ==
                                                        "Included" ? (
                                                            <li>
                                                                {activity.name}
                                                            </li>
                                                        ) : (
                                                            ""
                                                        )
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </ul>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {search("Optional", "type", period.activities) ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Optional Activities
                                    </h3>
                                    <ul className={classes.itineraryList}>
                                        {period.activities ? (
                                            <>
                                                {period.activities.map(
                                                    (activity) =>
                                                        activity.type ==
                                                        "Optional" ? (
                                                            <li>
                                                                {activity.name}
                                                            </li>
                                                        ) : (
                                                            ""
                                                        )
                                                )}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </ul>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {period.included.length > 0 ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Inclusions
                                    </h3>
                                    <div
                                        className={
                                            classes.dayInclusionExclusionText
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: urlToHyperlink(
                                                period.included
                                            ),
                                        }}
                                    ></div>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                        <Grid item xs={isPrint ? 6 : 12} sm={6}>
                            {period.excluded.length > 0 ? (
                                <>
                                    <h3 className={classes.dayTitle3}>
                                        Exclusions
                                    </h3>
                                    <div
                                        className={
                                            classes.dayInclusionExclusionText
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: urlToHyperlink(
                                                period.excluded
                                            ),
                                        }}
                                    ></div>
                                </>
                            ) : (
                                ""
                            )}
                        </Grid>
                    </Grid>
                    {period.greetings ? (
                        <Accordion
                            {...contactAccordianPrintProp}
                            elevation={0}
                            className={classes.contactExpansion}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={classes.accordianSummary}
                            >
                                <Typography className={classes.dayTitle3}>
                                    Contacts
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: 0 }}>
                                <ul style={{ margin: 0 }}>
                                    {period.greetings.map((greeting) => (
                                        <li>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {greeting.start_time
                                                    ? greeting.start_time +
                                                      " | "
                                                    : ""}
                                                {greeting.name
                                                    ? greeting.name
                                                    : ""}
                                                {greeting.contact_name
                                                    ? greeting.name
                                                        ? " | "
                                                        : ""
                                                    : ""}
                                                {greeting.contact_name
                                                    ? greeting.contact_name
                                                    : ""}
                                                {greeting.company
                                                    ? ", " + greeting.company
                                                    : ""}
                                                {greeting.contact_number !=
                                                "" ? (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <a
                                                            href={`tel:${greeting.contact_number}`}
                                                        >
                                                            {
                                                                greeting.contact_number
                                                            }
                                                        </a>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {greeting.contact_alternative_number !=
                                                "" ? (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <a
                                                            href={`tel:${greeting.contact_alternative_number}`}
                                                        >
                                                            {
                                                                greeting.contact_alternative_number
                                                            }
                                                        </a>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {greeting.contact_email !=
                                                "" ? (
                                                    <>
                                                        {" "}
                                                        |{" "}
                                                        <a
                                                            href={`mailto:${greeting.contact_email}`}
                                                        >
                                                            {
                                                                greeting.contact_email
                                                            }
                                                        </a>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </p>
                                            {greeting.description ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: urlToHyperlink(
                                                            greeting.description
                                                        ),
                                                    }}
                                                ></div>
                                            ) : (
                                                <></>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <></>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default DayCard;

DayCard.propTypes = {
    leg: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
    getPeriodsImageArray: PropTypes.func.isRequired,
    renderDailyImageGallery: PropTypes.func.isRequired,
    tripType: PropTypes.string.isRequired,
    isBooked: PropTypes.bool.isRequired,
    dateDisplayOptions: PropTypes.object.isRequired,
    addDays: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    tripStart: PropTypes.any.isRequired,
    itineraryHotelContent: PropTypes.any.isRequired,
    itineraryAltHotelContent: PropTypes.any.isRequired,
};

// Make API Request
function api(url, data = {}) {
    return new Promise((resolve, reject) => {
        if (url == undefined) reject("Missing url");
        data.method == undefined
            ? (data.method = "GET")
            : (data.body = JSON.stringify(data.body || {}));
        if (data.headers == undefined)
            data.headers = { "Content-Type": "application/json" };
        if (data.proxyUrl) url = data.proxyUrl + url;

        fetch(url, data)
            .then((data) => data.json())
            .then((json) => resolve(json))
            .catch((err) => reject({ Error: err }));
    });
}

// Make API Request

const asyncApi = async (url, data = {}) => {
    try {
        data.method == undefined
            ? (data.method = "GET")
            : (data.body = JSON.stringify(data.body || {}));
        if (data.headers == undefined)
            data.headers = { "Content-Type": "application/json" };
        if (data.proxyUrl) url = data.proxyUrl + url;

        let results = await fetch(url, data);
        results = await results.json();
        return results;
    } catch (err) {
        console.log(err);
        if (err instanceof SyntaxError) {
            throw new Error("Missing Url Parameter");
        }
    } finally {
        console.log("finally ran");
    }
};

export { api, asyncApi };

// Promise.all([
//     asyncApi("https://api.butterfield.com/v1/trips"),
//     asyncApi("https://api.butterfield.com/v1/departures")
// ]).then((results)=>{
//     console.log(results)
// })

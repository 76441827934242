import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    Map,
    GoogleApiWrapper,
    InfoWindow,
    Polygon,
    Polyline,
    Marker,
} from "google-maps-react";

const containerStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
};

const mapStyles = [
    { stylers: [{ weight: 1 }] },
    { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9b2a6" }],
    },
    {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [{ color: "#dcd2be" }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ae9e90" }],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [{ color: "#f1e4c5" }],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#93817c" }],
    },
    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
    {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#a5b076" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#447530" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#f5f1e6" }, { weight: 0.5 }],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#fdfcf8" }],
    },
    { featureType: "road.highway", stylers: [{ weight: 0.5 }] },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#f8c967" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#e9bc62" }],
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [{ color: "#e98d58" }],
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [{ color: "#db8555" }],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#806b63" }],
    },
    { featureType: "transit", stylers: [{ visibility: "off" }] },
    {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8f7d77" }],
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ebe3cd" }],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#3e739b" }],
    },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#92998d" }],
    },
];

const createBounds = (routes, pins) => {
    let points = [];
    routes.map((route) => {
        route.map((point) => {
            //Check that all points have a lat/lng pair
            if (point.lat && point.lng) {
                points.push(point);
            }
        });
    });
    pins.map((pin) => {
        points.push({ lat: pin.lat, lng: pin.lng });
    });

    console.log(pins);
    return points;
};

export class GoogleMaps extends Component {
    state = {
        showingInfoWindow: false, // Hides or shows the InfoWindow
        activeMarker: {}, // Shows the active marker upon click
        selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
        bounds: [],
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });

    onClose = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: mapStyles,
        });
        map.fitBounds(mapProps.bounds);
    }

    render() {
        var points = createBounds(this.props.routes, this.props.pins);
        var bounds = new this.props.google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        return (
            <div style={{ width: "100%", height: "600px" }}>
                <Map
                    google={this.props.google}
                    zoom={5}
                    containerStyle={containerStyles}
                    initialCenter={this.props.pins[0]}
                    bounds={bounds}
                    onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                >
                    {this.props.routes.map((route) => (
                        <Polyline
                            path={route}
                            strokeColor="#0000FF"
                            strokeOpacity={0.8}
                            strokeWeight={2}
                        />
                    ))}
                    {this.props.pins.map((pin) => (
                        <Marker
                            onClick={this.onMarkerClick}
                            name={pin.name}
                            position={pin}
                        />
                    ))}

                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div>
                            <h4>{this.state.selectedPlace.name}</h4>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCtkouLiSsz7KGwcOaQjCGWJF4qSgYxC-c",
    //language: "en",
})(GoogleMaps);

import React, { useState, useEffect } from "react";

//Import Active Forms Object
import activeForms from "./config/activeForms";

//Import Forms
import SmallGroupBookNowForm from "./forms/SmallGroupBookNowForm";
import StartPlanningForm from "./forms/StartPlanningForm";
import ContactUsForm from "./forms/ContactUsForm";
import RequestItineraryForm from "./forms/RequestItineraryForm";
import StartCustomizingForm from "./forms/StartCustomizingForm";
import NewsletterSignUpForm from "./forms/NewsletterSignUpForm";
import ShareTripForm from "./forms/ShareTripForm";
import PrivatizeDeparture from "./forms/PrivatizeDepartureForm";

const MarketoForm = (props) => {
    let { form, additionalData } = props;
    let { baseUrl, munchkinId, formId, callback } = activeForms[form];

    const [submitted, setSubmitted] = useState(false);
    const [formSubmissionObject, setFormSubmissionObject] = useState(() => {
        return {};
    });

    console.log(formSubmissionObject);

    const handleSubmit = () => {
        let form = window.MktoForms2.getForm(formId);
        form.addHiddenFields(formSubmissionObject);
        form.submit();
        form.onSuccess(() => {
            //return false to prevent submission handler from taking the lead to the follow up url
            console.log("Form Submitted Sucessfully");
            setSubmitted(true);
            return false;
        });
    };

    //Note: Duplicate keys will result in data overwrite.
    //TODO: Throw error if duplicate keys are present.
    const implicitUpdateFormSubmissionObject = (e) => {
        setFormSubmissionObject({
            ...formSubmissionObject,
            [e.target.name]: e.target.value,
        });
    };

    const explicitUpdateFormSubmissionObject = (explictFieldsObject) => {
        setFormSubmissionObject({
            ...formSubmissionObject,
            ...explictFieldsObject,
        });
    };

    useEffect(() => {
        window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
    }, []);

    function renderForm(form) {
        switch (form) {
            case "smallGroupBookNow":
                return (
                    <SmallGroupBookNowForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        departureDate={additionalData.departureDate}
                        year={additionalData.year}
                        tripName={additionalData.tripName}
                        tripId={additionalData.tripId}
                        selectedDepartureId={additionalData.selectedDepartureId}
                        closeForm={additionalData.closeForm}
                    />
                );
            case "startPlanning":
                return (
                    <StartPlanningForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        formHeading={additionalData.formHeading}
                        closeForm={additionalData.closeForm}
                    />
                );
            case "requestItinerary":
                return (
                    <RequestItineraryForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        formHeading={additionalData.formHeading}
                        closeForm={additionalData.closeForm}
                        tripType={additionalData.tripType}
                        tripName={additionalData.tripName}
                        finalDepartureList={additionalData.finalDepartureList}
                        itineraryId={
                            additionalData.tripInfo
                                ? additionalData.tripInfo.br_tripitineraryid
                                : ""
                        }
                    />
                );
            case "startCustomizing":
                return (
                    <StartCustomizingForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        formHeading={additionalData.formHeading}
                        closeForm={additionalData.closeForm}
                        tripType={additionalData.tripType}
                        tripName={additionalData.tripName}
                        finalDepartureList={additionalData.finalDepartureList}
                        itineraryId={
                            additionalData.tripInfo
                                ? additionalData.tripInfo.br_tripitineraryid
                                : ""
                        }
                    />
                );
            case "shareTrip":
                return (
                    <ShareTripForm
                        itineraryId={additionalData.itinerary.identifier}
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        formHeading={additionalData.formHeading}
                        tripType={additionalData.tripType}
                        tripUrl={additionalData.tripUrl}
                        handleSubmit={handleSubmit}
                        closeForm={additionalData.closeForm}
                    />
                );
            case "newsletterSignUp":
                return (
                    <NewsletterSignUpForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        closeForm={additionalData.closeForm}
                    />
                );
            case "contactUs":
                return (
                    <ContactUsForm
                        submitted={submitted}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                    />
                );
            case "privatizeDeparture":
                return (
                    <PrivatizeDeparture
                        submitted={submitted}
                        tripName={additionalData.tripName}
                        selectedDepartureId={additionalData.selectedDepartureId}
                        departureDate={additionalData.departureDate}
                        formSubmissionObject={formSubmissionObject}
                        implicitUpdateFormSubmissionObject={
                            implicitUpdateFormSubmissionObject
                        }
                        explicitUpdateFormSubmissionObject={
                            explicitUpdateFormSubmissionObject
                        }
                        handleSubmit={handleSubmit}
                        finalDepartureList={additionalData.finalDepartureList}
                    />
                );
            default:
                return "Form Error";
        }
    }

    return renderForm(form);
};

export default MarketoForm;

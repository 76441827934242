import React, { useState } from "react";

//Import Material UI Components
import { makeStyles } from "@material-ui/core/styles";

import BrBookingToolNew from "../elements/BrBookingToolNew";

//Build Component Styles
const useStyles = makeStyles((theme) => ({
	text: {
		fontFamily: theme.typography.body1.fontFamily,
	},
	title: {
		fontFamily: theme.typography.h3.fontFamily,
		fontWeight: 300,
		textTransform: "uppercase",
		marginTop: 0,
	},
	note: {
		fontStyle: "italic",
		fontSize: "12px",
	},
	currency: {
		position: "relative",
		fontSize: "14px",
		borderBottom: "1px solid #fff",
		top: "-10px",
	},
	ctaButtons: {
		textAlign: "center",
		position: "relative",
		top: "25%",
	},
	shareCta: {
		background: "#fff",
		color: "#494949",
		marginBottom: "15px",
		maxWidth: "175px",
		width: "100%",
		lineHeight: 1.2,
		padding: "10px",
	},
	itineraryCta: {
		background: "#3E739B",
		color: "#fff",
		maxWidth: "175px",
		width: "100%",
		lineHeight: 1.2,
		padding: "10px",
	},

	tripDates: {
		backgroundColor: "#494949",
		padding: "35px",
	},
	tripDatesTitle: {
		textAlign: "left",
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		fontSize: "20px",
		marginTop: 0,
		color: "#fff",
		textTransform: "uppercase",
		borderBottom: "1px solid #fff",
		width: "40%",
	},
	tripDatesInfo: {
		backgroundColor: "#fff",
		color: "#494949",
		textAlign: "left",
		padding: "35px",
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		textTransform: "uppercase",
	},
	bookCta: {
		background: "#96AF8E",
		color: "#494949",
		maxWidth: "190px",
		width: "100%",
		lineHeight: 1.2,
		padding: "10px",
		color: "#fff",
		marginRight: "15px",
	},
	privatizeCta: {
		background: "#194666",
		color: "#fff",
		maxWidth: "190px",
		width: "100%",
		lineHeight: 1.2,
		padding: "10px",
	},
	ctaButtons2: {
		textAlign: "left",
		marginTop: "25px",
	},
	departureImageContainer: {
		height: "450px",
	},
	departureImage: {
		height: "100%",
		objectFit: "cover",
	},
	title1: {
		fontFamily: theme.typography.h5.fontFamily,
		fontWeight: 300,
		textAlign: "center",
		fontSize: theme.typography.h5.fontSize,
		color: "#194666",
	},
	title2: {
		fontFamily: theme.typography.h1.fontFamily,
		textAlign: "left",
		fontSize: "20px",
		color: "#fff",
		marginTop: 0,
		fontWeight: 300,
		borderBottom: "1px solid #fff",
		width: "75%",
		paddingBottom: "10px",
	},
	title3: {
		fontFamily: theme.typography.h1.fontFamily,
		textAlign: "left",
		fontSize: "16px",
		color: "#fff",
		marginTop: 0,
		fontWeight: 300,
	},
	hotel1: {
		backgroundColor: "#194666",
		padding: "35px",
		height: "460px",
	},
	hotel2: {
		backgroundColor: "#70B2C4",
		padding: "35px",
		textTransform: "uppercase",
	},
	hotelImageContainer: {
		width: "100%",
		height: "100%",
	},
	hotelImage: {
		objectFit: "cover",
		height: "100%",
		width: "100%",
	},
	text4: {
		fontFamily: theme.typography.body1.fontFamily,
		textAlign: "left",
		fontSize: "14px",
		color: "#fff",
		margin: 0,
		padding: 0,
		listStyle: "none",
	},
	tripLegendContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
	tripLegend: {
		width: "7px",
		height: "17px",
		display: "inline-block",
		position: "relative",
		top: "4px",
		borderRadius: "10px",
		marginRight: "3px",
	},
	tripSchedule: {
		fontFamily: theme.typography.h1.fontFamily,
		textAlign: "left",
		fontSize: "14px",
		color: "#494949",
		marginBottom: 0,
		marginTop: "30px",
		fontWeight: 300,
	},
	price3: {
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: "10px",
		textTransform: "uppercase",
		margin: 0,
		marginBottom: "3px",
		marginTop: "20px",
	},
	price4: {
		fontFamily: theme.typography.h1.fontFamily,
		borderBottom: "1px solid #494949",
		textTransform: "uppercase",
		margin: 0,
		padding: "3px 0px",
		fontSize: "25px",
		width: "25%",
	},
	note2: {
		fontSize: "10px",
		fontWeight: "bold",
	},
	tripCurrency: {
		position: "relative",
		fontSize: "14px",
		top: "-10px",
	},
	schedule: {
		backgroundColor: "#6C705C",
		padding: "40px",
	},
	discoverMore: {
		backgroundColor: "#3E739B",
		padding: "20px 20px 70px 20px",
	},
	discoverContainer: {
		height: "275px",
		width: "275px",
		margin: "0 auto",
	},
	discoverImage: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
	loraTitle: {
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		textTransform: "uppercase",
		fontSize: "20px",
	},
	activityInfo: {
		padding: "40px",
	},
	dayHeading: {
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		textTransform: "uppercase",
	},
	smallGroupBlogs: {
		backgroundColor: "#fff",
		padding: "4rem 0 4rem 0",
	},
	smallGroupBlogsTitle: {
		color: "#494949",
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: "32px",
		fontWeight: 300,
		textTransform: "uppercase",
		textAlign: "center",
		width: "100%",
		padding: "2rem 0",
	},
	noteworthyTrip: {
		position: "relative",
		display: "inline-block",
		width: "100%",
	},
	smallGroupBlogsPostTitleBlock: {
		background: "salmon",
		padding: "2rem 0",
		width: "80%",
		margin: "-50px auto 0",
		position: "relative",
		zIndex: "99",
	},
	smallGroupBlogsPostImageBlock: {
		zIndex: "0",
		position: "relative",
		width: "90%",
		margin: "auto",
	},
	smallGroupBlogsPostTitle: {
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: "16px",
		fontWeight: 300,
		textTransform: "uppercase",
		textAlign: "center",
		color: "#fff",
		width: "100%",
		padding: "0 1rem 1rem 1rem",
	},
	tripMetaGrid: {
		overflowX: "hidden",
	},
}));

const ChooseDeparture = (props) => {
	const { tripContent, departureContent, itinerary } = props;

	//Pull In Styles
	const classes = useStyles();

	//Handle state change for toggle buttons component for trip year
	const [year, setYear] = React.useState("2020");
	const handleYear = (event, newYear) => {
		setYear(newYear);
	};

	//Handle state change for toggle buttons component for trip departure date
	const [departureDate, setDepartureDate] = React.useState("Sep 23 - Sep 26");
	const handleDepartureDate = (event, newDepartureDate) => {
		setDepartureDate(newDepartureDate);
	};

	return (
		<BrBookingToolNew itinerary={itinerary} tripData={tripContent.trip} />
	);
};

export default ChooseDeparture;

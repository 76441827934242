import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";

//React Slick Carousel Imports
import Slider from "react-slick";

//Import Material UI Stuff
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//Set up Styles
const useStyles = makeStyles((theme) => ({
    bespokeBlogs: {
        backgroundColor: "transparent",
        padding: "0 0 0 0",
    },
    bespokeBlogsTitle: {
        color: "#494949",
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "24px",
        fontWeight: 300,
        textTransform: "uppercase",
        textAlign: "center",
        width: "100%",
        padding: "1rem 0",
        marginBottom: "40px",
    },
    noteworthyTrip: {
        position: "relative",
        display: "inline-block",
        width: "100%",
    },
    blogPostsContainer: {
        textDecoration: "none",
        "&:hover": {
            "& .blog-posts-title": {
                color: "#494949",
            },
            "& .read-more-cta": {
                color: "#494949",
            },
            "& .blog-posts-title-block": {
                background: "#fff",
                border: "3px solid #C4635F",
            },
        },
    },
    bespokeBlogsPostTitleBlock: {
        background: "#C4635F",
        border: "3px solid #C4635F",
        padding: "7px 0px",
        width: "75%",
        margin: "-50px auto 0",
        position: "relative",
        zIndex: "99",
    },
    bespokeBlogsPostImageBlock: {
        zIndex: "0",
        position: "relative",
        width: "85%",
        height: "315px",
        margin: "auto",
    },
    bespokeBlogsPostTitle: {
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: "17px",
        fontWeight: 300,
        textTransform: "uppercase",
        textAlign: "center",
        color: "#fff",
        width: "100%",
        padding: "0 1rem 0 1rem",
    },
    readMoreCta: {
        fontFamily: theme.typography.h1.fontFamily,
        fontStyle: "italic",
        color: "#fff",
        textAlign: "center",
        fontWeight: 300,
        margin: "5px 0px",
        fontSize: "15px !important",
    },
    blogCarousel: {
        "& .slick-dots": {},
    },
}));

function parseWpCharacters(s) {
    let string = s;
    string = string.replace(/&#038;/g, "&");
    string = string.replace(/&#8217;/g, "'");
    return string;
}

export default function BlogCarousel(props) {
    //Spread Props
    const { entityData, title } = props;

    let blogObject = JSON.parse(entityData);
    //Set Media Query
    const mobileWidth = useMediaQuery("(max-width:990px)");

    //Pull In Styles
    const classes = useStyles();

    //Fetch Blog Data
    let blogs = [
        blogObject.blog_1,
        blogObject.blog_2,
        blogObject.blog_3,
        blogObject.blog_4,
        blogObject.blog_5,
    ].filter(Boolean);

    //Hack to prevent double render when number of blogs is less than slidesToShow
    let isInfinite = blogs.length < 3 ? false : true;

    //Set responsive carousel settings
    const carouselSettings = {
        dots: true,
        infinite: isInfinite,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 990,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div style={{ width: "100%" }} className={"blog-carousel"}>
            {false ? (
                <></>
            ) : (
                <>
                    {blogs.length > 0 ? (
                        <Container
                            className={classes.bespokeBlogs}
                            disableGutters={true}
                            maxWidth={false}
                        >
                            <div
                                style={
                                    mobileWidth
                                        ? { maxWidth: "100vw" }
                                        : { maxWidth: "1000px", margin: "auto" }
                                }
                                className={classes.blogCarousel}
                            >
                                <Slider {...carouselSettings}>
                                    {blogs.map((blog, index) => {
                                        return (
                                            <a
                                                href={`${process.env.REACT_APP_APP_ENDPOINT}/get-inspired/${blog.slug}`}
                                                key={index}
                                                className={
                                                    classes.blogPostsContainer
                                                }
                                                target="_blank"
                                            >
                                                <div>
                                                    <div
                                                        className={
                                                            classes.bespokeBlogsPostImageBlock
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                blog._embedded[
                                                                    "wp:featuredmedia"
                                                                ][0].source_url
                                                            }
                                                            alt="hero-image"
                                                            style={{
                                                                objectFit:
                                                                    "cover",
                                                                height: "100%",
                                                                width: "100%",
                                                            }}
                                                        ></img>
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.bespokeBlogsPostTitleBlock +
                                                            " blog-posts-title-block"
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.bespokeBlogsPostTitle +
                                                                " blog-posts-title"
                                                            }
                                                            component="h2"
                                                        >
                                                            {parseWpCharacters(
                                                                blog.title
                                                                    .rendered
                                                            )}
                                                        </Typography>
                                                        <p
                                                            className={
                                                                classes.readMoreCta +
                                                                " read-more-cta"
                                                            }
                                                        >
                                                            read more
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </Container>
                    ) : (
                        ""
                    )}
                </>
            )}
        </div>
    );
}

BlogCarousel.propTypes = {
    tripInfo: PropTypes.object.isRequired,
};

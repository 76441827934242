//React & Material UI Imports
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import Carousel from "react-material-ui-carousel";

//Import Images
import printLogo from "../../images/BnR-Hero-Logo-Color-Dark.jpg";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "800px",
        margin: "auto",
        textAlign: "left",
        padding: "60px 20px 20px 20px",
    },
    hero: {
        position: "relative",
        textAlign: "center",
        maxHeight: "60vh",
        width: "100%",
    },
    heroMobile: {
        position: "relative",
        textAlign: "center",
        maxHeight: "40vh",
        width: "100%",
    },
    title: {
        fontFamily: theme.typography.h3.fontFamily,
        fontWeight: 300,
        textTransform: "uppercase",
        marginTop: 0,
    },
    text: {
        fontFamily: theme.typography.body1.fontFamily,
        "& p": {
            textAlign: "justify",
        },
    },
    introduction: {
        maxWidth: "980px",
        margin: "0 auto",
        overflow: "hidden",
    },
}));

//Run Component
export default function ItineraryIntroduction(props) {
    const {
        urlToHyperlink,
        tripType,
        isBooked,
        isValidDate,
        tripStart,
        tripEnd,
        dateDisplayOptions,
        itinerary,
        heroImages,
        mobileWidth,
        isPrint,
        selfGuided,
    } = props;

    const renderIntroductionDate = (startDate, endDate) => {
        const isSameYear = () => {
            let startDateYear = startDate.getUTCFullYear();
            let endDateYear = endDate.getUTCFullYear();

            return startDateYear == endDateYear ? true : false;
        };
        const isSameMonth = () => {
            let startDateYear = startDate.getUTCMonth();
            let endDateYear = endDate.getUTCMonth();

            return startDateYear == endDateYear ? true : false;
        };

        return (
            <>
                {isSameYear() ? (
                    <span style={{ textTransform: "capitalize" }}>
                        {new Intl.DateTimeFormat("en-US", {
                            month: "long",
                            day: "numeric",
                            timeZone: "UTC",
                        }).format(tripStart)}{" "}
                        -{" "}
                        {new Intl.DateTimeFormat(
                            "en-US",
                            dateDisplayOptions
                        ).format(tripEnd)}
                    </span>
                ) : (
                    <span style={{ textTransform: "capitalize" }}>
                        {new Intl.DateTimeFormat(
                            "en-US",
                            dateDisplayOptions
                        ).format(tripStart)}{" "}
                        -{" "}
                        {new Intl.DateTimeFormat(
                            "en-US",
                            dateDisplayOptions
                        ).format(tripEnd)}
                    </span>
                )}
            </>
        );
    };

    const renderHeroImageSlides = (imageArray) => {
        if (imageArray) {
            return imageArray.map((image) => (
                <div
                    className={mobileWidth ? classes.heroMobile : classes.hero}
                >
                    <img
                        src={image}
                        alt="hero-image"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                            height: mobileWidth ? "45vh" : "65vh",
                        }}
                    ></img>
                </div>
            ));
        }
    };

    //Pull In Styles
    const classes = useStyles();

    const buildItineraryIntroduction = () => {
        return (
            <div
                style={{
                    backgroundColor:
                        tripType === "Small Group"
                            ? "#96AF8E"
                            : selfGuided
                            ? "#8eafaf"
                            : "#DBAA79",
                }}
            >
                <div
                    className={classes.introduction}
                    style={{ padding: "20px" }}
                >
                    <div style={{ borderBottom: "1px solid #494949" }}>
                        <h2
                            className={classes.title + " printTitle"}
                            style={{
                                color: "#fff",
                                fontSize: "30px",
                                paddingBottom: "0.5rem",
                                marginBottom: "0",
                            }}
                        >
                            {itinerary.name}
                        </h2>
                        {isBooked ? (
                            <h2
                                className={classes.title}
                                style={{
                                    color: "#494949",
                                    fontSize: "20px",
                                    fontStyle: "italic",
                                }}
                            >
                                {itinerary.client_name}
                            </h2>
                        ) : (
                            <></>
                        )}
                    </div>
                    <h2
                        className={classes.title}
                        style={{
                            fontSize: isPrint ? "1.0rem" : "1.6rem",
                            color: "#494949",
                            margin: "12px auto 0",
                        }}
                    >
                        {isBooked && isValidDate(tripStart) ? (
                            <>
                                {/* <span style={{ textTransform: "capitalize" }}>
                                    {new Intl.DateTimeFormat(
                                        "en-US",
                                        dateDisplayOptions
                                    ).format(tripStart)}{" "}
                                    -{" "}
                                    {new Intl.DateTimeFormat(
                                        "en-US",
                                        dateDisplayOptions
                                    ).format(tripEnd)}
                                </span>
                                <br /> */}
                                {renderIntroductionDate(tripStart, tripEnd)}
                                <br />
                                <span style={{ fontSize: "1rem" }}>
                                    {itinerary.days} Days | {itinerary.days - 1}{" "}
                                    Nights
                                </span>
                            </>
                        ) : (
                            <>
                                {itinerary.days} Days | {itinerary.days - 1}{" "}
                                Nights
                            </>
                        )}
                    </h2>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: urlToHyperlink(itinerary.summary),
                        }}
                    ></div>
                </div>
            </div>
        );
    };

    //Render
    return (
        <div>
            {!isPrint ? (
                <Carousel navButtonsAlwaysInvisible={true}>
                    {renderHeroImageSlides(heroImages)}
                </Carousel>
            ) : (
                <>
                    <div>
                        <img
                            style={{ width: "175px", marginTop: "25px" }}
                            src={printLogo}
                        ></img>
                    </div>
                    <div style={{ maxWidth: "95%", margin: "auto" }}>
                        <p style={{ fontFamily: "Lora, serif" }}>
                            <em>
                                Exported on:{" "}
                                {new Intl.DateTimeFormat("en-US", {
                                    //  weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                    timeZone: "UTC",
                                }).format()}
                            </em>
                        </p>
                    </div>
                </>
            )}
            {buildItineraryIntroduction()}
            <div style={{ pageBreakAfter: "always" }}></div>
        </div>
    );
}

ItineraryIntroduction.propTypes = {
    tripType: PropTypes.string.isRequired,
    isBooked: PropTypes.bool.isRequired,
    tripStart: PropTypes.any.isRequired,
    tripEnd: PropTypes.any.isRequired,
    dateDisplayOptions: PropTypes.object.isRequired,
    itinerary: PropTypes.object.isRequired,
    heroImages: PropTypes.array.isRequired,
};

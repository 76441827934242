//Import React
import React from "react";
import "./App.css";

//Import Layout Components
import Header from "./components/__layout/Header";
import Body from "./components/__layout/Body";
import Footer from "./components/__layout/Footer";

//Bring in React Router
import { BrowserRouter as Router } from "react-router-dom";

//Import Material UI Theme Elements
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme/butterfieldMuiTheme";

function App() {
    const loginState = JSON.parse(localStorage.getItem("loggedIn"));
    return (
        <div className="App">
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <Router>
                    <Header />
                    <Body loginState={loginState} />
                    <Footer />
                </Router>
            </ThemeProvider>
        </div>
    );
}

export default App;

//React and Material UI Imports
import React, { useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

//Import CSS Style
//import "../../../css/newsletterSignUpForm.css";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "960px",
        margin: "auto",
        textAlign: "left",
        padding: "40px",
    },
    title: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        color: "#194666",
    },
    travellerInformation: {
        display: "flex",
        fontSize: "16px",
        alignItems: "flex-end",
        fontFamily: theme.typography.body1.fontFamily,
    },
    signUpFields: {
        "& input": {
            border: 0,
            fontFamily: theme.typography.body1.fontFamily,
        },
        "& input::before": {
            borderBottom: "1px solid #194666",
        },
    },
    formSubmitButton: {
        "&:hover": {
            backgroundColor: "rgb(103 103 103) !important",
        },
    },
}));

export default function NewsletterSignUpForm(props) {
    const {
        formSubmissionObject,
        implicitUpdateFormSubmissionObject,
        handleSubmit,
        submitted,
    } = props;

    //Pull in styles
    const classes = useStyles();

    //Render
    return submitted ? (
        //Render form confirmation message
        <div className={"newsletter-signup-form"}>
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "30px 30px 50px 30px",
                }}
            >
                <div style={{ maxWidth: "735px", margin: "0 auto" }}>
                    <h3
                        style={{
                            fontFamily: "Lora, serif",
                            fontWeight: 300,
                            fontSize: "22px",
                            color: "#494949",
                            textTransform: "uppercase",
                            textAlign: "center",
                        }}
                    >
                        Thank you for your signing up to receive our emails!
                    </h3>
                </div>
            </div>
        </div>
    ) : (
        //Render form
        <div className={classes.signUpFields + " newsletter-signup-form"}>
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "0px 50px 10px 50px",
                    backgroundImage:
                        "linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)),url(" +
                        "/images/globe-illustration.svg" +
                        ")",
                    backgroundSize: "295px",
                    backgroundPosition: "380px",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div style={{ maxWidth: "735px", margin: "0 auto" }}>
                    <h1 className={classes.title}>sign up for b&r emails</h1>
                    <p
                        style={{
                            textAlign: "center",
                            marginBottom: "20px",
                            color: "#194666",
                            fontSize: "13px",
                        }}
                    >
                        Be the first to receive news and announcements from
                        Butterfield & Robinson and our global community, from
                        brand new trips to our slow travel initiatives.
                    </p>
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                                color: "#194666",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                        textAlign: "right",
                                        paddingRight: "10px",
                                    }}
                                >
                                    First Name*
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="first-name"
                                    name="FirstName"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                                color: "#194666",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                        textAlign: "right",
                                        paddingRight: "10px",
                                    }}
                                >
                                    Last Name*
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="last-name"
                                    name="LastName"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                                color: "#194666",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                        textAlign: "right",
                                        paddingRight: "10px",
                                    }}
                                >
                                    Email*
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="Email"
                                    name="Email"
                                    value={formSubmissionObject["Email"]}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <Button
                            style={{
                                backgroundColor: "#494949",
                                color: "#fff",
                                padding: "6px 20px",
                                margin: "30px 0px 10px 0px",
                            }}
                            className={classes.formSubmitButton}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                        <div>
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "11.5px",
                                    marginBottom: "20px",
                                }}
                            >
                                By submitting this form, you agree to the
                                Butterfield & Robinson{" "}
                                <Link
                                    href="/terms-and-conditions"
                                    style={{ color: "#194666" }}
                                >
                                    Terms
                                </Link>{" "}
                                and{" "}
                                <Link
                                    href="/privacy-policy"
                                    style={{ color: "#194666" }}
                                >
                                    Privacy Policy
                                </Link>
                                .
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

//React and Material UI Imports
import React, { useReducer, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";

//Import Reducers
import { shareTripFormReducer } from "../formReducers/ShareTripFormReducer";

//Import API
import { api } from "../../../api/api";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "960px",
        margin: "auto",
        textAlign: "left",
        padding: "40px",
    },
    title: {
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        color: "#494949",
        textTransform: "uppercase",
    },
    travellerInformation: {
        fontSize: "15px !important",
        fontFamily: theme.typography.body1.fontFamily,
    },
    recipientFieldRow: {
        margin: "15px 0px",
    },
    signUpFields: {
        background: "#494949",
        "& input": {
            border: 0,
            fontFamily: theme.typography.body1.fontFamily,
            padding: 0,
        },
        "& input::before": {
            borderBottom: "1px solid #194666",
        },
    },
    formSubmitButton: {
        "&:hover": {
            backgroundColor: "#494949",
        },
    },
    addRecipientsButton: {
        "&:hover": {
            backgroundColor: "#194666",
        },
    },
}));

export default function ShareTripForm(props) {
    const {
        formHeading,
        tripType,
        tripUrl,
        formSubmissionObject,
        implicitUpdateFormSubmissionObject,
        explicitUpdateFormSubmissionObject,
        handleSubmit,
        submitted,
        itineraryId,
    } = props;

    const [recipientsCount, setRecipientsCount] = useState(1);
    const [shareTripRecipientsArray, setShareTripRecipientsArray] = useState(
        []
    );

    const updateShareTripRecipientsArray = (field, value, index) => {
        let updateArray = shareTripRecipientsArray;

        updateArray[index] = {
            ...updateArray[index],
            [field]: value,
        };

        setShareTripRecipientsArray(updateArray);
    };

    const sendShareTripEmail = () => {
        let postObject = {
            method: "POST",
            body: {
                senderEmail: formSubmissionObject.Email,
                senderFirstName: formSubmissionObject.FirstName,
                senderLastName: formSubmissionObject.LastName,
                tripName: formHeading,
                tripUrl: tripUrl,
                isGroupEmail: shareTripFormInfo.isGroupEmail,
                recipientsArray: shareTripRecipientsArray,
            },
        };

        console.log(postObject);
        api(
            process.env.REACT_APP_API_ENDPOINT + "/v1/emails/shareTripEmail",
            postObject
        )
            .then((data) => {
                console.log(data);
                if (data.status == 250) {
                    handleSubmit();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const renderRecipientsRow = () => {
        let rows = [];
        for (let i = 0; i < recipientsCount; i++) {
            rows.push(
                <Grid container className={classes.recipientFieldRow}>
                    <Grid item xs={12} sm={4} md={4}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                            }}
                        >
                            <p
                                style={{
                                    width: "75%",
                                    margin: 0,
                                    paddingRight: "10px",
                                }}
                                className={classes.travellerInformation}
                            >
                                First Name*
                            </p>

                            <TextField
                                id={"recipient-" + i + "-first-name"}
                                name={"recipientFirstName"}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    updateShareTripRecipientsArray(
                                        e.target.name,
                                        e.target.value,
                                        i
                                    );
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className={classes.travellerInformation}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: "10px",
                            }}
                        >
                            <p
                                style={{
                                    width: "75%",
                                    margin: 0,
                                    paddingRight: "10px",
                                }}
                                className={classes.travellerInformation}
                            >
                                Last Name*
                            </p>

                            <TextField
                                id={"recipient-" + i + "-last-name"}
                                name={"recipientLastName"}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    updateShareTripRecipientsArray(
                                        e.target.name,
                                        e.target.value,
                                        i
                                    );
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={4}
                        className={classes.travellerInformation}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <p
                                style={{
                                    width: "75%",
                                    margin: 0,
                                    paddingRight: "10px",
                                }}
                                className={classes.travellerInformation}
                            >
                                Their Email*
                            </p>

                            <TextField
                                style={{ width: "100%" }}
                                id={"recipient-" + i + "-email"}
                                name={"recipientEmail"}
                                onChange={(e) => {
                                    updateShareTripRecipientsArray(
                                        e.target.name,
                                        e.target.value,
                                        i
                                    );
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            );
        }
        return rows;
    };

    const initialState = {
        newsletterSubscription: "",
        isGroupEmail: "",
        contactPreferences: "",
        ccTravelAdvisor: "",
    };

    const [shareTripFormInfo, dispatchShareTripFormInfo] = useReducer(
        shareTripFormReducer,
        initialState
    );

    //Pull in styles
    const classes = useStyles();

    //Fetch Page Data
    useEffect(() => {
        explicitUpdateFormSubmissionObject({
            inquirySubject:
                tripType +
                " Share Trip Submission - " +
                (formHeading ? formHeading : ""),
            requestedItineraryId: itineraryId,
            tripUrl: tripUrl,
        });
    }, []);

    //Render
    return submitted ? (
        //Render form confirmation message
        <div className={"share-trip-form"}>
            <div style={{ padding: "0px" }}>
                <div
                    style={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "30px 30px 50px 30px",
                    }}
                >
                    <div
                        style={{
                            maxWidth: "850px",
                            margin: "0 auto",
                            background: "#fff",
                        }}
                    >
                        <h3
                            style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 300,
                                fontSize: "22px",
                                color: "#494949",
                                textTransform: "uppercase",
                                textAlign: "center",
                            }}
                        >
                            You shared {formHeading}!
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        //Render form
        <div className={classes.signUpFields + " share-trip-form"}>
            <div style={{ padding: "0px" }}>
                <div
                    style={{
                        maxWidth: "850px",
                        margin: "0 auto",
                        padding: "30px 50px 50px 50px",
                        background: "#fff",
                        borderRadius: "10px",
                    }}
                >
                    <h1 className={classes.title}>
                        Share Trip:{" "}
                        <span
                            style={
                                tripType ==
                                "bfbe15fe-3f90-df11-a53e-005056ba41b5"
                                    ? { color: "#96AF8E", fontStyle: "italic" }
                                    : { color: "#DBAA79", fontStyle: "italic" }
                            }
                        >
                            {formHeading}
                        </span>
                    </h1>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "20px",
                        }}
                        className={classes.travellerInformation}
                    >
                        <div style={{ margin: "0px 0px 60px 0px" }}>
                            <p
                                style={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                    margin: "0px 0px 35px 0px",
                                }}
                            >
                                Your Information
                            </p>
                            <Grid
                                container
                                className={classes.travellerInformation}
                            >
                                <Grid item xs={12} sm={4} md={4}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                width: "75%",
                                                margin: 0,
                                                paddingRight: "10px",
                                            }}
                                            className={
                                                classes.travellerInformation
                                            }
                                        >
                                            First Name*
                                        </p>

                                        <TextField
                                            id="first-name"
                                            name="FirstName"
                                            style={{ width: "100%" }}
                                            onChange={
                                                implicitUpdateFormSubmissionObject
                                            }
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className={classes.travellerInformation}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        <p
                                            style={{
                                                width: "75%",
                                                margin: 0,
                                                paddingRight: "10px",
                                            }}
                                            className={
                                                classes.travellerInformation
                                            }
                                        >
                                            Last Name*
                                        </p>

                                        <TextField
                                            id="last-name"
                                            name="LastName"
                                            style={{ width: "100%" }}
                                            onChange={
                                                implicitUpdateFormSubmissionObject
                                            }
                                        />
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={4}
                                    className={classes.travellerInformation}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <p
                                            style={{
                                                width: "75%",
                                                margin: 0,
                                                paddingRight: "10px",
                                            }}
                                            className={
                                                classes.travellerInformation
                                            }
                                        >
                                            Your Email*
                                        </p>

                                        <TextField
                                            style={{ width: "100%" }}
                                            id="Email"
                                            name="Email"
                                            onChange={
                                                implicitUpdateFormSubmissionObject
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{ margin: "0px 0px 60px 0px" }}>
                            <p
                                style={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                    margin: "0px 0px 35px 0px",
                                }}
                            >
                                Who Are You Sharing With?
                            </p>

                            {renderRecipientsRow()}
                            {recipientsCount < 8 ? (
                                <>
                                    <Button
                                        style={{
                                            backgroundColor: "#194666",
                                            color: "#fff",
                                            padding: "6px 20px",
                                            margin: "30px 0px 10px 0px",
                                        }}
                                        className={classes.addRecipientsButton}
                                        onClick={() => {
                                            setRecipientsCount(
                                                recipientsCount + 1
                                            );
                                        }}
                                    >
                                        + Add More
                                    </Button>{" "}
                                    <p
                                        style={{
                                            margin: 0,
                                            textAlign: "center",
                                            fontSize: "12px",
                                        }}
                                        className={classes.travellerInformation}
                                    >
                                        (Max. 8 recipients)
                                    </p>
                                </>
                            ) : (
                                ""
                            )}
                        </div>

                        <div>
                            <p
                                className={classes.travellerInformation}
                                style={{ textAlign: "center" }}
                            >
                                Include a message to your recipients (optional):
                            </p>
                            <TextField
                                id="message"
                                name="message"
                                multiline
                                rows={5}
                                variant="outlined"
                                style={{ width: "100%" }}
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                        </div>

                        <div>
                            <FormLabel
                                component="legend"
                                style={{
                                    color: "#494949",
                                    marginTop: "55px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    lineHeight: "1.6",
                                    maxWidth: "450px",
                                }}
                                className={classes.travellerInformation}
                            >
                                Create a group email to discuss this trip?
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-label="create-group-email"
                                name="createGroupEmail"
                                value={shareTripFormInfo.isGroupEmail}
                                onChange={(event) => {
                                    dispatchShareTripFormInfo({
                                        type: "SET_CREATE_GROUP_EMAIL_INFO",
                                        payload: event.target.value,
                                    });
                                    implicitUpdateFormSubmissionObject(event);
                                }}
                                style={{
                                    color: "#494949",
                                    justifyContent: "center",
                                }}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Yes"
                                    style={{ color: "#494949" }}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="No"
                                    style={{ color: "#494949" }}
                                />
                            </RadioGroup>
                        </div>

                        {shareTripFormInfo.isGroupEmail == "true" ? (
                            <div>
                                <FormLabel
                                    component="legend"
                                    style={{
                                        color: "#494949",
                                        marginTop: "15px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        lineHeight: "1.6",
                                        maxWidth: "450px",
                                    }}
                                    className={classes.travellerInformation}
                                >
                                    Would you like to CC a Travel Advisor to
                                    help plan your trip?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="create-group-email"
                                    name="ccTravelAdvisor"
                                    value={shareTripFormInfo.ccTravelAdvisor}
                                    onChange={(event) => {
                                        dispatchShareTripFormInfo({
                                            type: "SET_CC_TRAVEL_ADVISOR",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    style={{
                                        color: "#494949",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Yes"
                                        style={{ color: "#494949" }}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="No"
                                        style={{ color: "#494949" }}
                                    />
                                </RadioGroup>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div>
                            <FormLabel
                                component="legend"
                                style={{
                                    color: "#494949",
                                    marginTop: "15px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    lineHeight: "1.6",
                                    maxWidth: "450px",
                                }}
                                className={classes.travellerInformation}
                            >
                                Would you like to be contacted directly by a
                                Travel Advisor?
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-label="contact-preferences"
                                name="contactPrefernces"
                                value={shareTripFormInfo.contactPreferences}
                                onChange={(event) => {
                                    dispatchShareTripFormInfo({
                                        type: "SET_CONTACT_PREFERENCES",
                                        payload: event.target.value,
                                    });
                                    implicitUpdateFormSubmissionObject(event);
                                }}
                                style={{
                                    color: "#494949",
                                    justifyContent: "center",
                                }}
                            >
                                <FormControlLabel
                                    value="phone"
                                    control={<Radio />}
                                    label="By Phone"
                                    style={{ color: "#494949" }}
                                />
                                <FormControlLabel
                                    value="email"
                                    control={<Radio />}
                                    label="By Email"
                                    style={{ color: "#494949" }}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="I don't want to be contacted"
                                    style={{ color: "#494949" }}
                                />
                            </RadioGroup>
                        </div>
                        {shareTripFormInfo.contactPreferences == "phone" ? (
                            <div>
                                <div
                                    style={{
                                        maxWidth: "45%",
                                        margin: "1rem auto 2rem",
                                        display: "flex",
                                        alignItems: "center",
                                        paddingRight: "10px",
                                    }}
                                >
                                    <TextField
                                        id="phone-number"
                                        name="phoneNumber"
                                        placeholder="Please enter you phone number..."
                                        style={{ width: "100%" }}
                                        onChange={
                                            implicitUpdateFormSubmissionObject
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div>
                            <FormLabel
                                component="legend"
                                style={{
                                    color: "#494949",
                                    marginTop: "15px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    lineHeight: "1.6",
                                    maxWidth: "450px",
                                }}
                                className={classes.travellerInformation}
                            >
                                Sign up for our emails to receive B&R news,
                                brand new trip launches, and content from our
                                award-winning blog.
                            </FormLabel>
                            <RadioGroup
                                row
                                aria-label="newsletter-subscription"
                                name="emailOptIn"
                                value={shareTripFormInfo.newsletterSubscription}
                                onChange={(event) => {
                                    dispatchShareTripFormInfo({
                                        type: "SET_NEWSLETTER_SUBSCRIPTION_INFO",
                                        payload: event.target.value,
                                    });
                                    implicitUpdateFormSubmissionObject(event);
                                }}
                                style={{
                                    color: "#494949",
                                    justifyContent: "center",
                                }}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Yes"
                                    style={{ color: "#494949" }}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="No"
                                    style={{ color: "#494949" }}
                                />
                            </RadioGroup>
                        </div>

                        <div style={{ margin: "20px" }}>
                            <p style={{ textAlign: "center" }}>CAPTCHA</p>
                        </div>

                        <Button
                            style={{
                                backgroundColor: "#494949",
                                color: "#fff",
                                padding: "6px 20px",
                                margin: "30px 0px 10px 0px",
                            }}
                            className={classes.formSubmitButton}
                            onClick={() => {
                                sendShareTripEmail();
                            }}
                        >
                            Share Trip
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function startPlanningFormReducer(state, action) {
    switch (action.type) {
        case "SET_DAILY_BUDGET_INFO":
            return { ...state, dailyBudget: action.payload };
        case "SET_TRAVEL_DATE_INFO":
            return { ...state, travelDate: action.payload };
        case "SET_TRAVEL_HISTORY_INFO":
            return { ...state, travelHistory: action.payload };
        case "SET_NEWSLETTER_SUBSCRIPTION_INFO":
            return { ...state, newsletterSubscription: action.payload };
        case "SET_TRAVEL_AGENT_INFO":
            return { ...state, travelAgent: action.payload };
        case "SET_CONTACT_METHOD_INFO":
            return { ...state, contactMethod: action.payload };
    }
}

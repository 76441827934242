import React from "react";

import {
    Map,
    InfoWindow,
    Marker,
    Polyline,
    GoogleApiWrapper,
} from "google-maps-react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
    where,
    scrollToTargetAdjusted,
} from "../../utilities/HelperFunctions/helperFunctions";

const containerStyles = {
    position: "relative",
    width: "100%",
    height: "88%",
};

const mapTitleStyles = {
    color: "#194666",
    marginTop: "0",
    marginBottom: "0",
    fontSize: "24px",
    fontFamily: "Lora, serif",
    fontWeight: "300",
    textTransform: "uppercase",
};

const mapStyles = [
    { stylers: [{ weight: 1 }] },
    { elementType: "geometry", stylers: [{ color: "#ebe3cd" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#523735" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f1e6" }] },
    {
        featureType: "administrative",
        elementType: "geometry.stroke",
        stylers: [{ color: "#c9b2a6" }],
    },
    {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "geometry.stroke",
        stylers: [{ color: "#dcd2be" }],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [{ color: "#ae9e90" }],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "landscape.natural",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "landscape.natural",
        elementType: "geometry.fill",
        stylers: [{ color: "#f1e4c5" }],
    },
    {
        featureType: "poi",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#93817c" }],
    },
    { featureType: "poi.business", stylers: [{ visibility: "off" }] },
    {
        featureType: "poi.park",
        elementType: "geometry.fill",
        stylers: [{ color: "#a5b076" }],
    },
    {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#447530" }],
    },
    {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#f5f1e6" }, { weight: 0.5 }],
    },
    {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [{ color: "#fdfcf8" }],
    },
    { featureType: "road.highway", stylers: [{ weight: 0.5 }] },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#f8c967" }],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#e9bc62" }],
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [{ color: "#e98d58" }],
    },
    {
        featureType: "road.highway.controlled_access",
        elementType: "geometry.stroke",
        stylers: [{ color: "#db8555" }],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [{ color: "#806b63" }],
    },
    {
        featureType: "transit",
        // stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit.line",
        elementType: "geometry.fill",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.fill",
        stylers: [{ color: "#8f7d77" }],
    },
    {
        featureType: "transit.line",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ebe3cd" }],
    },
    {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [{ color: "#dfd2ae" }],
    },
    {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#3e739b" }],
    },
    {
        featureType: "water",
        elementType: "labels.text",
        stylers: [{ visibility: "off" }],
    },
    {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#92998d" }],
    },
];

const accomIconBase = "/mapIcons/accommadationMarkers/";

const accommadationMarkers = [
    `${accomIconBase}A.png`,
    `${accomIconBase}B.png`,
    `${accomIconBase}C.png`,
    `${accomIconBase}D.png`,
    `${accomIconBase}E.png`,
    `${accomIconBase}F.png`,
    `${accomIconBase}G.png`,
    `${accomIconBase}H.png`,
    `${accomIconBase}I.png`,
    `${accomIconBase}J.png`,
    `${accomIconBase}K.png`,
    `${accomIconBase}L.png`,
    `${accomIconBase}M.png`,
    `${accomIconBase}N.png`,
];

const calcCenter = (pins) => {
    let latSum = pins.reduce((a, b) => a + b.lat, 0);
    let lngSum = pins.reduce((a, b) => a + b.lng, 0);

    let latAvg = latSum / pins.length;
    let lngAvg = lngSum / pins.length;

    return { lat: latAvg, lng: lngAvg };
};

const createBounds = (routes, pins) => {
    let points = [];
    routes.map((route) => {
        route.route.map((point) => {
            //Check that all points have a lat/lng pair
            if (point.lat && point.lng) {
                points.push(point);
            }
        });
    });
    pins.map((pin) => {
        points.push({ lat: pin.lat, lng: pin.lng });
    });

    return points;
};

const formatDuration = (durationStr) => {
    if (!durationStr) return "Duration string not provided";

    let finalString = durationStr;

    while (finalString.charAt(0) === ":" || finalString.charAt(0) === "0")
        finalString = finalString.substring(1);

    return finalString;
};

export class ItineraryMap extends React.Component {
    state = {
        showingInfoWindow: false,
        currentDay: null,
        activeMarker: {},
        selectedPlace: {},
        useBounds: true,
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            useBounds: false,
        });

    onMarkerHover = (props, marker, e) => {
        if (this.state.showingInfoWindow) return;
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            useBounds: false,
        });
    };

    onMarkerOut = () => {
        if (!this.state.showingInfoWindow) return;
        this.setState({
            showingInfoWindow: false,
            activeMarker: null,
            useBounds: false,
        });
    };

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                useBounds: false,
            });
        }
    };

    toggleMapMode = () => {
        scrollToTargetAdjusted("mapContainer", this.props.mobileWidth);
        if (this.state.currentDay == null) {
            this.setState({
                currentDay: 0,
                useBounds: true,
            });
            return;
        }
        this.setState({
            currentDay: null,
            useBounds: true,
        });
    };

    incrementCurrentDay = () => {
        scrollToTargetAdjusted("mapContainer", this.props.mobileWidth);
        if (
            this.state.currentDay <
            this._buildDayMapItemsArray(this.props.itinerary).length - 1
        ) {
            this.setState({
                currentDay: this.state.currentDay + 1,
                useBounds: true,
                showingInfoWindow: false,
            });
            return;
        }
    };

    decrementCurrentDay = () => {
        scrollToTargetAdjusted("mapContainer", this.props.mobileWidth);
        if (this.state.currentDay > 0) {
            this.setState({
                currentDay: this.state.currentDay - 1,
                useBounds: true,
                showingInfoWindow: false,
            });
            return;
        }
    };

    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: mapStyles,
        });
        map.setOptions({
            maxZoom: 18,
        });
        map.setMapTypeId("terrain");
    }

    _buildDayMapItemsArray(itinerary) {
        let days = [];

        let routes = this.props.routes;
        let pins = this.props.pins;

        itinerary.legs.map((leg, i) =>
            leg.periods.map((period, j) => {
                let dayObj = {
                    start_day: period.itinerary_start_day + 1,
                    end_day:
                        period.itinerary_end_day - period.itinerary_start_day >
                        0
                            ? period.itinerary_end_day + 1
                            : null,
                    routes: where(routes, {
                        start_day: period.itinerary_start_day,
                    }),
                    legContentEntityId: leg.content_entity_id,
                    pins: where(pins, {
                        content_entity_id: leg.content_entity_id,
                    }),
                    markerUrl: accommadationMarkers[i],
                    activityPins: [],
                };

                if (period.routes) {
                    //add period level pins and routes
                    period.routes
                        .filter((route) => route.mode !== "NoTransit")
                        .map((route) => {
                            //Add Activity Pin Start Content
                            dayObj.activityPins.push({
                                content_entity_id: where(
                                    this.props.itineraryRelatedContent,
                                    {
                                        map_object_id:
                                            route.start_content_entity_id,
                                    }
                                )[0].map_object_id,
                                lat: where(this.props.itineraryRelatedContent, {
                                    map_object_id:
                                        route.start_content_entity_id,
                                })[0].position.latitude,
                                lng: where(this.props.itineraryRelatedContent, {
                                    map_object_id:
                                        route.start_content_entity_id,
                                })[0].position.longitude,
                                name: where(
                                    this.props.itineraryRelatedContent,
                                    {
                                        map_object_id:
                                            route.start_content_entity_id,
                                    }
                                )[0].name,
                            });

                            //Add Activity Pin Start Content
                            dayObj.activityPins.push({
                                content_entity_id: where(
                                    this.props.itineraryRelatedContent,
                                    {
                                        map_object_id:
                                            route.end_content_entity_id,
                                    }
                                )[0].map_object_id,
                                lat: where(this.props.itineraryRelatedContent, {
                                    map_object_id: route.end_content_entity_id,
                                })[0].position.latitude,
                                lng: where(this.props.itineraryRelatedContent, {
                                    map_object_id: route.end_content_entity_id,
                                })[0].position.longitude,
                                name: where(
                                    this.props.itineraryRelatedContent,
                                    {
                                        map_object_id:
                                            route.end_content_entity_id,
                                    }
                                )[0].name,
                            });

                            dayObj.routes.push({
                                start_content_entity_id:
                                    route.start_content_entity_id,
                                end_content_entity_id:
                                    route.end_content_entity_id,
                                start_day: route.start_day,
                                end_day: route.end_day,
                                mode: route.mode,
                                variant: route.variant,
                                distance: route.distance,
                                duration: route.duration,
                                start_time: route.start_time,
                                end_time: route.end_time,
                                route: this.props.buildRoute(
                                    this.props.CSVToArray(route.points, ";")[0]
                                ),
                            });
                        });
                }

                days.push(dayObj);
            })
        );

        return days;
    }

    _generateRouteLabelData(matchTerm) {
        if (!matchTerm) return "No Match";

        let lowerMatchTerm = matchTerm.toLowerCase();

        if (
            lowerMatchTerm === "selfdrive" ||
            lowerMatchTerm === "selfdrive_motorbike" ||
            lowerMatchTerm === "selfdrive_car" ||
            lowerMatchTerm === "selfdrive_4x4" ||
            lowerMatchTerm === "selfdrive_4x4_enclosed" ||
            lowerMatchTerm === "rv" ||
            lowerMatchTerm === "selfdrive_cycling"
        ) {
            return {
                name: "Self Drive",
                color: "#8c8cff",
                showDuration: true,
                showDistance: true,
                showStartEndTime: false,
            };
        }
        if (
            lowerMatchTerm === "transfer_car" ||
            lowerMatchTerm === "transfer_bus" ||
            lowerMatchTerm === "transfer_walk" ||
            lowerMatchTerm === "transfer_cycling" ||
            lowerMatchTerm === "transfer_4x4" ||
            lowerMatchTerm === "transfer_4x4_enclosed"
        ) {
            return {
                name: "Transfer",
                color: "#ff8966",
                showDuration: true,
                showDistance: true,
                showStartEndTime: false,
            };
        }
        if (lowerMatchTerm === "cycle") {
            return {
                name: "Bike",
                color: "#66bcff",
                showDuration: false,
                showDistance: false,
                showStartEndTime: false,
            };
        }
        if (lowerMatchTerm === "hiking" || lowerMatchTerm === "hike") {
            return {
                name: "Walk",
                color: "#66bcff",
                showDuration: false,
                showDistance: false,
                showStartEndTime: false,
            };
        }
        if (lowerMatchTerm === "scheduledflight") {
            return {
                name: "Flight",
                color: "#bf575c",
                showDuration: this.props.isBooked ? true : false,
                showDistance: false,
                showStartEndTime: this.props.isBooked ? true : false,
            };
        }
        if (lowerMatchTerm === "boat_speedboat" || lowerMatchTerm === "boat") {
            return {
                name: "Boat",
                color: "#d2bc6d",
                showDuration: false,
                showDistance: false,
                showStartEndTime: false,
            };
        }
        if (lowerMatchTerm === "train" || lowerMatchTerm === "tram") {
            return {
                name: "Train",
                color: "#5ecbcd",
                showDuration: false,
                showDistance: false,
                showStartEndTime: false,
            };
        }
        return {
            name: matchTerm,
            color: "#ff8966",
            showDuration: false,
            showDistance: false,
            showStartEndTime: false,
        };
    }

    render() {
        let days = this._buildDayMapItemsArray(this.props.itinerary);

        var bounds = null;
        if (this.state.useBounds) {
            var points;
            if (this.state.currentDay === null) {
                points = createBounds(this.props.routes, this.props.pins);
                bounds = new this.props.google.maps.LatLngBounds();
                for (var i = 0; i < points.length; i++) {
                    bounds.extend(points[i]);
                }
            } else {
                points = createBounds(
                    days[this.state.currentDay].routes,
                    days[this.state.currentDay].pins
                );
                bounds = new this.props.google.maps.LatLngBounds();
                for (var i = 0; i < points.length; i++) {
                    bounds.extend(points[i]);
                }
            }
        }

        return (
            <>
                <div
                    id={"mapContainer"}
                    style={{ width: "100%", height: "65vh" }}
                >
                    <Map
                        containerStyle={{
                            position: "relative",
                            width: "100%",
                            height: this.props.bySea ? "100%" : "100%",
                        }}
                        google={this.props.google}
                        onClick={this.onMapClicked}
                        bounds={bounds}
                        initialCenter={calcCenter(this.props.pins)}
                        onReady={(mapProps, map) =>
                            this._mapLoaded(mapProps, map)
                        }
                    >
                        {/*This is the Accommadation Pin Component*/}
                        {this.state.currentDay == null ? (
                            this.props.pins.map((pin, i) => (
                                <Marker
                                    onClick={() => {
                                        this.setState({
                                            useBounds: false,
                                            showingInfoWindow: false,
                                        });
                                        this.props.setActiveDialog(i);
                                    }}
                                    onMouseover={(props, marker) =>
                                        this.onMarkerHover(props, marker)
                                    }
                                    onMouseout={() => this.onMarkerOut()}
                                    //  title={pin.name}
                                    name={<h2>{pin.name}</h2>}
                                    position={pin}
                                    icon={
                                        this.props.bySea
                                            ? null
                                            : {
                                                  url: accommadationMarkers[i],
                                                  // anchor: new this.props.google.maps.Point(
                                                  //     32,
                                                  //     32
                                                  // ),
                                                  scaledSize:
                                                      new this.props.google.maps.Size(
                                                          32,
                                                          32
                                                      ),
                                              }
                                    }
                                />
                            ))
                        ) : (
                            <></>
                        )}

                        {/*Day Start Marker*/}
                        {days[this.state.currentDay]?.pins?.length > 0 ? (
                            <Marker
                                // onClick={this.onMarkerClick}
                                onClick={() => {
                                    this.setState({
                                        useBounds: false,
                                        showingInfoWindow: false,
                                    });
                                    this.props.setActiveDialog(
                                        this.props.pins
                                            .map((el) => el.content_entity_id)
                                            .indexOf(
                                                days[this.state.currentDay]
                                                    .legContentEntityId
                                            )
                                    );
                                }}
                                onMouseover={(props, marker) =>
                                    this.onMarkerHover(props, marker)
                                }
                                onMouseout={() => this.onMarkerOut()}
                                // title={days[this.state.currentDay].pins[0].name}
                                name={
                                    <h2>
                                        {
                                            days[this.state.currentDay].pins[0]
                                                .name
                                        }
                                    </h2>
                                }
                                position={days[this.state.currentDay].pins[0]}
                                icon={{
                                    url: days[this.state.currentDay].markerUrl,
                                    // anchor: new this.props.google.maps.Point(
                                    //     32,
                                    //     32
                                    // ),
                                    scaledSize: new this.props.google.maps.Size(
                                        32,
                                        32
                                    ),
                                }}
                            />
                        ) : (
                            <></>
                        )}

                        {/*Day End Marker*/}
                        {days[this.state.currentDay - 1]?.pins?.length > 0 ? (
                            <Marker
                                //onClick={this.onMarkerClick}
                                onClick={() => {
                                    this.setState({
                                        useBounds: false,
                                        showingInfoWindow: false,
                                    });
                                    this.props.setActiveDialog(
                                        this.props.pins
                                            .map((el) => el.content_entity_id)
                                            .indexOf(
                                                days[this.state.currentDay - 1]
                                                    .legContentEntityId
                                            )
                                    );
                                }}
                                onMouseover={(props, marker) =>
                                    this.onMarkerHover(props, marker)
                                }
                                onMouseout={() => this.onMarkerOut()}
                                //  title={days[this.state.currentDay - 1].pins[0].name}
                                name={
                                    <h2>
                                        {
                                            days[this.state.currentDay - 1]
                                                .pins[0].name
                                        }
                                    </h2>
                                }
                                position={
                                    days[this.state.currentDay - 1].pins[0]
                                }
                                icon={{
                                    url: days[this.state.currentDay - 1]
                                        .markerUrl,
                                    // anchor: new this.props.google.maps.Point(
                                    //     32,
                                    //     32
                                    // ),
                                    scaledSize: new this.props.google.maps.Size(
                                        32,
                                        32
                                    ),
                                }}
                            />
                        ) : (
                            <></>
                        )}

                        {/*Day Activity Marker*/}
                        {days[this.state.currentDay]?.activityPins?.length >
                        0 ? (
                            days[this.state.currentDay].activityPins
                                .filter(
                                    (pin) =>
                                        pin.content_entity_id !==
                                        days[this.state.currentDay].pins[0]
                                            .content_entity_id
                                )
                                .map((pin) => {
                                    return (
                                        <Marker
                                            // onClick={this.onMarkerClick}
                                            // onMouseover={(props, marker) =>
                                            //     this.onMarkerHover(props, marker)
                                            // }
                                            // onMouseout={() => this.onMarkerOut()}
                                            // title={pin.name}
                                            name={<h2>{pin.name}</h2>}
                                            position={pin}
                                            icon={{
                                                // url: days[this.state.currentDay]
                                                //     .markerUrl,
                                                // anchor: new this.props.google.maps.Point(
                                                //     32,
                                                //     32
                                                // ),
                                                scaledSize:
                                                    new this.props.google.maps.Size(
                                                        32,
                                                        32
                                                    ),
                                            }}
                                        />
                                    );
                                })
                        ) : (
                            <></>
                        )}

                        {/*This is the Routes Line Component*/}
                        {this.state.currentDay == null
                            ? days.map((day) => {
                                  if (day.routes.length > 0) {
                                      return day.routes.map((route) => (
                                          <Polyline
                                              path={route.route}
                                              strokeColor={
                                                  this._generateRouteLabelData(
                                                      route.variant ||
                                                          route.mode
                                                  ).color
                                              }
                                              strokeOpacity={0.8}
                                              strokeWeight={4}
                                          />
                                      ));
                                  }
                              })
                            : days[this.state.currentDay].routes.map(
                                  (route) => (
                                      <Polyline
                                          path={route.route}
                                          strokeColor={
                                              this._generateRouteLabelData(
                                                  route.variant || route.mode
                                              ).color
                                          }
                                          strokeOpacity={0.8}
                                          strokeWeight={4}
                                      />
                                  )
                              )}

                        {/*This is the Routes Marker Component*/}
                        {this.state.currentDay == null
                            ? days.map((day) => {
                                  if (day.routes.length > 0) {
                                      return day.routes.map((route) => (
                                          <Marker
                                              //   onClick={this.onMarkerClick}
                                              onMouseover={(props, marker) =>
                                                  this.onMarkerHover(
                                                      props,
                                                      marker
                                                  )
                                              }
                                              onMouseout={() =>
                                                  this.onMarkerOut()
                                              }
                                              title={
                                                  route.variant || route.mode
                                              }
                                              name={
                                                  <div>
                                                      <h2 style={{ margin: 0 }}>
                                                          {
                                                              this._generateRouteLabelData(
                                                                  route.variant ||
                                                                      route.mode
                                                              ).name
                                                          }
                                                      </h2>
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showDistance &&
                                                      !isNaN(route.distance) ? (
                                                          <p
                                                              style={{
                                                                  fontWeight:
                                                                      "bold",
                                                              }}
                                                          >
                                                              Distance:{" "}
                                                              {(
                                                                  route.distance /
                                                                  1000
                                                              ).toFixed(1)}{" "}
                                                              km
                                                          </p>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showDuration &&
                                                      route.duration ? (
                                                          <p
                                                              style={{
                                                                  fontWeight:
                                                                      "bold",
                                                              }}
                                                          >
                                                              Duration:{" "}
                                                              {formatDuration(
                                                                  route.duration
                                                              )}{" "}
                                                              {formatDuration(
                                                                  route.duration
                                                              ).length < 3
                                                                  ? "mins"
                                                                  : "hours"}
                                                          </p>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showStartEndTime &&
                                                      route.start_time ? (
                                                          <>
                                                              <p
                                                                  style={{
                                                                      fontWeight:
                                                                          "bold",
                                                                  }}
                                                              >
                                                                  Start Time:{" "}
                                                                  {
                                                                      route.start_time
                                                                  }
                                                              </p>
                                                              <p
                                                                  style={{
                                                                      fontWeight:
                                                                          "bold",
                                                                  }}
                                                              >
                                                                  End Time:{" "}
                                                                  {
                                                                      route.end_time
                                                                  }
                                                              </p>
                                                          </>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {/* <p style={{ fontWeight: "bold" }}>
                                                  Distance:{" "}
                                                  {(
                                                      route.distance / 1000
                                                  ).toFixed(1)}{" "}
                                                  km
                                              </p> */}
                                                      {/* <p style={{ fontWeight: "bold" }}>
                                                  Duration: {route.duration}{" "}
                                                  hours
                                              </p> */}
                                                  </div>
                                              }
                                              position={
                                                  route.route.length == 2
                                                      ? calcCenter(route.route)
                                                      : route.route[
                                                            Math.round(
                                                                route.route
                                                                    .length / 2
                                                            )
                                                        ]
                                              }
                                              icon={{
                                                  url: `/mapIcons/transferTypeIcons/${(
                                                      route.variant ||
                                                      route.mode
                                                  ).toLowerCase()}.png`,
                                                  anchor: new this.props.google.maps.Point(
                                                      16,
                                                      16
                                                  ),
                                                  scaledSize:
                                                      new this.props.google.maps.Size(
                                                          32,
                                                          32
                                                      ),
                                              }}
                                          />
                                      ));
                                  }
                              })
                            : days[this.state.currentDay].routes.map(
                                  (route) => {
                                      return (
                                          <Marker
                                              //  onClick={this.onMarkerClick}
                                              onMouseover={(props, marker) =>
                                                  this.onMarkerHover(
                                                      props,
                                                      marker
                                                  )
                                              }
                                              onMouseout={() =>
                                                  this.onMarkerOut()
                                              }
                                              name={
                                                  <div>
                                                      <h2 style={{ margin: 0 }}>
                                                          {
                                                              this._generateRouteLabelData(
                                                                  route.variant ||
                                                                      route.mode
                                                              ).name
                                                          }
                                                      </h2>
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showDistance &&
                                                      !isNaN(route.distance) ? (
                                                          <p
                                                              style={{
                                                                  fontWeight:
                                                                      "bold",
                                                              }}
                                                          >
                                                              Distance:{" "}
                                                              {(
                                                                  route.distance /
                                                                  1000
                                                              ).toFixed(1)}{" "}
                                                              km
                                                          </p>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showDuration &&
                                                      route.duration ? (
                                                          <p
                                                              style={{
                                                                  fontWeight:
                                                                      "bold",
                                                              }}
                                                          >
                                                              Duration:{" "}
                                                              {formatDuration(
                                                                  route.duration
                                                              )}{" "}
                                                              {formatDuration(
                                                                  route.duration
                                                              ).length < 3
                                                                  ? "mins"
                                                                  : "hours"}
                                                          </p>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {this._generateRouteLabelData(
                                                          route.variant ||
                                                              route.mode
                                                      ).showStartEndTime &&
                                                      route.start_time ? (
                                                          <>
                                                              <p
                                                                  style={{
                                                                      fontWeight:
                                                                          "bold",
                                                                  }}
                                                              >
                                                                  Start Time:{" "}
                                                                  {
                                                                      route.start_time
                                                                  }
                                                              </p>
                                                              <p
                                                                  style={{
                                                                      fontWeight:
                                                                          "bold",
                                                                  }}
                                                              >
                                                                  End Time:{" "}
                                                                  {
                                                                      route.end_time
                                                                  }
                                                              </p>
                                                          </>
                                                      ) : (
                                                          <></>
                                                      )}
                                                      {/* <p style={{ fontWeight: "bold" }}>
                                                  Distance:{" "}
                                                  {(
                                                      route.distance / 1000
                                                  ).toFixed(1)}{" "}
                                                  km
                                              </p> */}
                                                      {/* <p style={{ fontWeight: "bold" }}>
                                                  Duration: {route.duration}{" "}
                                                  hours
                                              </p> */}
                                                  </div>
                                              }
                                              position={
                                                  route.route.length == 2
                                                      ? calcCenter(route.route)
                                                      : route.route[
                                                            Math.round(
                                                                route.route
                                                                    .length / 2
                                                            )
                                                        ]
                                              }
                                              icon={{
                                                  url: `/mapIcons/transferTypeIcons/${(
                                                      route.variant ||
                                                      route.mode
                                                  ).toLowerCase()}.png`,
                                                  anchor: new this.props.google.maps.Point(
                                                      16,
                                                      16
                                                  ),
                                                  scaledSize:
                                                      new this.props.google.maps.Size(
                                                          32,
                                                          32
                                                      ),
                                              }}
                                          />
                                      );
                                  }
                              )}

                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                        >
                            {this.state.selectedPlace.name}
                        </InfoWindow>
                    </Map>
                </div>
                {this.props.bySea ? (
                    <></>
                ) : (
                    <Grid container style={{ margin: "0.5rem 0" }} spacing={2}>
                        <Grid item xs={12}>
                            {this.state.currentDay == null ? (
                                <>
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <h3 style={mapTitleStyles}>
                                            Trip Overview
                                        </h3>
                                    </div>
                                </>
                            ) : (
                                <div
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    <h3 style={mapTitleStyles}>
                                        {this.state.currentDay ===
                                        days.length - 1 ? (
                                            <span>End of Itinerary</span>
                                        ) : (
                                            <>
                                                <span>
                                                    Day
                                                    {days[this.state.currentDay]
                                                        .end_day
                                                        ? "s"
                                                        : ""}{" "}
                                                    {
                                                        days[
                                                            this.state
                                                                .currentDay
                                                        ].start_day
                                                    }
                                                </span>
                                                {days[this.state.currentDay]
                                                    .end_day ? (
                                                    <span>
                                                        {" "}
                                                        -{" "}
                                                        {
                                                            days[
                                                                this.state
                                                                    .currentDay
                                                            ].end_day
                                                        }
                                                    </span>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </h3>
                                </div>
                            )}
                        </Grid>

                        <>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                }}
                            >
                                {this.state.currentDay == null ? (
                                    <></>
                                ) : this.state.currentDay > 0 ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{ width: "100%" }}
                                            onClick={() =>
                                                this.decrementCurrentDay()
                                            }
                                        >
                                            {this.props.mobileWidth
                                                ? "<"
                                                : "< Previous Day"}
                                        </Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => this.toggleMapMode()}
                                >
                                    {this.state.currentDay == null
                                        ? "Day By Day"
                                        : "Overview"}
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "end",
                                }}
                            >
                                {this.state.currentDay == null ? (
                                    <></>
                                ) : this.state.currentDay <
                                  this._buildDayMapItemsArray(
                                      this.props.itinerary
                                  ).length -
                                      1 ? (
                                    <div
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            style={{ width: "100%" }}
                                            onClick={() =>
                                                this.incrementCurrentDay()
                                            }
                                        >
                                            {this.props.mobileWidth
                                                ? ">"
                                                : "Next Day >"}
                                        </Button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </>
                    </Grid>
                )}
            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyCtkouLiSsz7KGwcOaQjCGWJF4qSgYxC-c",
})(ItineraryMap);

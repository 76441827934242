import React from "react";

//Import CSS Styles
import "../../theme/css/loading.css";

const Loading = () => {
    return (
        <div
            style={{
                position: "relative",
                zIndex: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100vh",
            }}
        >
            <div style={{ width: "300px" }} className="logo">
                <img
                    src={"/BnR-Logo-Icon-Black.svg"}
                    style={{ width: "100%" }}
                />
                <p style={{ marginTop: 0, textAlign: "center" }}>Loading...</p>
            </div>
        </div>
    );
};

export default Loading;

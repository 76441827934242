//React and Material-UI Imports
import React, { useReducer, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

//Import Reducers
import { startPlanningFormReducer } from "../formReducers/StartPlanningFormReducer";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    container: {
        maxWidth: "960px",
        margin: "auto",
        textAlign: "left",
        padding: "30px",
    },
    title: {
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: 300,
        textAlign: "center",
        textTransform: "uppercase",
        color: "#DBAA79",
    },
    travellerInformation: {
        display: "flex",
        fontSize: "16px",
        alignItems: "flex-end",
    },
    startPlanningForm: {
        "& p": {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
        },
    },
}));

export default function StartPlanningForm(props) {
    const {
        formHeading,
        formSubmissionObject,
        implicitUpdateFormSubmissionObject,
        explicitUpdateFormSubmissionObject,
        handleSubmit,
        submitted,
    } = props;

    var today = new Date();

    const initialState = {
        dailyBudget: 0,
        travelDate: today,
        travelHistory: "",
        newsletterSubscription: "",
        travelAgent: "",
        contactMethod: "",
    };

    const [startPlanningFormInfo, dispatchStartPlanningFormInfo] = useReducer(
        startPlanningFormReducer,
        initialState
    );

    const handleDateChange = (date) => {
        dispatchStartPlanningFormInfo({
            type: "SET_TRAVEL_DATE_INFO",
            payload: date,
        });
    };

    //Pull in styles
    const classes = useStyles();

    //Fetch Page Data
    useEffect(() => {
        explicitUpdateFormSubmissionObject({
            // inquirySubject: "Bespoke Start Planning Request - " + formHeading,
            inquirySubject: "Start Planning Request - " + formHeading,
        });
    }, []);

    //Render
    return submitted ? (
        //Render form confirmation message
        <div className={classes.startPlanningForm + " start-planning-form"}>
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "35px 30px 50px 30px",
                }}
            >
                <div style={{ maxWidth: "735px", margin: "0 auto" }}>
                    <h3
                        style={{
                            fontFamily: "Lora, serif",
                            fontWeight: 300,
                            fontSize: "22px",
                            color: "#494949",
                            textTransform: "uppercase",
                            textAlign: "center",
                        }}
                    >
                        Thank you for your message!
                    </h3>
                    <p style={{ color: "#494949", textAlign: "center" }}>
                        One of our team members will get back to you shortly.
                    </p>
                </div>
            </div>
        </div>
    ) : (
        //Render form
        <div className={classes.startPlanningForm + " start-planning-form"}>
            <div
                style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "35px 30px 50px 30px",
                }}
            >
                <div style={{ maxWidth: "735px", margin: "0 auto" }}>
                    <h1 className={classes.title}>
                        {formHeading !== "" || null
                            ? "Start Planning: " + formHeading
                            : "Let's Start Planning!"}
                    </h1>
                    <p style={{ textAlign: "center", marginBottom: "40px" }}>
                        At Butterfield & Robinson our goal is to take your
                        vision and make it a reality. Fill out the form below to
                        get started and one of our expert Trip Designers will
                        reach out and start designing your trip.
                    </p>
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {formHeading ? (
                            ""
                        ) : (
                            <div
                                style={{
                                    padding: "25px 0px",
                                    maxWidth: "280px",
                                    margin: "auto",
                                }}
                            >
                                <p
                                    style={{
                                        width: "100%",
                                        margin: 0,
                                        textAlign: "center",
                                    }}
                                >
                                    Where do you want to travel?
                                </p>
                                <TextField
                                    style={{ width: "100%", border: "none" }}
                                    id="destinationofInterest"
                                    name="destinationofInterest"
                                    value={
                                        formSubmissionObject[
                                            "destinationofInterest"
                                        ]
                                    }
                                    onChange={(event) => {
                                        explicitUpdateFormSubmissionObject({
                                            inquirySubject:
                                                // "General Start Planning Request - " +
                                                "Start Planning Request - " +
                                                event.target.value,
                                            destinationofInterest:
                                                event.target.value,
                                        });
                                    }}
                                />
                            </div>
                        )}

                        <div
                            style={{
                                padding: "25px 0px",
                                maxWidth: "280px",
                                margin: "auto",
                            }}
                        >
                            <p
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    textAlign: "center",
                                }}
                            >
                                How many people in your group?
                            </p>
                            <TextField
                                style={{ width: "100%", border: "none" }}
                                id="flexFieldGroupSize"
                                name="flexFieldGroupSize"
                                value={
                                    formSubmissionObject["flexFieldGroupSize"]
                                }
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                        </div>
                        <div
                            style={{
                                padding: "25px 0px",
                                maxWidth: "280px",
                                margin: "auto",
                            }}
                        >
                            <p
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    textAlign: "center",
                                }}
                            >
                                What's your daily budget per person?
                            </p>
                            <FormControl
                                className={classes.formControl}
                                style={{ margin: 0, minWidth: "280px" }}
                            >
                                <Select
                                    value={startPlanningFormInfo.dailyBudget}
                                    name="budget"
                                    onChange={(event) => {
                                        dispatchStartPlanningFormInfo({
                                            type: "SET_DAILY_BUDGET_INFO",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    displayEmpty
                                    className={classes.selectEmpty}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                >
                                    <MenuItem value={"$500 - $1000"}>
                                        $500 - $1000
                                    </MenuItem>
                                    <MenuItem value={"$1000 - $2000"}>
                                        $1000 - $2000
                                    </MenuItem>
                                    <MenuItem value={"$2000 - $3000"}>
                                        $2000 - $3000
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                padding: "25px 0px",
                                maxWidth: "280px",
                                margin: "auto",
                            }}
                        >
                            <p
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    textAlign: "center",
                                }}
                            >
                                When do you want to travel?
                            </p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="travel-date"
                                    name="flexFieldDepartureDate"
                                    format="MM/dd/yyyy"
                                    value={startPlanningFormInfo.travelDate}
                                    onChange={(date) => {
                                        handleDateChange(date);
                                        explicitUpdateFormSubmissionObject({
                                            flexFieldDepartureDate: date.toLocaleDateString(
                                                "en-US",
                                                {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                }
                                            ),
                                        });
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div
                            style={{
                                padding: "25px 0px",
                                maxWidth: "500px",
                                margin: "auto",
                            }}
                        >
                            <p
                                style={{
                                    width: "100%",
                                    margin: 0,
                                    textAlign: "center",
                                }}
                            >
                                Tell us about your perfect trip (remember to
                                reach for the stars).
                            </p>
                            <TextField
                                id="tell-us-about-your-perfect-trip"
                                name="additionalComments"
                                multiline
                                variant="outlined"
                                rows={5}
                                style={{
                                    width: "100%",
                                    padding: "20px",
                                }}
                                onChange={implicitUpdateFormSubmissionObject}
                            />
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p style={{ width: "100%", margin: 0 }}>
                                    First Name
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="first-name"
                                    name="FirstName"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p style={{ width: "100%", margin: 0 }}>
                                    Last Name
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="last-name"
                                    name="LastName"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p style={{ width: "100%", margin: 0 }}>
                                    Email
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    style={{ width: "100%" }}
                                    id="Email"
                                    name="Email"
                                    value={formSubmissionObject["Email"]}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p style={{ width: "100%", margin: 0 }}>
                                    Phone number
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="phone-number"
                                    name="Phone"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className={classes.travellerInformation}
                            style={{
                                maxWidth: "350px",
                                margin: "auto",
                                padding: "5px 0px",
                            }}
                        >
                            <div style={{ width: "40%" }}>
                                <p style={{ width: "100%", margin: 0 }}>
                                    Zip/Postal Code
                                </p>
                            </div>
                            <div style={{ width: "60%" }}>
                                <TextField
                                    id="postal-code"
                                    name="PostalCode"
                                    style={{ width: "100%" }}
                                    onChange={
                                        implicitUpdateFormSubmissionObject
                                    }
                                />
                            </div>
                        </div>

                        <div
                            className={"additional-traveller-info"}
                            style={{ padding: "45px 0px" }}
                        >
                            <FormControl component="fieldset">
                                <FormLabel
                                    component="legend"
                                    style={{ color: "#494949" }}
                                >
                                    Have you travelled with us before?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="travel-history"
                                    name="previousTraveler"
                                    value={startPlanningFormInfo.travelHistory}
                                    onChange={(event) => {
                                        dispatchStartPlanningFormInfo({
                                            type: "SET_TRAVEL_HISTORY_INFO",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    style={{
                                        color: "#494949",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Yes"
                                        style={{ color: "#494949" }}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="No"
                                        style={{ color: "#494949" }}
                                    />
                                </RadioGroup>

                                <FormLabel
                                    component="legend"
                                    style={{
                                        color: "#494949",
                                        marginTop: "15px",
                                        lineHeight: "1.6",
                                    }}
                                >
                                    Sign up for our emails to receive B&R news,
                                    brand new trip launches, and content from
                                    our award-winning blog.
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="newsletter-subscription"
                                    name="emailOptIn"
                                    value={
                                        startPlanningFormInfo.newsletterSubscription
                                    }
                                    onChange={(event) => {
                                        dispatchStartPlanningFormInfo({
                                            type:
                                                "SET_NEWSLETTER_SUBSCRIPTION_INFO",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    style={{
                                        color: "#494949",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Yes"
                                        style={{ color: "#494949" }}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="No"
                                        style={{ color: "#494949" }}
                                    />
                                </RadioGroup>

                                <FormLabel
                                    component="legend"
                                    style={{
                                        color: "#494949",
                                        marginTop: "15px",
                                    }}
                                >
                                    Are you working with a travel agent?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="travel-agent"
                                    name="travelAgent"
                                    value={startPlanningFormInfo.travelAgent}
                                    onChange={(event) => {
                                        dispatchStartPlanningFormInfo({
                                            type: "SET_TRAVEL_AGENT_INFO",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    style={{
                                        color: "#494949",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Yes"
                                        style={{ color: "#494949" }}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="No"
                                        style={{ color: "#494949" }}
                                    />
                                </RadioGroup>
                                <FormLabel
                                    component="legend"
                                    style={{
                                        color: "#494949",
                                        marginTop: "15px",
                                    }}
                                >
                                    How do you preferred to be contacted?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="contact-method"
                                    name="contactPreference"
                                    value={startPlanningFormInfo.contactMethod}
                                    onChange={(event) => {
                                        dispatchStartPlanningFormInfo({
                                            type: "SET_CONTACT_METHOD_INFO",
                                            payload: event.target.value,
                                        });
                                        implicitUpdateFormSubmissionObject(
                                            event
                                        );
                                    }}
                                    style={{
                                        color: "#494949",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FormControlLabel
                                        value="email"
                                        control={<Radio />}
                                        label="Email"
                                        style={{ color: "#494949" }}
                                    />
                                    <FormControlLabel
                                        value="phone"
                                        control={<Radio />}
                                        label="Phone"
                                        style={{ color: "#494949" }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div>
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "11.5px",
                                    marginBottom: "20px",
                                }}
                            >
                                By submitting this form, you agree to the
                                Butterfield & Robinson{" "}
                                <Link
                                    href="/terms-and-conditions"
                                    style={{ color: "#70B2C4" }}
                                >
                                    Terms
                                </Link>{" "}
                                and{" "}
                                <Link
                                    href="/privacy-policy"
                                    style={{ color: "#70B2C4" }}
                                >
                                    Privacy Policy
                                </Link>
                                .
                            </p>
                        </div>
                        <Button
                            style={{
                                backgroundColor: "#494949",
                                color: "#fff",
                                padding: "6px 20px",
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//React Slick Carousel Imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Carousel from "react-material-ui-carousel";
import EmblaTileCarousel from "../elements/EmblaTileCarousel";
import AccommadationCard from "../elements/AccommadationCard";

import { isValidDate } from "../../utilities/HelperFunctions/helperFunctions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    title: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        marginTop: 0,
        fontSize: "2rem",
    },
    text: {
        fontFamily: theme.typography.body1.fontFamily,
        "& p": {
            textAlign: "justify",
        },
    },
    introduction: {
        maxWidth: "980px",
        margin: "0 auto",
        overflow: "hidden",
    },
    rendezvousDropOffContainer: {
        maxWidth: "1000px",
        margin: "0 auto",
    },
    rendezvousDropOff: {
        display: "flex",
        textTransform: "uppercase",
        fontSize: "12px",
        position: "relative",
    },
    hotel1: {
        backgroundColor: "#194666",
        padding: "35px",
        height: "100%",
    },
    hotel2: {
        backgroundColor: "#70B2C4",
        padding: "35px",
        textTransform: "uppercase",
    },
    hotelImageContainer: {
        width: "100%",
        height: "35vh",
    },
    hotelImage: {
        objectFit: "cover",
        height: "100%",
        width: "100%",
    },
    text4: {
        fontFamily: theme.typography.body1.fontFamily,
        textAlign: "left",
        fontSize: "14px",
        color: "#fff",
        margin: 0,
        padding: 0,
        listStyle: "none",
    },
    title2: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "20px",
        color: "#fff",
        marginBottom: 0,
        fontWeight: 300,
    },
    hotelSuperTitle: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "16px",
        color: "#fff",
        fontStyle: "italic",
        margin: 0,
        fontWeight: 300,
    },
    hotelSubtitle: {
        fontFamily: theme.typography.h1.fontFamily,
        textAlign: "left",
        fontSize: "18px",
        color: "#6dafc1",
        fontStyle: "italic",
        marginTop: 0,
        fontWeight: 300,
    },
    hotelText: {
        textAlign: "justify",
        color: "#fafafa",
        "& p": {
            textAlign: "justify",
            color: "#fafafa",
        },
    },
    dailyBreakdownGallery: {
        height: "500px",
    },
    readMore: {
        "& p:nth-child(2)": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineHeight: "24px" /* fallback */,
            maxHeight: "72px" /* fallback */,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3,
        },
        "& p:nth-child(n+3)": {
            display: "none",
        },
    },
}));

function search(keyValue, key, array) {
    if (array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === keyValue) {
                return array[i];
            }
        }
    }
}

const ItineraryAccommadations = (props) => {
    const {
        itineraryHotelContent,
        itinerary,
        mobileWidth,
        isPrint,
        tripStart,
        addDays,
        isBooked,
    } = props;

    const [activeDialog, setActiveDialog] = useState(null);

    let legs = itinerary.legs;

    const buildHotelArray = () => {
        let hotels = new Array();
        legs.map((leg, i) => {
            if (leg.content_entity_id != 25862) {
                hotels.push({
                    hotel: search(
                        leg.content_entity_id,
                        "map_object_id",
                        itineraryHotelContent
                    ),
                    checkInDay: leg.periods[0].itinerary_start_day,
                    checkOutDay:
                        leg.periods[leg.periods.length - 1].itinerary_end_day,
                    nights: leg.nights,
                    rooms: leg.rooms,
                    bookingReference: leg.booking_reference,
                    tripStart,
                    type: leg.type,
                });
            }
        });
        return hotels;
    };

    let accommadationsArray = buildHotelArray();

    //Pull In Styles
    const classes = useStyles();

    const renderAccommadationPrintSlides = () =>
        itineraryHotelContent
            ? accommadationsArray.map((accommadation, i, arr) =>
                  accommadation.hotel.content.images.length > 0 ? (
                      <>
                          <Grid
                              container
                              style={
                                  isPrint
                                      ? { padding: "5px 2rem" }
                                      : mobileWidth
                                      ? {}
                                      : { height: "550px" }
                              }
                          >
                              <Grid
                                  item
                                  xs={isPrint ? 4 : 12}
                                  md={isPrint ? 4 : 6}
                                  style={
                                      isPrint
                                          ? {}
                                          : mobileWidth
                                          ? { height: "450px" }
                                          : { height: "100%" }
                                  }
                              >
                                  <img
                                      style={
                                          isPrint
                                              ? {}
                                              : mobileWidth
                                              ? { height: "450px" }
                                              : {}
                                      }
                                      src={`https://wetu.com/ImageHandler/c500x550/${accommadation.hotel.content.images[0].url_fragment}`}
                                      className={classes.hotelImage}
                                  />
                              </Grid>
                              <Grid
                                  item
                                  xs={isPrint ? 8 : 12}
                                  md={isPrint ? 8 : 6}
                                  style={
                                      isPrint
                                          ? { minHeight: "350px" }
                                          : mobileWidth
                                          ? { height: "400px" }
                                          : { height: "100%" }
                                  }
                              >
                                  <div
                                      className={classes.hotel1}
                                      style={
                                          isPrint
                                              ? {
                                                    padding: "0px 35px",
                                                    height: "100%",
                                                }
                                              : {}
                                      }
                                  >
                                      <h4 className={classes.hotelSuperTitle}>
                                          {accommadation.type ===
                                          "OwnArrangement" ? (
                                              <>Own Arrangements</>
                                          ) : (
                                              <></>
                                          )}
                                      </h4>
                                      <h3
                                          className={classes.title2}
                                          style={
                                              isPrint
                                                  ? { padding: 0, marginTop: 0 }
                                                  : {}
                                          }
                                      >
                                          {accommadation.hotel.name}
                                      </h3>
                                      <h4 className={classes.hotelSubtitle}>
                                          {accommadation.hotel.position ? (
                                              <>
                                                  {
                                                      accommadation.hotel
                                                          .position.destination
                                                  }
                                                  ,{" "}
                                                  {
                                                      accommadation.hotel
                                                          .position.country
                                                  }
                                              </>
                                          ) : (
                                              <></>
                                          )}
                                      </h4>
                                      <div
                                          className={classes.text4}
                                          dangerouslySetInnerHTML={{
                                              __html: accommadation.hotel
                                                  .content.general_description,
                                          }}
                                      ></div>
                                      {accommadation.rooms ? (
                                          <>
                                              {accommadation.rooms.map(
                                                  (room) => (
                                                      <>
                                                          {room.rooms > 1 ? (
                                                              <h3
                                                                  className={
                                                                      classes.title2
                                                                  }
                                                                  style={{
                                                                      fontSize:
                                                                          isPrint
                                                                              ? "12px"
                                                                              : "18px",
                                                                      fontStyle:
                                                                          "italic",
                                                                      margin: 0,
                                                                  }}
                                                              >
                                                                  {room.rooms}x{" "}
                                                                  {room.name}
                                                              </h3>
                                                          ) : (
                                                              <h3
                                                                  className={
                                                                      classes.title2
                                                                  }
                                                                  style={{
                                                                      fontSize:
                                                                          isPrint
                                                                              ? "12px"
                                                                              : "18px",
                                                                      fontStyle:
                                                                          "italic",
                                                                      margin: 0,
                                                                  }}
                                                              >
                                                                  {room.name}
                                                              </h3>
                                                          )}
                                                      </>
                                                  )
                                              )}
                                          </>
                                      ) : (
                                          <></>
                                      )}
                                      <p
                                          className={classes.hotelText}
                                          style={{
                                              marginTop: 12,
                                              marginBottom: 0,
                                              fontStyle: "italic",
                                          }}
                                      >
                                          {accommadation.nights > 1
                                              ? `${accommadation.nights} Nights`
                                              : `${accommadation.nights} Night`}
                                      </p>
                                      {isBooked ? (
                                          isValidDate(
                                              accommadation.tripStart
                                          ) ? (
                                              <p
                                                  className={classes.hotelText}
                                                  style={{ marginTop: 0 }}
                                              >
                                                  Check in:{" "}
                                                  {new Intl.DateTimeFormat(
                                                      "en-US",
                                                      {
                                                          // weekday: "long",
                                                          // year: "numeric",
                                                          month: "short",
                                                          day: "numeric",
                                                          timeZone: "UTC",
                                                      }
                                                  ).format(
                                                      addDays(
                                                          accommadation.tripStart,
                                                          accommadation.checkInDay
                                                      )
                                                  )}{" "}
                                                  | Check out:{" "}
                                                  {new Intl.DateTimeFormat(
                                                      "en-US",
                                                      {
                                                          // weekday: "long",
                                                          // year: "numeric",
                                                          month: "short",
                                                          day: "numeric",
                                                          timeZone: "UTC",
                                                      }
                                                  ).format(
                                                      addDays(
                                                          addDays(
                                                              accommadation.tripStart,
                                                              accommadation.checkInDay
                                                          ),
                                                          accommadation.nights
                                                      )
                                                  )}
                                              </p>
                                          ) : (
                                              <p
                                                  className={classes.hotelText}
                                                  style={{ marginBottom: 0 }}
                                              >
                                                  {accommadation.checkOutDay >
                                                  accommadation.checkInDay
                                                      ? "Nights " +
                                                        (accommadation.checkInDay +
                                                            1) +
                                                        " - " +
                                                        (accommadation.checkInDay +
                                                            accommadation.nights)
                                                      : "Night " +
                                                        (accommadation.checkInDay +
                                                            1)}
                                              </p>
                                          )
                                      ) : (
                                          <p
                                              className={classes.hotelText}
                                              style={{ marginBottom: 0 }}
                                          >
                                              {accommadation.checkOutDay >
                                              accommadation.checkInDay
                                                  ? "Nights " +
                                                    (accommadation.checkInDay +
                                                        1) +
                                                    " - " +
                                                    (accommadation.checkInDay +
                                                        accommadation.nights)
                                                  : "Night " +
                                                    (accommadation.checkInDay +
                                                        1)}
                                          </p>
                                      )}

                                      {/* <p className={classes.hotelText}>
                                    Check Out:{" "}
                                    {new Intl.DateTimeFormat("en-US", {
                                        // weekday: "long",
                                        // year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                        timeZone: "UTC",
                                    }).format(checkOutDay)}
                                </p> */}
                                      {accommadation.bookingReference ? (
                                          <p className={classes.hotelText}>
                                              Reference:{" "}
                                              {accommadation.bookingReference}
                                          </p>
                                      ) : (
                                          <></>
                                      )}
                                  </div>
                              </Grid>
                          </Grid>
                          {(i + 1) % 2 == 0 || i + 1 == arr.length ? (
                              <div style={{ pageBreakAfter: "always" }}></div>
                          ) : (
                              <></>
                          )}
                      </>
                  ) : (
                      ""
                  )
              )
            : "";

    let accommadationsArrayNew = buildHotelArray();

    const renderAccommodationSlidesNEW = () => {
        return accommadationsArrayNew.map((accommadation, index, arr) => (
            <AccommadationCard
                accommadation={accommadation}
                activeDialog={activeDialog}
                setActiveDialog={setActiveDialog}
                index={index}
                length={arr.length}
                addDays={addDays}
                isBooked={isBooked}
            />
        ));
    };

    return (
        <div
            className={"hotels-container"}
            style={{ maxWidth: "1000px", margin: "0 auto" }}
        >
            <div className={classes.root}>
                <div className={classes.accommodationsContainer}>
                    <div style={{ paddingBottom: "2rem" }}>
                        <h3
                            className={classes.title}
                            style={{
                                color: "#194666",
                                borderBottom: "1px solid #494949",
                                maxWidth: "85%",
                                margin: "0 auto",
                                paddingBottom: isPrint ? "0" : "2rem",
                                textAlign: "center",
                                fontSize: isPrint ? "1.2rem" : "",
                            }}
                            id="accommodations"
                        >
                            Your Accommodations
                        </h3>
                    </div>
                    {isPrint ? (
                        <div>{renderAccommadationPrintSlides()}</div>
                    ) : (
                        <EmblaTileCarousel
                            slides={renderAccommodationSlidesNEW()}
                        />
                    )}
                    {/* <Slider
                        dots={true}
                        autoplay={false}
                        autoplaySpeed={5000}
                        arrows={false}
                    >
                        {renderAccommadationSlides()}
                    </Slider> */}
                </div>
            </div>
        </div>
    );
};

export default ItineraryAccommadations;

ItineraryAccommadations.propTypes = {
    itineraryHotelContent: PropTypes.object.isRequired,
    itinerary: PropTypes.object.isRequired,
};

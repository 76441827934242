import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

//NOTE: Added forwardRef() wrapper as per Next JS Docs:
//      https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component

const BrButton = React.forwardRef(
    ({ buttonText, onClick, color, className }, ref) => {
        //const { buttonText, onClick, color } = props;

        const BrButton = withStyles((theme) => ({
            root: {
                margin: "0",
                padding: "5px 16px",
                fontSize: theme.typography.button.fontSize,
                fontFamily: theme.typography.button.fontFamily,
                color: theme.palette[color].contrastText,
                backgroundColor: theme.palette[color].main,
                "&:hover": {
                    color: theme.palette[color].contrastText,
                    backgroundColor: theme.palette[color].light,
                },
            },
        }))(Button);

        function renderBrButton(color) {
            if (color) {
                return (
                    <BrButton className={className} onClick={onClick}>
                        {buttonText}
                    </BrButton>
                );
            } else {
                return (
                    <Button
                        className={className}
                        variant="contained"
                        onClick={onClick}
                    >
                        {buttonText}
                    </Button>
                );
            }
        }

        return renderBrButton(color);
    }
);

export default BrButton;

import { createMuiTheme } from "@material-ui/core/styles";

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        lightBlue: {
            light: "#4e97cd",
            main: "#3E739B",
            dark: "#316083",
            contrastText: "#fafafa",
        },
        darkBlue: {
            light: "#234f6f",
            main: "#194666",
            dark: "#0e344e",
            contrastText: "#fafafa",
        },
        lightRed: {
            light: "#c36f6b",
            main: "#C4635F",
            dark: "#a54743",
            contrastText: "#fafafa",
        },
        bespokeOrange: {
            light: "#d8af85",
            main: "#DBAA79",
            dark: "#ce9152",
            contrastText: "#fafafa",
        },
        smallGroupGreen: {
            light: "#b4ccad",
            main: "#96AF8E",
            dark: "#768e6f",
            contrastText: "#fafafa",
        },
        error: {
            main: "#dc3545",
        },
        background: {
            default: "#fff",
        },
    },
    typography: {
        h1: {
            fontFamily: "Lora, serif",
            fontSize: "45px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h2: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "45px",
            color: "#194666",
        },
        h3: {
            fontFamily: "Lora, serif",
            fontSize: "35px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h4: {
            fontFamily: "Lora, serif",
            fontSize: "30px",
            color: "#494949",
            textTransform: "uppercase",
        },
        h5: {
            fontFamily: "Rock Salt, sans-serif",
            fontSize: "30px",
            color: "#494949",
        },
        body1: {
            fontFamily: "Muli, sans-serif",
            color: "#494949",
            fontSize: "16px",
        },
        button: {
            fontFamily: "Lora, serif",
            fontSize: "15px",
        },
    },
});

export default theme;

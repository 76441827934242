import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import BrBlogSlider from "../elements/BrBlogSlider";

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: "1000px",
        margin: "auto",
        textAlign: "left",
        padding: "30px 20px 20px 20px",
    },
    sectionTitle: {
        fontFamily: theme.typography.h2.fontFamily,
        fontWeight: 500,
        textTransform: "lowercase",
        marginTop: 0,
        fontSize: "2rem",
    },
    title: {
        textAlign: "left",
        textTransform: "uppercase",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "24px",
        fontWeight: 300,
        margin: 0,
    },
    countryDetailsTitle: {
        textAlign: "left",
        textTransform: "uppercase",
        fontFamily: theme.typography.h3.fontFamily,
        color: "#194666",
        fontSize: "18px",
        fontWeight: 300,
        margin: 0,
    },
    text: {
        textAlign: "left",
        fontFamily: theme.typography.body1.fontFamily,
        marginBottom: 0,
        fontSize: "14px",
    },
    details: {
        border: "1px solid #494949",
        padding: "30px",
        margin: "30px 0",
        "& ul": {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: "16px",
        },
    },
    localInformationPanel: {
        boxShadow: "none",
    },
    guidePhoto: {
        objectFit: "cover",
        height: "100%",
        width: "100%",
    },
    guideName: {
        color: "#194666",
        margin: "0 0 0.5rem 0",
        textTransform: "uppercase",
        fontFamily: "Lora, serif",
        fontWeight: 300,
    },
    guideBio: {
        margin: "0",
        textAlign: "justify",
    },
}));

const PreTripInformation = ({
    isBooked,
    otpContent,
    departureContent,
    itineraryCountryContent,
    isOtpComplete,
}) => {
    //Pull In Styles
    const classes = useStyles();

    const lessThan30DaysToStart = (tripStartDate) => {
        let now = new Date();
        tripStartDate = new Date(tripStartDate);

        let differenceInTime = tripStartDate.getTime() - now.getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);

        if (differenceInDays < 45) {
            return true;
        } else {
            return false;
        }
    };

    const isWithin30Days = lessThan30DaysToStart(
        departureContent?.departures[0]?.p15_startdate
    );

    const renderCountryInformation = () => {
        return (
            <>
                {itineraryCountryContent.map((countryContent) => {
                    return (
                        <div>
                            <Typography
                                className={classes.title}
                                style={{
                                    color: "#194666",
                                    // borderBottom: "1px solid #494949",
                                    maxWidth: "600px",
                                    margin: "auto",
                                    paddingBottom: "10px",
                                    textAlign: "center",
                                    fontSize: "24px",
                                }}
                            >
                                Tips for Travelling in {countryContent.name}
                            </Typography>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Banking & Currency
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .banking,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Travel, Transportation And Getting
                                            Around
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .transport,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Food & Drink
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .cuisine,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Clothing & Dress Recommendations
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .dress,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Climate & Weather
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .climate,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            <div className={classes.details}>
                                <ExpansionPanel
                                    className={classes.localInformationPanel}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<AddIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography
                                            className={
                                                classes.countryDetailsTitle
                                            }
                                        >
                                            Electricity & Plugs
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Typography>
                                            <div
                                                className={
                                                    classes.destinationDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: countryContent
                                                        .travel_information
                                                        .electricity,
                                                }}
                                            ></div>
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                            {countryContent.travel_information
                                .additional_info ? (
                                <div className={classes.details}>
                                    <ExpansionPanel
                                        className={
                                            classes.localInformationPanel
                                        }
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={
                                                    classes.countryDetailsTitle
                                                }
                                            >
                                                General Information
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={
                                                        classes.destinationDescription
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent
                                                            .travel_information
                                                            .additional_info,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.health ? (
                                <div className={classes.details}>
                                    <ExpansionPanel
                                        className={
                                            classes.localInformationPanel
                                        }
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={
                                                    classes.countryDetailsTitle
                                                }
                                            >
                                                Health
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={
                                                        classes.destinationDescription
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent
                                                            .travel_information
                                                            .health,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.visa ? (
                                <div className={classes.details}>
                                    <ExpansionPanel
                                        className={
                                            classes.localInformationPanel
                                        }
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={
                                                    classes.countryDetailsTitle
                                                }
                                            >
                                                Entry Requirements
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={
                                                        classes.destinationDescription
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent
                                                            .travel_information
                                                            .visa,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                            {countryContent.travel_information.safety ? (
                                <div className={classes.details}>
                                    <ExpansionPanel
                                        className={
                                            classes.localInformationPanel
                                        }
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<AddIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                className={
                                                    classes.countryDetailsTitle
                                                }
                                            >
                                                Safety
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Typography>
                                                <div
                                                    className={
                                                        classes.destinationDescription
                                                    }
                                                    dangerouslySetInnerHTML={{
                                                        __html: countryContent
                                                            .travel_information
                                                            .safety,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className={classes.container}>
            {isBooked && isOtpComplete ? (
                <>
                    <h3
                        className={classes.sectionTitle}
                        style={{
                            color: "#194666",
                            borderBottom: "1px solid #494949",
                            maxWidth: "85%",
                            margin: "0 auto",
                            paddingBottom: "2rem",
                            textAlign: "center",
                            pageBreakBefore: "always",
                        }}
                        id="prepare"
                    >
                        Prepare For Your Trip
                    </h3>
                    <p style={{ textAlign: "center" }}>
                        <em>
                            We want to ensure you feel ready for your trip.
                            Below are answers to common questions, but please
                            reach out to your Travel Advisor if you require more
                            information.
                        </em>
                    </p>
                    {/* <Typography
                        className={classes.title}
                        style={{
                            color: "#194666",
                            // borderBottom: "1px solid #494949",
                            maxWidth: "600px",
                            margin: "auto",
                            paddingBottom: "10px",
                            textAlign: "center",
                            fontSize: "24px",
                        }}
                    >
                        Useful Information
                    </Typography> */}
                    {/* {otpContent.guides && isWithin30Days ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Your Guides
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={3}>
                                        {otpContent.guides.map((guide) => {
                                            return (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={5}
                                                        md={4}
                                                    >
                                                        <img
                                                            className={
                                                                classes.guidePhoto
                                                            }
                                                            src={guide.photo}
                                                            alt=""
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={7}
                                                        md={8}
                                                    >
                                                        <h2
                                                            className={
                                                                classes.guideName
                                                            }
                                                        >
                                                            {
                                                                guide.preferredName
                                                            }
                                                        </h2>
                                                        <p
                                                            className={
                                                                classes.guideBio
                                                            }
                                                        >
                                                            {guide.bio}
                                                        </p>
                                                    </Grid>
                                                </>
                                            );
                                        })}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )}
                    {departureContent?.departures[0]?.br_emergencycontactname &&
                    departureContent?.departures[0]
                        ?.br_emergencycontactnumber ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Emergency Contact Information
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div>
                                        <Typography>
                                            <strong>Point of Contact: </strong>
                                            {
                                                departureContent.departures[0]
                                                    .br_emergencycontactname
                                            }
                                        </Typography>
                                        <Typography>
                                            <strong>Contact Number: </strong>
                                            {
                                                departureContent.departures[0]
                                                    .br_emergencycontactnumber
                                            }
                                        </Typography>
                                    </div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )} */}
                    {!otpContent.otp.packingList.hidden_in_mybnr ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        What To Pack
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div
                                        className={
                                            classes.destinationDescription
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: otpContent.otp.packingList.content.replace(
                                                new RegExp("<p><br></p>", "g"),
                                                ""
                                            ),
                                        }}
                                    ></div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )}
                    {!otpContent.otp.gettingThere.hidden_in_mybnr ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Getting There
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div
                                        className={
                                            classes.destinationDescription
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: otpContent.otp.gettingThere.content.replace(
                                                new RegExp("<p><br></p>", "g"),
                                                ""
                                            ),
                                        }}
                                    ></div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )}
                    {!otpContent.otp.gettingInShape.hidden_in_mybnr ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Getting In Shape
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div
                                        className={
                                            classes.destinationDescription
                                        }
                                        dangerouslySetInnerHTML={{
                                            __html: otpContent.otp.gettingInShape.content.replace(
                                                new RegExp("<p><br></p>", "g"),
                                                ""
                                            ),
                                        }}
                                    ></div>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )}
                    {/* {departureContent?.departures[0]?.br_tippingguidelines ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Tipping Guidelines
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        {
                                            departureContent.departures[0]
                                                .br_tippingguidelines
                                        }
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )} */}
                    {!otpContent.otp.blog.hidden_in_mybnr ? (
                        <div className={classes.details}>
                            <ExpansionPanel
                                className={classes.localInformationPanel}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<AddIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        className={classes.countryDetailsTitle}
                                    >
                                        Good Reads
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <BrBlogSlider
                                        entityData={otpContent.otp.blog.content}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
            {renderCountryInformation()}
        </div>
    );
};

export default PreTripInformation;

// Convert String Value to Slug

// Input    = "Hello World"

// Output   = "hello-world"
export function convertStringToSlug(Text) {
    if (!Text) {
        return "invalid-string-passed";
    }

    return Text.toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
}

// Get Unique Values In Array Based on Key

// Input
// arr = [
//     {
//         name: "John"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jim"
//     }
// ];
//
// comp = "name";

// Output
// unique = [
//     {
//         name: "John"
//     },
//     {
//         name: "Jane"
//     },
//     {
//         name: "Jim"
//     }
// ];
export function getUnique(arr, comp) {
    const unique = arr
        .map((e) => e[comp])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter((e) => arr[e])
        .map((e) => arr[e]);

    return unique;
}

export function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

export function numberWithCommas(num) {
    return num;
    // return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

//Generate UUID v4
export function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );
}

// Slugify a string
export function slugify(str) {
    if (str) {
        str = str.replace(/^\s+|\s+$/g, "");

        // Make the string lowercase
        str = str.toLowerCase();

        // Remove accents, swap ñ for n, etc
        var from =
            "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
        var to =
            "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }

        // Remove invalid chars
        str = str
            .replace(/[^a-z0-9 -]/g, "")
            // Collapse whitespace and replace by -
            .replace(/\s+/g, "-")
            // Collapse dashes
            .replace(/-+/g, "-");

        return str;
    } else {
        return "slugify-failed";
    }
}

//Convert number or string to Currency
export function toCurrency(value) {
    let number = parseFloat(value);

    return number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
}

//Filter via SubArray (e.g. Destination or Experience)
export function filterViaSubArray(arr, subArr, key, matchValue) {
    return arr.filter((obj) =>
        obj[subArr].some((subObj) => subObj[key] === matchValue)
    );
}

//Build list of unique destinations or experiences from trips array
export function buildUniqueAttributes(trips, attribute) {
    let uniqueAttributeList = [];

    trips.map((trip) => {
        if (trip[attribute].length > 0) {
            trip[attribute].map((attribute) => {
                uniqueAttributeList.push(attribute.p15_name);
            });
        }
    });

    uniqueAttributeList = uniqueAttributeList.filter(
        (v, i, a) => a.indexOf(v) === i
    );

    return uniqueAttributeList;
}

export function referenceStagingMedia(mediaUrl) {
    let isMediaUrl = mediaUrl
        ? mediaUrl.indexOf("https://media.butterfield.com") > -1
        : "";
    let returnUrl = mediaUrl;

    if (isMediaUrl) {
        let fileName = mediaUrl.match(/([^\/]*)\/*$/)[1];
        returnUrl = "https://staging.media.butterfield.com/" + fileName;
    }

    return returnUrl;
}

//Filter and Find Functions
export function where(collection, constraint) {
    return collection.filter((collectionItem) =>
        Object.keys(constraint).every(
            (key) =>
                collectionItem.hasOwnProperty(key) &&
                constraint[key] === collectionItem[key]
        )
    );
}

export function search(keyValue, key, array) {
    if (array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === keyValue) {
                return array[i];
            }
        }
    }
}

export function copyUrl() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

export function isValidDate(date) {
    let isDate = true;
    if (isNaN(date.getDate())) {
        isDate = false;
    }
    return isDate;
}

//
export const buildHotelArray = (itinerary, itineraryHotelContent) => {
    //Check for Itinerary and Hotel Content
    if (!itinerary) return [];
    if (!itineraryHotelContent) return [];

    //Set legs and initialize tripStart variable
    let legs = itinerary.legs;
    let tripStart;

    //If trip is personal
    if (itinerary.type !== "Sample") {
        tripStart = new Date(itinerary.start_date);
    }

    let hotels = new Array();
    legs.map((leg, i) => {
        if (leg.content_entity_id != 25862) {
            hotels.push({
                hotel: search(
                    leg.content_entity_id,
                    "map_object_id",
                    itineraryHotelContent
                ),
                checkInDay: leg.periods[0].itinerary_start_day,
                checkOutDay:
                    leg.periods[leg.periods.length - 1].itinerary_end_day,
                nights: leg.nights,
                rooms: leg.rooms,
                bookingReference: leg.booking_reference,
                tripStart,
                type: leg.type,
            });
        }
    });
    return hotels;
};

export function scrollToTargetAdjusted(target, mobileWidth) {
    if (target) {
        const element = document.getElementById(target);
        if (element) {
            const headerOffset = mobileWidth ? 60 + 15 : 60 + 52 + 15;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }
}

//React and Material UI Imports
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.body1.fontFamily,
        color: "#494949",
        fontStyle: "italic",
        fontSize: "12px",
    },
    photoCredit: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px",
    },
}));

export default function BrPhotoCredit(props) {
    const { itineraryRelatedContent } = props;

    const buildPhotoCreditString = (itineraryRelatedContent) => {
        let photoCredits = [];

        if (itineraryRelatedContent) {
            itineraryRelatedContent.map((contentPiece) => {
                if (contentPiece.content.images) {
                    contentPiece.content.images.map((image) => {
                        photoCredits.push(image.credit);
                    });
                }
            });
        }

        photoCredits = photoCredits.filter((credit) => credit);
        let uniquePhotoCredits = [...new Set(photoCredits)];

        if (uniquePhotoCredits.length > 0) {
            return uniquePhotoCredits.join(", ");
        } else {
            return null;
        }
    };

    let photoCredit = buildPhotoCreditString(itineraryRelatedContent);

    //Pull in styles
    const classes = useStyles();

    return (
        <>
            {photoCredit ? (
                <div style={{ maxWidth: "1260px", margin: "auto" }}>
                    <div className={classes.photoCredit}>
                        <PhotoCameraIcon
                            style={{
                                fill: "#194666",
                                marginRight: "4px",
                                width: "14px",
                                marginTop: "1px",
                            }}
                        />
                        <p className={classes.text}>
                            Photography credit: {photoCredit}
                        </p>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
}

export function smallGroupBookNowFormReducer(state, action) {
    switch (action.type) {
        case "SET_DEPARTURE_DATE_INFO":
            return { ...state, departureDate: action.payload };
        case "SET_NUMBER_OF_ADULTS_INFO":
            return { ...state, numberofAdults: action.payload };
        case "SET_NUMBER_OF_CHILDREN_INFO":
            return { ...state, numberofChildren: action.payload };
        case "SET_NUMBER_OF_ROOMS_INFO":
            return { ...state, numberOfRoomsRequired: action.payload };
        case "SET_COUNTRY_INFO":
            return { ...state, country: action.payload };
        case "SET_TRAVEL_HISTORY_INFO":
            return { ...state, travelHistory: action.payload };
        case "SET_NEWSLETTER_SUBSCRIPTION_INFO":
            return { ...state, newsletterSubscription: action.payload };
        case "SET_TRAVEL_AGENT_INFO":
            return { ...state, travelAgent: action.payload };
        case "SET_PREFERRED_PAYMENT_METHOD_INFO":
            return { ...state, preferredPaymentMethod: action.payload };
        case "SET_TERMS_AND_CONDITIONS_INFO":
            return { ...state, termsAndConditions: action.payload };
    }
}

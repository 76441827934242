export function shareTripFormReducer(state, action) {
    switch (action.type) {
        case "SET_NEWSLETTER_SUBSCRIPTION_INFO":
            return { ...state, newsletterSubscription: action.payload };
        case "SET_CREATE_GROUP_EMAIL_INFO":
            return { ...state, isGroupEmail: action.payload };
        case "SET_CONTACT_PREFERENCES":
            return { ...state, contactPreferences: action.payload };
        case "SET_CC_TRAVEL_ADVISOR":
            return { ...state, ccTravelAdvisor: action.payload };
    }
}

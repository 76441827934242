//React Imports
import React from "react";

//Material-UI Imports
import useMediaQuery from "@material-ui/core/useMediaQuery";

//React Router Imports
import { useLocation } from "react-router-dom";

//Image Imports
import LogoWhite from "../../images/BnR_Logo_White.png";
import LogoShort from "../../images/BnR-Logo-Icon-White.png";

//Import CSS Styles
import "../../theme/css/header.css";

export default function Header(props) {
    const mobileWidth = useMediaQuery("(max-width:959px)");
    let searchParams = new URLSearchParams(useLocation().search);
    let isPrint = searchParams.get("isPrint") === "true";

    //Render All Mobile Header Components
    const renderDesktopHeader = () => {
        return (
            <header className={`site-header big`} id="masthead">
                <a href={process.env.REACT_APP_APP_ENDPOINT}>
                    <div>
                        <img
                            src={LogoWhite}
                            alt="logo"
                            style={{
                                maxWidth: "160px",
                            }}
                        />
                    </div>
                </a>
            </header>
        );
    };
    //Render All Mobile Header Components
    const renderMobileHeader = () => {
        return (
            <header className={`site-header small`} id="masthead">
                <a href={process.env.REACT_APP_APP_ENDPOINT}>
                    <img
                        src={LogoShort}
                        alt="logo"
                        style={{
                            maxWidth: "70px",
                        }}
                    />
                </a>
            </header>
        );
    };

    return (
        <>
            {isPrint ? (
                <></>
            ) : (
                <div>
                    {/* {mobileWidth ? renderMobileHeader() : renderDesktopHeader()} */}
                    {renderMobileHeader()}
                </div>
            )}
        </>
    );
}

//React and Material-UI Imports
import React, { useReducer, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "@material-ui/core/Link";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core/";

//Import Reducers
import { smallGroupBookNowFormReducer } from "../formReducers/SmallGroupBookNowFormReducer";

//Import Helper Functions
import { toCurrency } from "../../HelperFunctions/helperFunctions";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	title: {
		fontFamily: theme.typography.h1.fontFamily,
		fontWeight: 300,
		textTransform: "uppercase",
	},
	title2: {
		fontFamily: theme.typography.h2.fontFamily,
		fontWeight: 300,
	},
	text: {
		fontFamily: theme.typography.body1.fontFamily,
		fontWeight: 300,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 200,
		border: "1px solid #494949",
		borderRadius: "4px",
	},
	formLabel: {
		fontFamily: theme.typography.body1.fontFamily,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	tripInformation: {
		textAlign: "left",
		textTransform: "uppercase",
		fontSize: "16px",
	},
	tripInformationMobile: {
		textAlign: "center",
		textTransform: "uppercase",
		fontSize: "16px",
	},
	travellerInformation: {
		display: "flex",
		fontSize: "16px",
		alignItems: "flex-end",
	},
	button: {
		backgroundColor: "#494949",
		color: "#fff",
		width: "115px",
		margin: "0px 8px",
		fontSize: "16px",
		padding: "3px 16px",
		"&:hover": {
			backgroundColor: "#494949",
			color: "#fff",
		},
	},
	submitButton: {
		backgroundColor: "#3E739B",
		color: "#fff",
		width: "115px",
		margin: "0px 8px",
		fontSize: "16px",
		padding: "3px 16px",
		"&:hover": {
			backgroundColor: "#3E739B",
			color: "#fff",
		},
	},
	smallGroupBookNowForm: {
		"& button span": {
			color: "#fff",
		},
		"& .MuiSelect-iconOutlined": {
			color: "#494949",
		},
		"& .MuiSelect-icon": {
			color: "#494949",
		},
		"& .MuiSvgIcon-root": {
			fill: "#494949",
			height: "0.7em",
			width: "0.7em",
		},
		"& textarea": {
			padding: "18.5px 14px",
		},
		"& .MuiInputBase-root": {
			color: "#494949",
			background: "transparent",
			padding: 0,
		},
		"& .MuiInput-underline:after": {
			display: "none",
		},
		"& .MuiInputBase-input": {
			padding: "6px 8px 7px",
		},
		"& .MuiInput-underline:before": {
			borderBottom: "1px solid #494949",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			borderBottom: "1px solid #494949",
		},
		"& .MuiFormHelperText-root": {
			fontFamily: theme.typography.body1.fontFamily,
			textAlign: "center",
		},
		"& .MuiFormControlLabel-label": {
			color: "#494949",
		},
		"& .address-info-two .MuiFormControl-root": {
			border: "none",
		},
		"& .MuiFilledInput-input": {
			backgroundColor: "#fff",
			color: "#494949",
			padding: "10px",
		},
		"& .MuiOutlinedInput-input": {
			color: "#494949",
		},
		"& p": {
			color: "#494949",
		},
		"& span": {
			color: "#494949",
		},
		"& h1": {
			color: "#494949",
		},
		"& h2": {
			color: "#494949",
		},
		"& h3": {
			color: "#494949",
		},
		"& legend": {
			color: "#494949",
		},
	},
}));

export default function SmallGroupBookNowForm(props) {
	const {
		submitted,
		formSubmissionObject,
		implicitUpdateFormSubmissionObject,
		explicitUpdateFormSubmissionObject,
		handleSubmit,
		departureDate,
		tripName,
		tripId,
		selectedDepartureId,
		closeForm,
	} = props;

	const classes = useStyles();

	const mobileWidth = useMediaQuery("(max-width:990px)");

	const initialState = {
		departureDate: "",
		numberofAdults: 0,
		numberofChildren: 0,
		numberOfRoomsRequired: 0,
		country: "",
		travelHistory: "false",
		newsletterSubscription: "true",
		travelAgent: "false",
		preferredPaymentMethod: false,
		termsAndConditions: false,
	};

	const [smallGroupBookingInfo, dispatchSmallGroupBookingInfo] = useReducer(
		smallGroupBookNowFormReducer,
		initialState
	);

	//Fetch Page Data
	useEffect(() => {
		explicitUpdateFormSubmissionObject({
			formEnvironment: 20376,
			flexFieldDepartureDate: departureDate,
			flexFieldTripId: tripId,
			selectedDepartureId: selectedDepartureId,
			inquirySubject: "Itinerary Book Now Request - " + tripName,
			Trip_of_Interest: tripName,
			flexFieldInquiryType: 1,
			flexFieldInquirySource: "ea43a955-e021-e211-93c7-0050568b0004", //Book Now
		});
	}, []);

	//Fetch Page Data
	useEffect(() => {
		if (
			formSubmissionObject["numberofAdults"] &&
			!formSubmissionObject["numberofChildren"]
		) {
			explicitUpdateFormSubmissionObject({
				flexFieldGroupSize: formSubmissionObject["numberofAdults"],
			});
		}
		if (
			formSubmissionObject["numberofAdults"] &&
			formSubmissionObject["numberofChildren"]
		) {
			explicitUpdateFormSubmissionObject({
				flexFieldGroupSize:
					formSubmissionObject["numberofAdults"] +
					formSubmissionObject["numberofChildren"],
			});
		}
	}, [
		formSubmissionObject["numberofAdults"],
		formSubmissionObject["numberofChildren"],
	]);

	console.log(formSubmissionObject);

	function getSteps() {
		return ["Step 1", "Step 2", "Step 3", "Step 4"];
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return buildTripInfoFields();
			case 1:
				return buildTravellerInfoFields();
			case 2:
				return buildGuestInfoFields();
			case 3:
				return buildPaymentInfo();
			default:
				return "Unknown step";
		}
	}

	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const countryList = [
		{ label: "Afghanistan", value: "AF" },
		{ label: "Åland Islands", value: "AX" },
		{ label: "Albania", value: "AL" },
		{ label: "Algeria", value: "DZ" },
		{ label: "American Samoa", value: "AS" },
		{ label: "Andorra", value: "AD" },
		{ label: "Angola", value: "AO" },
		{ label: "Anguilla", value: "AI" },
		{ label: "Antarctica", value: "AQ" },
		{ label: "Antigua and Barbuda", value: "AG" },
		{ label: "Argentina", value: "AR" },
		{ label: "Armenia", value: "AM" },
		{ label: "Aruba", value: "AW" },
		{ label: "Australia", value: "AU" },
		{ label: "Austria", value: "AT" },
		{ label: "Azerbaijan", value: "AZ" },
		{ label: "Bahamas", value: "BS" },
		{ label: "Bahrain", value: "BH" },
		{ label: "Bangladesh", value: "BD" },
		{ label: "Barbados", value: "BB" },
		{ label: "Belarus", value: "BY" },
		{ label: "Belgium", value: "BE" },
		{ label: "Belize", value: "BZ" },
		{ label: "Benin", value: "BJ" },
		{ label: "Bermuda", value: "BM" },
		{ label: "Bhutan", value: "BT" },
		{ label: "Bolivia", value: "BO" },
		{ label: "Bosnia and Herzegovina", value: "BA" },
		{ label: "Botswana", value: "BW" },
		{ label: "Bouvet Island", value: "BV" },
		{ label: "Brazil", value: "BR" },
		{ label: "British Indian Ocean Territory", value: "IO" },
		{ label: "Brunei Darussalam", value: "BN" },
		{ label: "Bulgaria", value: "BG" },
		{ label: "Burkina Faso", value: "BF" },
		{ label: "Burundi", value: "BI" },
		{ label: "Cambodia", value: "KH" },
		{ label: "Cameroon", value: "CM" },
		{ label: "Canada", value: "CA" },
		{ label: "Cape Verde", value: "CV" },
		{ label: "Cayman Islands", value: "KY" },
		{ label: "Central African Republic", value: "CF" },
		{ label: "Chad", value: "TD" },
		{ label: "Chile", value: "CL" },
		{ label: "China", value: "CN" },
		{ label: "Christmas Island", value: "CX" },
		{ label: "Cocos (Keeling) Islands", value: "CC" },
		{ label: "Colombia", value: "CO" },
		{ label: "Comoros", value: "KM" },
		{ label: "Congo", value: "CG" },
		{ label: "Congo, The Democratic Republic of the", value: "CD" },
		{ label: "Cook Islands", value: "CK" },
		{ label: "Costa Rica", value: "CR" },
		{ label: "Cote D'Ivoire", value: "CI" },
		{ label: "Croatia", value: "HR" },
		{ label: "Cuba", value: "CU" },
		{ label: "Cyprus", value: "CY" },
		{ label: "Czech Republic", value: "CZ" },
		{ label: "Denmark", value: "DK" },
		{ label: "Djibouti", value: "DJ" },
		{ label: "Dominica", value: "DM" },
		{ label: "Dominican Republic", value: "DO" },
		{ label: "Ecuador", value: "EC" },
		{ label: "Egypt", value: "EG" },
		{ label: "El Salvador", value: "SV" },
		{ label: "Equatorial Guinea", value: "GQ" },
		{ label: "Eritrea", value: "ER" },
		{ label: "Estonia", value: "EE" },
		{ label: "Ethiopia", value: "ET" },
		{ label: "Falkland Islands (Malvinas)", value: "FK" },
		{ label: "Faroe Islands", value: "FO" },
		{ label: "Fiji", value: "FJ" },
		{ label: "Finland", value: "FI" },
		{ label: "France", value: "FR" },
		{ label: "French Guiana", value: "GF" },
		{ label: "French Polynesia", value: "PF" },
		{ label: "French Southern Territories", value: "TF" },
		{ label: "Gabon", value: "GA" },
		{ label: "Gambia", value: "GM" },
		{ label: "Georgia", value: "GE" },
		{ label: "Germany", value: "DE" },
		{ label: "Ghana", value: "GH" },
		{ label: "Gibraltar", value: "GI" },
		{ label: "Greece", value: "GR" },
		{ label: "Greenland", value: "GL" },
		{ label: "Grenada", value: "GD" },
		{ label: "Guadeloupe", value: "GP" },
		{ label: "Guam", value: "GU" },
		{ label: "Guatemala", value: "GT" },
		{ label: "Guernsey", value: "GG" },
		{ label: "Guinea", value: "GN" },
		{ label: "Guinea-Bissau", value: "GW" },
		{ label: "Guyana", value: "GY" },
		{ label: "Haiti", value: "HT" },
		{ label: "Heard Island and Mcdonald Islands", value: "HM" },
		{ label: "Holy See (Vatican City State)", value: "VA" },
		{ label: "Honduras", value: "HN" },
		{ label: "Hong Kong", value: "HK" },
		{ label: "Hungary", value: "HU" },
		{ label: "Iceland", value: "IS" },
		{ label: "India", value: "IN" },
		{ label: "Indonesia", value: "ID" },
		{ label: "Iran, Islamic Republic Of", value: "IR" },
		{ label: "Iraq", value: "IQ" },
		{ label: "Ireland", value: "IE" },
		{ label: "Isle of Man", value: "IM" },
		{ label: "Israel", value: "IL" },
		{ label: "Italy", value: "IT" },
		{ label: "Jamaica", value: "JM" },
		{ label: "Japan", value: "JP" },
		{ label: "Jersey", value: "JE" },
		{ label: "Jordan", value: "JO" },
		{ label: "Kazakhstan", value: "KZ" },
		{ label: "Kenya", value: "KE" },
		{ label: "Kiribati", value: "KI" },
		{ label: "Korea, Democratic People'S Republic of", value: "KP" },
		{ label: "Korea, Republic of", value: "KR" },
		{ label: "Kuwait", value: "KW" },
		{ label: "Kyrgyzstan", value: "KG" },
		{ label: "Lao People'S Democratic Republic", value: "LA" },
		{ label: "Latvia", value: "LV" },
		{ label: "Lebanon", value: "LB" },
		{ label: "Lesotho", value: "LS" },
		{ label: "Liberia", value: "LR" },
		{ label: "Libyan Arab Jamahiriya", value: "LY" },
		{ label: "Liechtenstein", value: "LI" },
		{ label: "Lithuania", value: "LT" },
		{ label: "Luxembourg", value: "LU" },
		{ label: "Macao", value: "MO" },
		{ label: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
		{ label: "Madagascar", value: "MG" },
		{ label: "Malawi", value: "MW" },
		{ label: "Malaysia", value: "MY" },
		{ label: "Maldives", value: "MV" },
		{ label: "Mali", value: "ML" },
		{ label: "Malta", value: "MT" },
		{ label: "Marshall Islands", value: "MH" },
		{ label: "Martinique", value: "MQ" },
		{ label: "Mauritania", value: "MR" },
		{ label: "Mauritius", value: "MU" },
		{ label: "Mayotte", value: "YT" },
		{ label: "Mexico", value: "MX" },
		{ label: "Micronesia, Federated States of", value: "FM" },
		{ label: "Moldova, Republic of", value: "MD" },
		{ label: "Monaco", value: "MC" },
		{ label: "Mongolia", value: "MN" },
		{ label: "Montserrat", value: "MS" },
		{ label: "Morocco", value: "MA" },
		{ label: "Mozambique", value: "MZ" },
		{ label: "Myanmar", value: "MM" },
		{ label: "Namibia", value: "NA" },
		{ label: "Nauru", value: "NR" },
		{ label: "Nepal", value: "NP" },
		{ label: "Netherlands", value: "NL" },
		{ label: "Netherlands Antilles", value: "AN" },
		{ label: "New Caledonia", value: "NC" },
		{ label: "New Zealand", value: "NZ" },
		{ label: "Nicaragua", value: "NI" },
		{ label: "Niger", value: "NE" },
		{ label: "Nigeria", value: "NG" },
		{ label: "Niue", value: "NU" },
		{ label: "Norfolk Island", value: "NF" },
		{ label: "Northern Mariana Islands", value: "MP" },
		{ label: "Norway", value: "NO" },
		{ label: "Oman", value: "OM" },
		{ label: "Pakistan", value: "PK" },
		{ label: "Palau", value: "PW" },
		{ label: "Palestinian Territory, Occupied", value: "PS" },
		{ label: "Panama", value: "PA" },
		{ label: "Papua New Guinea", value: "PG" },
		{ label: "Paraguay", value: "PY" },
		{ label: "Peru", value: "PE" },
		{ label: "Philippines", value: "PH" },
		{ label: "Pitcairn", value: "PN" },
		{ label: "Poland", value: "PL" },
		{ label: "Portugal", value: "PT" },
		{ label: "Puerto Rico", value: "PR" },
		{ label: "Qatar", value: "QA" },
		{ label: "Reunion", value: "RE" },
		{ label: "Romania", value: "RO" },
		{ label: "Russian Federation", value: "RU" },
		{ label: "RWANDA", value: "RW" },
		{ label: "Saint Helena", value: "SH" },
		{ label: "Saint Kitts and Nevis", value: "KN" },
		{ label: "Saint Lucia", value: "LC" },
		{ label: "Saint Pierre and Miquelon", value: "PM" },
		{ label: "Saint Vincent and the Grenadines", value: "VC" },
		{ label: "Samoa", value: "WS" },
		{ label: "San Marino", value: "SM" },
		{ label: "Sao Tome and Principe", value: "ST" },
		{ label: "Saudi Arabia", value: "SA" },
		{ label: "Senegal", value: "SN" },
		{ label: "Serbia and Montenegro", value: "CS" },
		{ label: "Seychelles", value: "SC" },
		{ label: "Sierra Leone", value: "SL" },
		{ label: "Singapore", value: "SG" },
		{ label: "Slovakia", value: "SK" },
		{ label: "Slovenia", value: "SI" },
		{ label: "Solomon Islands", value: "SB" },
		{ label: "Somalia", value: "SO" },
		{ label: "South Africa", value: "ZA" },
		{ label: "South Georgia and the South Sandwich Islands", value: "GS" },
		{ label: "Spain", value: "ES" },
		{ label: "Sri Lanka", value: "LK" },
		{ label: "Sudan", value: "SD" },
		{ label: "Surilabel", value: "SR" },
		{ label: "Svalbard and Jan Mayen", value: "SJ" },
		{ label: "Swaziland", value: "SZ" },
		{ label: "Sweden", value: "SE" },
		{ label: "Switzerland", value: "CH" },
		{ label: "Syrian Arab Republic", value: "SY" },
		{ label: "Taiwan, Province of China", value: "TW" },
		{ label: "Tajikistan", value: "TJ" },
		{ label: "Tanzania, United Republic of", value: "TZ" },
		{ label: "Thailand", value: "TH" },
		{ label: "Timor-Leste", value: "TL" },
		{ label: "Togo", value: "TG" },
		{ label: "Tokelau", value: "TK" },
		{ label: "Tonga", value: "TO" },
		{ label: "Trinidad and Tobago", value: "TT" },
		{ label: "Tunisia", value: "TN" },
		{ label: "Turkey", value: "TR" },
		{ label: "Turkmenistan", value: "TM" },
		{ label: "Turks and Caicos Islands", value: "TC" },
		{ label: "Tuvalu", value: "TV" },
		{ label: "Uganda", value: "UG" },
		{ label: "Ukraine", value: "UA" },
		{ label: "United Arab Emirates", value: "AE" },
		{ label: "United Kingdom", value: "GB" },
		{ label: "United States", value: "US" },
		{ label: "United States Minor Outlying Islands", value: "UM" },
		{ label: "Uruguay", value: "UY" },
		{ label: "Uzbekistan", value: "UZ" },
		{ label: "Vanuatu", value: "VU" },
		{ label: "Venezuela", value: "VE" },
		{ label: "Viet Nam", value: "VN" },
		{ label: "Virgin Islands, British", value: "VG" },
		{ label: "Virgin Islands, U.S.", value: "VI" },
		{ label: "Wallis and Futuna", value: "WF" },
		{ label: "Western Sahara", value: "EH" },
		{ label: "Yemen", value: "YE" },
		{ label: "Zambia", value: "ZM" },
		{ label: "Zimbabwe", value: "ZW" },
	];

	const buildTripInfoFields = () => {
		return (
			<div
				className={"trip-information"}
				style={{
					margin: "0 auto",
					color: "#494949",
					padding: mobileWidth ? "0px" : "20px 0px",
				}}
			>
				<p
					className={classes.text}
					style={{
						textAlign: "left",
						fontSize: "16px",
						marginBottom: "60px",
					}}
				>
					Please provide us with the below information, and a B&R
					Travel Advisor will be in touch within one business day to
					finalize the details.
					<br />
					<br />
					If you see On Request beside your desired departure, this
					indicates that currently there are not enough spaces
					available and B&R will need to contact hotels to secure
					rooms before guaranteeing your booking.
				</p>
				<Grid container>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						<p className={classes.text}>Departure Date</p>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						<p
							className={classes.text}
							style={{ marginLeft: "10px" }}
						>
							{departureDate}
							<span
								style={{
									fontSize: "12px",
									float: "right",
									textTransform: "capitalize",
									paddingTop: "5px",
									cursor: "pointer",
								}}
								onClick={() => closeForm(true)}
							>
								Change Date
							</span>
						</p>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						{/*Number of Adults In Group Select*/}
						<p className={classes.text}>
							Number of Adults in Group
						</p>
					</Grid>
					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						<FormControl
							variant="outlined"
							className={classes.formControl}
						>
							<Select
								name={"numberofAdults"}
								labelId="small-group-booking-number-of-adults"
								id="demo-simple-select-outlined"
								value={formSubmissionObject["numberofAdults"]}
								onChange={implicitUpdateFormSubmissionObject}
								label="Number of Adults in Group"
								style={{ color: "#494949" }}
								className={classes.title}
							>
								<MenuItem value={0}>0</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
						v
					>
						{/* Number of Children In Group Select */}
						<p>Number of Children in Group</p>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						<FormControl
							variant="outlined"
							className={classes.formControl}
						>
							<InputLabel
								id="demo-simple-select-outlined-label"
								style={{ color: "#fff" }}
								className={classes.title}
							>
								0
							</InputLabel>
							<Select
								name={"numberofChildren"}
								labelId="small-group-booking-number-of-adults"
								id="demo-simple-select-outlined"
								value={formSubmissionObject["numberofChildren"]}
								onChange={implicitUpdateFormSubmissionObject}
								label="Number of Children in Group"
								style={{ color: "#fff" }}
								className={classes.title}
							>
								<MenuItem value={0}>0</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
							</Select>
						</FormControl>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						{/*Number of Rooms Required Select*/}
						<p className={classes.text}>Number of Rooms Required</p>
					</Grid>

					<Grid
						item
						xs={12}
						sm={6}
						md={6}
						lg={6}
						className={
							mobileWidth
								? classes.tripInformationMobile
								: classes.tripInformation
						}
					>
						<FormControl
							variant="outlined"
							className={classes.formControl}
						>
							{/* <InputLabel
                                id="demo-simple-select-outlined-label"
                                style={{ color: "#fff" }}
                                className={classes.title}
                            >
                                0
                            </InputLabel> */}
							<Select
								name={"numberOfRoomsRequired"}
								labelId="small-group-booking-number-of-adults"
								id="demo-simple-select-outlined"
								value={
									formSubmissionObject[
										"numberOfRoomsRequired"
									]
								}
								onChange={implicitUpdateFormSubmissionObject}
								label="Number of rooms required for your group"
								style={{ color: "#494949" }}
								className={classes.title}
							>
								<MenuItem value={0}>0</MenuItem>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
								<MenuItem value={3}>3</MenuItem>
								<MenuItem value={4}>4</MenuItem>
								<MenuItem value={5}>5</MenuItem>
								<MenuItem value={6}>6</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</div>
		);
	};

	const buildTravellerInfoFields = () => {
		return (
			<div
				className={"traveller-information"}
				style={{
					margin: "0 auto",
					color: "#494949",
					textAlign: "left",
					padding: "30px 0px",
				}}
			>
				<div className={classes.travellerInformation}>
					<div style={{ width: "30%" }}>
						<p
							className={classes.formLabel}
							style={{ width: "100%", margin: 0 }}
						>
							First Name
						</p>
					</div>
					<div style={{ width: "70%" }}>
						<TextField
							id={"FirstName"}
							name={"FirstName"}
							style={{ width: "100%" }}
							value={formSubmissionObject["FirstName"]}
							onChange={implicitUpdateFormSubmissionObject}
						/>
					</div>
				</div>
				<div className={classes.travellerInformation}>
					<div style={{ width: "30%" }}>
						<p
							className={classes.formLabel}
							style={{ width: "100%", margin: 0 }}
						>
							Last Name
						</p>
					</div>
					<div style={{ width: "70%" }}>
						<TextField
							id={"LastName"}
							name={"LastName"}
							style={{ width: "100%" }}
							value={formSubmissionObject["LastName"]}
							onChange={implicitUpdateFormSubmissionObject}
						/>
					</div>
				</div>
				<div className={classes.travellerInformation}>
					<div style={{ width: "30%" }}>
						<p
							className={classes.formLabel}
							style={{ width: "100%", margin: 0 }}
						>
							Email
						</p>
					</div>
					<div style={{ width: "70%" }}>
						<TextField
							id={"Email"}
							name={"Email"}
							style={{ width: "100%" }}
							value={formSubmissionObject["Email"]}
							onChange={implicitUpdateFormSubmissionObject}
						/>
					</div>
				</div>
				<div className={classes.travellerInformation}>
					<div style={{ width: "30%" }}>
						<p
							className={classes.formLabel}
							style={{ width: "100%", margin: 0 }}
						>
							Phone Number
						</p>
					</div>
					<div style={{ width: "70%" }}>
						<TextField
							id={"Phone"}
							name={"Phone"}
							style={{ width: "100%" }}
							value={formSubmissionObject["Phone"]}
							onChange={implicitUpdateFormSubmissionObject}
						/>
					</div>
				</div>

				<div
					className={"address-info"}
					style={{ margin: "40px 0px 20px 0px" }}
				>
					<p
						className={classes.formLabel}
						style={{
							fontSize: "16px",
							marginBottom: "10px",
						}}
					>
						Address
					</p>
					<Grid container>
						<Grid item xs={12} sm={6} md={6}>
							<div
								style={{
									maxWidth: mobileWidth ? "none" : "200px",
								}}
							>
								<TextField
									id={"Address"}
									name={"Address"}
									helperText={"Address Line One"}
									style={{ display: "block" }}
									value={formSubmissionObject["Address"]}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
								<TextField
									id={"addressLine2"}
									name={"addressLine2"}
									helperText={"Address Line Two"}
									style={{ display: "block" }}
									value={formSubmissionObject["addressLine2"]}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
								<TextField
									id={"City"}
									name={"City"}
									helperText={"City"}
									style={{ display: "block" }}
									value={formSubmissionObject["City"]}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<div
								className={"address-info-two"}
								style={{
									maxWidth: mobileWidth ? "none" : "200px",
								}}
							>
								<TextField
									id={"State"}
									name={"State"}
									helperText={"State / Province / Region"}
									style={{ display: "block" }}
									value={formSubmissionObject["State"]}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>

								<FormControl
									className={classes.formControl}
									style={{ margin: 0 }}
								>
									<Select
										name={"Country"}
										labelId="small-group-booking-number-of-adults"
										id="demo-simple-select-outlined"
										className={classes.selectEmpty}
										inputProps={{
											"aria-label": "Without label",
										}}
										value={formSubmissionObject["Country"]}
										onChange={
											implicitUpdateFormSubmissionObject
										}
									>
										{countryList.map((country) => {
											return (
												<MenuItem value={country.label}>
													{country.label}
												</MenuItem>
											);
										})}
									</Select>
									<FormHelperText>Country</FormHelperText>
								</FormControl>

								<TextField
									id={"PostalCode"}
									name={"PostalCode"}
									helperText={"Zip / Postal Code"}
									style={{ display: "block" }}
									value={
										formSubmissionObject[
											"zipPosPostalCodetalCode"
										]
									}
									onChange={
										implicitUpdateFormSubmissionObject
									}
								/>
							</div>
						</Grid>
					</Grid>
				</div>

				<div
					className={"additional-traveller-info"}
					style={{ padding: "45px 0px", textAlign: "center" }}
				>
					<FormControl component="fieldset">
						<FormLabel component="legend">
							Have you travelled with us before?
						</FormLabel>
						<RadioGroup
							row
							aria-label="travel-history"
							name="previousTraveler"
							value={smallGroupBookingInfo.travelHistory}
							onChange={(event) => {
								dispatchSmallGroupBookingInfo({
									type: "SET_TRAVEL_HISTORY_INFO",
									payload: event.target.value,
								});
								implicitUpdateFormSubmissionObject(event);
							}}
							style={{ color: "#494949", margin: "auto" }}
						>
							<FormControlLabel
								value="true"
								control={<Radio />}
								label="Yes"
								style={{ color: "#494949" }}
							/>
							<FormControlLabel
								value="false"
								control={<Radio />}
								label="No"
								style={{ color: "#494949" }}
							/>
						</RadioGroup>

						<FormLabel
							component="legend"
							style={{
								marginTop: "15px",
								lineHeight: "1.6",
							}}
						>
							Sign up for our emails to receive b&r news, brand
							new trip launches, and content from our
							award-winning blog.
						</FormLabel>
						<RadioGroup
							row
							aria-label="newsletter-subscription"
							name="emailOptIn"
							value={smallGroupBookingInfo.newsletterSubscription}
							onChange={(event) => {
								dispatchSmallGroupBookingInfo({
									type: "SET_NEWSLETTER_SUBSCRIPTION_INFO",
									payload: event.target.value,
								});
								implicitUpdateFormSubmissionObject(event);
							}}
							style={{ color: "#494949", margin: "auto" }}
						>
							<FormControlLabel
								value="true"
								control={<Radio />}
								label="Yes"
								style={{ color: "#494949" }}
							/>
							<FormControlLabel
								value="false"
								control={<Radio />}
								label="No"
								style={{ color: "#494949" }}
							/>
						</RadioGroup>

						<FormLabel
							component="legend"
							style={{ marginTop: "15px" }}
						>
							Are you working with a travel agent?
						</FormLabel>
						<RadioGroup
							row
							aria-label="travel-agent"
							name="travelAgent"
							value={smallGroupBookingInfo.travelAgent}
							onChange={(event) => {
								dispatchSmallGroupBookingInfo({
									type: "SET_TRAVEL_AGENT_INFO",
									payload: event.target.value,
								});
								implicitUpdateFormSubmissionObject(event);
							}}
							style={{ color: "#494949", margin: "auto" }}
						>
							<FormControlLabel
								value="true"
								control={<Radio />}
								label="Yes"
								style={{ color: "#494949" }}
							/>
							<FormControlLabel
								value="false"
								control={<Radio />}
								label="No"
								style={{ color: "#494949" }}
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</div>
		);
	};

	const buildGuestInfoFields = () => {
		const { numberofAdults, numberofChildren } = formSubmissionObject;
		const buildGuestFields = () => {
			let guestFields = [];
			if (numberofAdults) {
				for (var i = 0; i < numberofAdults; i++) {
					// console.log(
					//     formSubmissionObject["adultTraveller" + (i + 1)]
					// );
					guestFields.push(
						<div style={{ margin: "60px 0px" }}>
							<p
								className={classes.title}
								style={{
									textAlign: "center",
									marginBottom: "5px",
									fontSize: "16px",
								}}
							>
								Adult Traveller {i + 1}
							</p>
							<div className={classes.travellerInformation}>
								<div style={{ width: "30%" }}>
									<p
										className={classes.text}
										style={{ width: "100%", margin: 0 }}
									>
										Full Name
									</p>
								</div>
								<div style={{ width: "70%" }}>
									<TextField
										id={"adultTraveller" + (i + 1)}
										name={"adultTraveller" + (i + 1)}
										style={{ width: "100%" }}
										value={
											formSubmissionObject[
												"adultTraveller" + (i + 1)
											]
										}
										onChange={
											implicitUpdateFormSubmissionObject
										}
									/>
								</div>
							</div>
						</div>
					);
				}
			}
			if (numberofChildren) {
				guestFields.push(
					<div
						style={{
							width: "100%",
							padding: "10px 0 0 0",
							margin: "50px 0 0 0",
							borderTop: "1px #494949 solid",
						}}
					></div>
				);
				for (var i = 0; i < numberofChildren; i++) {
					guestFields.push(
						<div style={{ margin: "30px 0px" }}>
							<p
								className={classes.title}
								style={{
									textAlign: "center",
									marginBottom: "5px",
									fontSize: "16px",
								}}
							>
								Child Traveller {i + 1}
							</p>
							<div className={classes.travellerInformation}>
								<div style={{ width: "30%" }}>
									<p
										className={classes.text}
										style={{ width: "100%", margin: 0 }}
									>
										Full Name
									</p>
								</div>
								<div style={{ width: "70%" }}>
									<TextField
										id={"childTraveller" + (i + 1)}
										name={"childTraveller" + (i + 1)}
										style={{ width: "100%" }}
										value={
											formSubmissionObject[
												"childTraveller" + (i + 1)
											]
										}
										onChange={
											implicitUpdateFormSubmissionObject
										}
									/>
								</div>
							</div>
						</div>
					);
				}
				guestFields.push(
					<div
						style={{
							width: "100%",
							padding: "10px 0 0 0",
							margin: "50px 0 0 0",
							borderTop: "1px #494949 solid",
						}}
					></div>
				);
			}
			// console.log(guestFields);
			return guestFields;
		};

		buildGuestFields();

		return (
			<div
				className={"guest-information"}
				style={{
					maxWidth: "600px",
					margin: "0 auto",
					color: "#494949",
					textAlign: "left",
				}}
			>
				<p style={{ fontSize: "16px" }}>
					If you have selected more than 1 guest we require a bit more
					information about each additional guest. Please fill out the
					first and last names of the additional guests below. If
					there are no additional guests, please continue to the next
					step by clicking next below.
				</p>
				<div
					style={{
						maxWidth: "500px",
						margin: "0 auto",
						color: "#494949",
						textAlign: "left",
					}}
				>
					{buildGuestFields()}
				</div>
			</div>
		);
	};

	const buildPaymentInfo = () => {
		return (
			<div
				className={"payment-info"}
				style={{
					margin: "0 auto",
					color: "#494949",
					textAlign: "left",
					fontSize: "16px",
				}}
			>
				<p className={classes.text}>
					Deposit required:{" "}
					{formSubmissionObject["numberofAdults"] +
						formSubmissionObject["numberofChildren"] >
					0
						? toCurrency(
								(formSubmissionObject["numberofAdults"] +
									formSubmissionObject["numberofChildren"]) *
									1000
						  )
						: "Please add at least one guest..."}
				</p>
				<div>
					<p className={classes.text}>
						A B&R Travel Advisor will be in contact within one
						business day to accept payment. Your booking is not
						confirmed until your deposit is paid and processed.
					</p>
					<p className={classes.text}>
						<em>
							NOTE: In some cases trips may have additional
							deposit costs. Your personal travel advisor will
							communicate any additional costs prior to processing
							your deposit.
						</em>
					</p>
				</div>
				{/* <div style={{ maxWidth: "350px" }}>
                    <TextField
                        id="name-on-card"
                        style={{
                            width: "100%",
                            padding: "16px 0px",
                        }}
                        variant="filled"
                        placeholder="Name on card"
                    />
                    <TextField
                        id="billing-address"
                        placeholder="Billing address"
                        style={{
                            width: "100%",
                            padding: "16px 0px",
                        }}
                        variant="filled"
                    />
                    <TextField
                        id="card-number"
                        placeholder="Card number"
                        style={{
                            width: "100%",
                            padding: "16px 0px",
                        }}
                        variant="filled"
                    />
                    <div style={{ display: "flex" }}>
                        <TextField
                            id="expiration"
                            placeholder="MM/YY"
                            style={{
                                width: "20%",
                                padding: "16px 0px",
                                marginRight: "20px",
                            }}
                            variant="filled"
                        />
                        <TextField
                            id="cvc"
                            placeholder="CVC"
                            style={{
                                width: "20%",
                                padding: "16px 0px",
                            }}
                            variant="filled"
                        />
                    </div>
                </div>
                <div style={{ margin: "20px 0px" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleCheckedFilled />}
                                name="paymentOverPhone"
                                checked={
                                    formSubmissionObject["paymentOverPhone"]
                                }
                                onChange={(event) => {
                                    dispatchSmallGroupBookingInfo({
                                        type:
                                            "SET_PREFERRED_PAYMENT_METHOD_INFO",
                                        payload: event.target.checked,
                                    });
                                    explicitUpdateFormSubmissionObject({
                                        paymentOverPhone: event.target.checked.toString(),
                                    });
                                }}
                            />
                        }
                        label="I would prefer to process the payment over the phone with a
                        B&R Travel Advisor"
                        style={{ marginRight: 0 }}
                    />
                </div> */}
				<div style={{ margin: "50px 0px" }}>
					<p
						className={classes.title}
						style={{ marginBottom: 0, fontSize: "14px" }}
					>
						Terms & Conditions
					</p>
					<FormControlLabel
						control={
							<Checkbox
								icon={<CircleUnchecked />}
								checkedIcon={<CircleCheckedFilled />}
								name="termsandPrivacyPolicyAgreement"
								checked={
									formSubmissionObject[
										"termsandPrivacyPolicyAgreement"
									]
								}
								onChange={(event) => {
									dispatchSmallGroupBookingInfo({
										type: "SET_TERMS_AND_CONDITIONS_INFO",
										payload: event.target.checked,
									});
									explicitUpdateFormSubmissionObject({
										termsandPrivacyPolicyAgreement:
											event.target.checked.toString(),
									});
								}}
							/>
						}
						label="I have read and accepted the terms & conditions"
						style={{ marginRight: 0 }}
					/>
				</div>
				<div style={{ margin: "40px 0px" }}>
					<p
						className={classes.title}
						style={{
							textTransform: "uppercase",
							marginBottom: "7px",
						}}
					>
						Is there anything else we should know?
					</p>
					<TextField
						id="additional-info"
						name="additionalComments"
						variant="outlined"
						multiline
						rows={4}
						style={{ width: "100%" }}
						value={formSubmissionObject["additionalComments"]}
						onChange={implicitUpdateFormSubmissionObject}
					/>
				</div>
				<div>
					<p className={classes.text} style={{ fontSize: "11.5px" }}>
						By submitting this form, you agree to the Butterfield &
						Robinson{" "}
						<Link
							href="/terms-and-conditions"
							style={{ color: "#70B2C4" }}
						>
							Terms
						</Link>{" "}
						and{" "}
						<Link
							href="/privacy-policy"
							style={{ color: "#70B2C4" }}
						>
							Privacy Policy
						</Link>
						.
					</p>
				</div>
			</div>
		);
	};

	return (
		<div
			className={
				classes.smallGroupBookNowForm + " small-group-book-now-form"
			}
			style={{
				borderRadius: "10px",

				backgroundColor: "#fff",
				maxWidth: mobileWidth ? "none" : "660px",
			}}
		>
			<div style={{ padding: "30px 30px 50px 30px" }}>
				<div
					style={{
						maxWidth: "600px",
						margin: "0 auto",
						color: "#494949",
					}}
				>
					<h2
						className={classes.title}
						style={{
							textAlign: "center",
							color: "rgb(150, 175, 142)",
						}}
					>
						{tripName}
					</h2>
					<h1
						className={classes.title}
						style={{ marginBottom: "35px", textAlign: "center" }}
					>
						Book Your Trip
					</h1>
				</div>
				<div style={{ textAlign: "center" }}>
					{submitted ? (
						<div>
							<p
								style={{
									color: "#494949",
									textAlign: "center",
									margin: "20px 0px",
								}}
								className={classes.text}
							>
								Thank you for your 'Book Now' reservation
								request. One of our expert travel coordinators
								will be in touch shortly to confirm your
								reservation details and process your trip
								deposit.
								<br></br>
								<br></br>
								See you on the road!
							</p>
							<Button
								onClick={() => closeForm(true)}
								className={classes.button}
								style={{ width: "auto" }}
							>
								Back to {tripName}
							</Button>
						</div>
					) : (
						<div>
							<Typography className={classes.instructions}>
								{getStepContent(activeStep)}
							</Typography>
							<div style={{ margin: "35px 0px 10px 0px" }}>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={
										classes.button +
										" button-with-background"
									}
									style={{
										display:
											activeStep === 0
												? "none"
												: "inline-block",
									}}
								>
									Previous
								</Button>
								{activeStep === steps.length - 1 ? (
									<Button
										variant="contained"
										color="primary"
										onClick={handleSubmit}
										className={
											classes.submitButton +
											" button-with-background"
										}
									>
										{"Submit"}
									</Button>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={handleNext}
										className={
											classes.button +
											" button-with-background"
										}
									>
										{"Next"}
									</Button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
